import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import { debounce, isEqual, pick } from 'lodash';
import { MDFInfiniteList } from './MDFInfiniteList';
import { MDFButton } from './MDFButton';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { Popup } from '@synerg/vdl-react-components';
import { TimeoutManager } from '@synerg/vdl-react-components/lib/util/timeout-manager';
import { handleBlur, handleFocus, IFocusMixinProps } from '@synerg/vdl-react-components/lib/util/focus-mixin';
import { activeElement } from 'dom-helpers';
import CaretDown from 'adp-react-icons/lib/fa/caret-down';
import CloseIcon from 'adp-react-icons/lib/adp/close-thin';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

// Notes
// - The dropdownMenu opens onEnter, Keydown and typing in

export interface IMenuItem {
  id: string;
  value: string;
  subValue?: string;
}

export interface IGroupedMenuItem {
  name: string;
  items: IMenuItem[];
}

export interface IMDFDropdownMenuProps extends React.AriaAttributes {
  // Enables focus on page load.
  autofocus?: boolean;

  // Accessibility feature manages focus.
  id?: string;

  // A CSS classname to override the styles of this component.
  className?: string;

  // A CSS classname to override the styles of the dropdown menu items when it is opened
  containerClassName?: string;

  // If true, render this component as disabled.
  disabled?: boolean;

  isValid?: boolean;

  // Search only works after minimum characters are entered in this component
  minCharactersToSearch?: number;

  // The items to display in the dropdown.
  items: IMenuItem[] | IGroupedMenuItem[];

  // Called when the user selects an item in the dropdown.
  onChange?: (index: number, menuItem: IMenuItem) => void;

  // Called when the user scrolls to the end of the list of items. Signals the application to load more items into the items array.
  onFetchItems?: () => void;

  // When `search` is true, the component will call this function once the user types three or more chararters in the component.
  onSearchItems?: (query: string) => Promise<any>;

  // When `paginate` is true, the component will call this function when the user scrolls to the end of the list of items and also on search.
  onLoadOptions?: (query: string, previousOptions: IMenuItem[] | IGroupedMenuItem[]) => Promise<any>;

  // The placeholder text for this component.
  placeholder?: string;

  // The tabIndex of the component.
  tabIndex?: number;

  // Enable search mode. The component will call `onSearchItems` once the user types minCharactersToSearch(default 3 characters) characters.
  search?: boolean;

  // Enable paginate mode. The component will call `onLoadOptions` when the user scrolls to the end of the list of items and also on search.
  paginate?: boolean;

  // This is to reload MDFDropdownMenu when there is a change in dependentValue. MDFDropdownMenu cleans all cached options and call onLoadOptions on dependentValue change.
  dependentValue?: any;

  // One of `single` | `grouped` | `complex`
  type: string;

  // If set, the initial value the component should display. The item set here should be in the list of items.`
  value?: IMenuItem;

  // Not for application use. It will be set by validatedField or MDFFormValidatedField component.
  validatedField?: boolean;

  // When renderFooter set to true create MDFDropdownMenu that contains a footer.
  renderFooter?: boolean;

  // Text to display in the MDFDropdownMenu footer.
  footerText?: string;

  // Called when the user click on footer link.
  onFooterLinkClick?: () => void;

  // Accessibility message for when component is invalid.
  ariaInvalid?: any;
  'aria-invalid'?: any;

  // To enable the aria required attribute for accessibility.
  ariaRequired?: boolean;
  'aria-required'?: boolean;
}

export class MDFDropdownMenu extends React.Component<IMDFDropdownMenuProps, any> {
  static propTypes = {
    autofocus: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    disabled: PropTypes.bool,
    isValid: PropTypes.bool,
    items: PropTypes.array,
    onChange: PropTypes.func,
    onFetchItems: PropTypes.func,
    onSearchItems: PropTypes.func,
    placeholder: PropTypes.string,
    search: PropTypes.bool,
    type: PropTypes.string,
    value: PropTypes.object,
    ariaInvalid: PropTypes.any,
    ariaRequired: PropTypes.bool
  };

  static displayName = 'MDFDropdownMenu';

  private readonly SINGLE: string = 'single';
  private readonly GROUPED: string = 'grouped';
  private readonly COMPLEX: string = 'complex';
  private readonly ESC_KEY: string = 'Escape';
  private readonly TAB_KEY: string = 'Tab';
  private readonly UP_ARROW_KEY: string = 'ArrowUp';
  private readonly DOWN_ARROW_KEY: string = 'ArrowDown';
  private readonly ENTER_KEY: string = 'Enter';
  private static readonly EMPTY_ITEM = { id: '', value: '' };
  private readonly DEFAULT_SEARCH_LENGTH: number = 3;
  private id: string;
  private listId: string;
  private defaultSearchLength: number;
  private inputRef = React.createRef<HTMLInputElement>();
  private containerRef = React.createRef<HTMLDivElement>();
  private containerInputRef = React.createRef<HTMLInputElement>();
  private _isMounted = false;
  private timeoutManager = new TimeoutManager();
  private debounced;
  private loadOptionsDebounced;
  private previousLoadOptions = [];
  private hasMoreOptions = [];
  private lastSearchedQuery;
  private valueIndex = -1;
  private groupId: string;

  constructor(props: IMDFDropdownMenuProps) {
    super(props);

    this.id = this.props.id ? this.props.id : generateId('input');
    this.listId = `mdf-dropdown-menu-list-${this.id}`;
    this.groupId = generateId('mdf-dropdown-menu-group');
    this.defaultSearchLength = this.props.minCharactersToSearch || this.DEFAULT_SEARCH_LENGTH;

    this.state = {
      activeItem: -1,
      dependentValue: this.props.dependentValue,
      disabled: this.props.disabled,
      isOpen: false,
      isSearching: false,
      isValid: true,
      items: this.props.items || [],
      search: this.props.search,
      searchValue: '',
      type: this.props.type,
      value: props.value || MDFDropdownMenu.EMPTY_ITEM,
      focused: false
    };

    this.debounced = debounce(this.callOnSearch, 500);
    this.loadOptionsDebounced = debounce(this.onLoadOptions, 500);
  }

  handleDropdownKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === this.DOWN_ARROW_KEY) {
      this.handleDropdownKeyPress(e);
    }
  };

  handleDropdownKeyPress = (e: React.KeyboardEvent) => {
    e.preventDefault();
    let charValue = '';

    charValue = String.fromCharCode(e.charCode);

    if (this.state.disabled) {
      return;
    }

    this.setState({
      searchValue: '',
      items: this.props.paginate ? this.state.items : this.props.items,
      isOpen: !this.state.isOpen
    });

    if (e.key !== this.ENTER_KEY && e.key !== this.TAB_KEY && e.key !== this.UP_ARROW_KEY && e.key !== this.DOWN_ARROW_KEY) {
      this.handleSearchInputChange(null, charValue);
    }
  };

  /**
   * Handle the click on the dropdown component
   */
  handleDropDownMenuClick = (e: React.MouseEvent | React.FocusEvent) => {
    e.preventDefault();

    if (this.state.disabled) {
      return;
    }

    this.setState({
      searchValue: '',
      items: this.props.paginate ? (this.previousLoadOptions[''] || this.state.items) : this.props.items,
      isOpen: !this.state.isOpen,
      activeItem: 0
    });
  };

  /**
   * Clear the selected value from the dropdown when the user
   * clicks on the X.
   */
  handleClearClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      value: MDFDropdownMenu.EMPTY_ITEM,
      activeItem: -1,
      items: this.props.search ? this.props.items : this.state.items
    });

    this.valueIndex = -1;
    this.onChange(-1, MDFDropdownMenu.EMPTY_ITEM);
    this.handleDropDownMenuClick(e);
  };

  /**
   * Handle the click event when a user clicks on an item in the search dropdown menu.
   */
  handleItemClick = (e: React.MouseEvent, index: number, menuItem: IMenuItem) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen,
      value: menuItem,
      activeItem: -1
    });

    this.valueIndex = index;
    this.onChange(index, menuItem);
    this.containerInputRef.current?.focus();
  };

  handleBackgroundClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen
    });

    this.onChange(this.valueIndex, this.state.value);
    this.containerInputRef.current?.focus();
  };

  /**
   * Handle change events on the search input. This will narrow down the choices
   * as the user types.
   */
  handleSearchInputChange = (e?: React.ChangeEvent<HTMLInputElement>, inputValue?: string) => {
    const input = inputValue || (e.target as HTMLInputElement).value;

    if (!this.state.search && (!this.props.paginate || (this.props.paginate && this.hasMoreOptions[''] === false))) {
      const value = input;
      let items = [];
      const totalItems = this.props.paginate ? this.previousLoadOptions[''] : this.props.items;

      if (this.state.type !== this.GROUPED) {
        items = (totalItems as IMenuItem[]).filter((item) => item.value.toLowerCase().includes(value.toLowerCase()) || item.id.toLowerCase().includes(value.toLowerCase()) || (item.subValue && item.subValue.toLowerCase().includes(value.toLowerCase())));
      }
      else {
        (totalItems as IGroupedMenuItem[]).forEach((group) => {
          const groupItems = group.items.filter((item) => item.value.toLowerCase().includes(value.toLowerCase()) || item.id.toLowerCase().includes(value.toLowerCase()));

          if (groupItems && groupItems.length > 0) {
            items.push({ name: group.name, items: groupItems });
          }
        });
      }

      if (value === '') {
        items = totalItems;
      }

      this.setState({
        items: items,
        searchValue: value,
        activeItem: 0
      });
    }
    else if (this.props.paginate) {
      this.setState({
        searchValue: input,
        isSearching: input?.length >= this.defaultSearchLength,
        items: input?.length < this.defaultSearchLength ? (this.previousLoadOptions[''] || []) : this.state.items
      });

      if (input?.length >= this.defaultSearchLength) {
        this.loadOptionsDebounced(input);
      }
    }
    else if (this.state.search) {
      this.setState({
        searchValue: input,
        isSearching: input && input.length >= this.defaultSearchLength,
        items: input && input.length < this.defaultSearchLength ? this.props.items : this.state.items
      });

      // Yuck... Shouldn't need this but unfortunately, its the only way
      // to get the render done **now** to show the "Searching..." item.
      this.forceUpdate();

      this.debounced(input);
    }
  };

  callOnSearch = (query: string) => {
    if (this.props.onSearchItems && query.length >= this.defaultSearchLength) {
      void this.props.onSearchItems(query).then((items) => {
        this.setState({
          items: items,
          isSearching: false
        });
      });
    }
  };

  onLoadOptions = (query?: string) => {
    const searchQuery = query || this.state.searchValue;
    let previousOptons = [];
    let hasMoreOptions = true;

    if (query?.length < this.defaultSearchLength || this.hasMoreOptions[''] === false) {
      return;
    }

    if (this.previousLoadOptions[searchQuery]) {
      previousOptons = this.previousLoadOptions[searchQuery];
      hasMoreOptions = this.hasMoreOptions[searchQuery];
    }

    if (this.props.onLoadOptions) {
      if ((query && this.previousLoadOptions[query]) || hasMoreOptions === false) {
        this.setState({
          items: previousOptons,
          isSearching: false
        });
      }
      else {
        this.setState({
          isSearching: true
        });
        this.lastSearchedQuery = searchQuery;

        void this.props.onLoadOptions(searchQuery, previousOptons).then(({ options, hasMore = true, concatNewOptions = true }) => {
          this.previousLoadOptions[searchQuery] = concatNewOptions ? previousOptons.concat(options) : options;
          this.hasMoreOptions[searchQuery] = hasMore;

          if (this.lastSearchedQuery === searchQuery) {
            this.setState({
              items: this.previousLoadOptions[searchQuery],
              isSearching: false
            });
          }
        });
      }
    }
  };

  /**
   * Handle key up events on the dropdown div.
   */
  handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === this.ESC_KEY) {
      e.preventDefault();

      this.containerInputRef.current?.focus();
      this.setState({
        isOpen: false
      });
    }
    else if (e.key === this.UP_ARROW_KEY) {
      e.preventDefault();

      if (this.state.activeItem <= 0) {
        this.setState({
          activeItem: 0
        }, this.scrollItemIntoView);
      }
      else {
        this.setState({
          activeItem: this.state.activeItem - 1
        }, this.scrollItemIntoView);
      }
    }
    else if (e.key === this.DOWN_ARROW_KEY) {
      e.preventDefault();

      if (this.state.activeItem >= this.getTotalItems() - 1) {
        this.setState({
          activeItem: this.getTotalItems() - 1
        }, this.scrollItemIntoView);
      }
      else {
        this.setState({
          activeItem: this.state.activeItem + 1
        }, this.scrollItemIntoView);
      }
    }
    else if (e.key === this.ENTER_KEY) {
      if (this.state.activeItem >= 0 && this.state.activeItem <= this.getTotalItems() - 1) {
        const menuItem = this.getActiveItemAt(this.state.activeItem);

        this.setState({
          value: menuItem,
          activeItem: -1,
          isOpen: false
        }, () => {
          this.valueIndex = -1;
          this.onChange(-1, menuItem);
        });

        this.containerInputRef.current?.focus();

        e.preventDefault();
      }
    }
  };

  onChange = (index: number, menuItem) => {
    this.props.onChange && this.props.onChange(index, menuItem);
  };

  scrollItemIntoView = () => {
    // Using document.getElementsByClassName is ok here as there will
    // only be one of them open at a time.
    const listContentRef = document.getElementsByClassName('mdf-dropdown-menu-items')[0];
    const element = listContentRef?.querySelector('.mdf-dropdown-menu-active-item');

    if (listContentRef && element) {
      (listContentRef as HTMLElement).scrollTop = (element as HTMLElement).offsetTop - (listContentRef as HTMLElement).offsetTop;
    }
  };

  /**
   * Returns the number of items in the dropdown. Special handling for the grouped
   * dropdown.
   */
  getTotalItems = () => {
    if (this.props.type !== this.GROUPED) {
      return this.state.items.length;
    }
    else {
      let count = 0;

      for (const group of this.state.items) {
        count += group.items.length;
      }

      return count;
    }
  };

  /**
   * Finds the active item in the grouped dropdown
   */
  getActiveItemAt = (index: number) => {
    if (this.props.type !== this.GROUPED) {
      return this.state.items[index];
    }
    else {
      let count = 0;

      for (const group of this.state.items) {
        if (group.items) {
          for (const item of group.items) {
            if (count === this.state.activeItem) {
              return item;
            }

            count++;
          }
        }
      }
    }
  };

  getMenuWidth = () => {
    const element = findDOMNode(this) as HTMLElement;
    let width = 200;

    if (element) {
      const dropdown = element.querySelector('.mdf-dropdown-menu');

      if (dropdown) {
        const rect = dropdown.getBoundingClientRect();
        width = rect.width;
      }
    }

    return {
      minWidth: (width - 1) + 'px',
      width: width + 'px',
      maxWidth: (width * 2) + 'px'
    };
  };

  onFetchItems = () => {
    if (this.props.paginate) {
      return this.onLoadOptions;
    }
    else if (!this.props.search && this.state.searchValue === '') {
      return this.props.onFetchItems;
    }
  };

  /**
   * Renders the dropdown in the grouped style.
   */
  renderGroups = () => (
    <MDFInfiniteList aria-activedescendant={this.getActiveItemAt(this.state.activeItem)?.id} id={this.listId} onFetchItems={this.onFetchItems()} className={'mdf-dropdown-menu-items'}>
      {this.renderSearchingMessage()}
      {this.state.items.map((group, index) => this.renderGroupItems(group, index))}
      {this.renderNoItemsFoundMessage()}
    </MDFInfiniteList>
  );

  renderGroupItems = (group: any, groupIndex: number) => (
    <div role="group" aria-labelledby={this.groupId + groupIndex} className="mdf-dropdown-menu-grouped">
      <div id={this.groupId + groupIndex} className="mdf-dropdown-menu-grouped-title">{group.name}</div>
      {
        group.items.map((item, index) => {
          let classname = (item.id === this.state.value.id && item.value === this.state.value.value && item.subValue === this.state.value.subValue) ? 'mdf-dropdown-menu-grouped-item mdf-dropdown-menu-selected' : 'mdf-dropdown-menu-grouped-item';

          // Allow key up/key down to highlight items
          const active = this.findActiveItem();

          if (active.group === groupIndex && active.index === index) {
            classname += ' mdf-dropdown-menu-active-item';
          }

          return (
            <div role="option" key={`dd-group-item-${groupIndex}-${index}`} id={item.id} className={classname} onClick={(e) => this.handleItemClick(e, index, item)}>
              <div className="mdf-dropdown-menu-grouped-item-value">{item.value}</div>
              {item.subValue && <div className="mdf-dropdown-menu-grouped-item-subvalue">{item.subValue}</div>}
            </div>
          );
        })
      }
    </div>
  );

  /**
   * Used to determine which item, in which group, should be highlighted when using
   * the arrow keys. Only used when this.props.grouped is true.
   * returns - An object with the group index and item index of the active item.
   */
  findActiveItem = () => {
    if (this.props.type !== this.GROUPED) {
      return {
        group: -1,
        index: -1
      };
    }

    let groupIndex = 0;
    let itemIndex = 0;

    for (const group of this.state.items) {
      let groupItemIndex = 0;

      for (const _item of group.items) {
        if (itemIndex === this.state.activeItem) {
          return {
            group: groupIndex,
            index: groupItemIndex
          };
        }

        groupItemIndex++;
        itemIndex++;
      }

      groupIndex++;
    }

    return {
      group: -1,
      index: -1
    };
  };

  /**
   * Renders the dropdown in the search style.
   */
  renderComplex = () => {
    return (
      <MDFInfiniteList id={this.listId} onFetchItems={this.onFetchItems()} className={'mdf-dropdown-menu-items'}>
        {this.renderSearchingMessage()}
        {this.state.items.map((item, index) => this.renderComplexItem(item, index))}
        {this.renderNoItemsFoundMessage()}
      </MDFInfiniteList>
    );
  };

  renderComplexItem = (item: any, index: number) => {
    let classname = (item.id === this.state.value.id && item.value === this.state.value.value && item.subValue === this.state.value.subValue) ? 'mdf-dropdown-menu-complex-item mdf-dropdown-menu-selected' : 'mdf-dropdown-menu-complex-item';

    // Allow key up/key down to highlight items
    if (index === this.state.activeItem) {
      classname += ' mdf-dropdown-menu-active-item';
    }

    const subValue = item.subValue ? item.subValue : 'ID: ' + item.id;

    return (
      <div role="option" id={item.id} className={classname} onClick={(e) => this.handleItemClick(e, index, item)}>
        <div className="mdf-dropdown-menu-complex-item-value">{item.value}</div>
        <div className="mdf-dropdown-menu-complex-item-id">{subValue}</div>
      </div>
    );
  };

  /**
   * Handle the resize event. Basically, we just close the dropdown if its
   * open.
   */
  handleResize = (_e: any) => {
    this.setState({
      isOpen: false
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this._isMounted = true;
    if (this.props.paginate) {
      this.onLoadOptions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    this.timeoutManager.clearAllTimeouts();
    this._isMounted = false;
  }

  // Wait until the next screen redraw to focus the input.
  setInputFocus = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  /**
   * Update the state if certain properties change while the component
   * is mounted.
   * @param props - Current properties
   * @param state - Current state
   */
  static getDerivedStateFromProps(props, state) {
    const newState = Object.assign({}, state);
    let updated = false;

    if (props.disabled !== state.disabled) {
      updated = true;
      newState.disabled = props.disabled;
    }

    if (props.type !== state.type) {
      updated = true;
      newState.type = props.type;
      newState.items = props.paginate ? [] : props.items;
      newState.value = MDFDropdownMenu.EMPTY_ITEM;
      newState.searchValue = '';
    }

    if (!isEqual(props.items, state.items)) {
      updated = true;
      newState.items = (props.paginate || state.search || state.searchValue !== '') ? state.items : props.items;
    }

    if (props.search !== state.search) {
      updated = true;
      newState.search = props.search;
      newState.value = MDFDropdownMenu.EMPTY_ITEM;
      newState.searchValue = '';
    }

    if (props.isValid !== state.isValid) {
      updated = true;
      newState.isValid = props.isValid;
    }

    if (!isEqual(props.value, state.value)) {
      updated = true;
      newState.value = props.value;
    }

    if (props.paginate && (props.type !== state.type || props.dependentValue !== state.dependentValue)) {
      updated = true;
      newState.type = props.type;
      newState.dependentValue = props.dependentValue;
      newState.searchValue = '';
      newState.items = [];
    }

    if (updated) {
      return newState;
    }
    else {
      return null;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.paginate && (prevProps.type !== this.props.type || prevProps.dependentValue !== this.props.dependentValue)) {
      this.previousLoadOptions = [];
      this.hasMoreOptions = [];
      this.onLoadOptions();
    }
  }

  /**
   * Renders the "Searching item with a spinner that is placed
   * at the end of the list of items.
   */
  renderSearchingMessage = () => (
    <div aria-live="polite">
      {this.state.isSearching ? (<div className="mdf-dropdown-menu-searching">
        {this.props.paginate ? <div>{FormatHelper.formatMessage('@@Loading')}</div> : <div>{FormatHelper.formatMessage('@@Searching')}</div>}
        <i aria-hidden="true" className="fa fa-spin fa-spinner"></i>
      </div>) : ''}
    </div>
  );

  /**
   * Renders the "No items found" message that is placed
   * at the end of the list of items when search does not return items.
   */
  renderNoItemsFoundMessage = () => (
    <div aria-live="polite">
      {!this.state.isSearching && this.state.items.length === 0 ? (<div className="mdf-dropdown-menu-no-items-found">{FormatHelper.formatMessage('@@NoItemsFound')}</div>) : ''}
    </div>
  );

  /**
   * Renders the dropdown in the single style.
   */
  renderItems = () => {
    return (
      <MDFInfiniteList id={this.listId} onFetchItems={this.onFetchItems()} className={'mdf-dropdown-menu-items'} >
        {this.renderSearchingMessage()}
        {
          this.state.items.map((item, index) => {
            let classname = (item.id === this.state.value.id && item.value === this.state.value.value) ? 'mdf-dropdown-menu-single-item mdf-dropdown-menu-selected' : 'mdf-dropdown-menu-single-item';

            // Allow key up/key down to highlight items
            if (index === this.state.activeItem) {
              classname += ' mdf-dropdown-menu-active-item';
            }

            return <div role="option" key={`dd-item-${item.id || index}`} id={item.id} className={classname} onClick={(e) => this.handleItemClick(e, index, item)}>{item.value}</div>;
          })
        }
        {this.renderNoItemsFoundMessage()}
      </MDFInfiniteList>

    );
  };

  renderFooter = () => {
    return (
      <div className={'mdf-dropdown-menu-footer'}>
        <MDFButton
          buttonStyle="link"
          iconClass="fa fa-plus"
          className="link-button"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            this.setState({
              isOpen: !this.state.isOpen
            });

            this.props.onFooterLinkClick?.();
            this.containerInputRef.current?.focus();
          }}
        >
          {this.props.footerText || FormatHelper.formatMessage('@@Add')}
        </MDFButton>
      </div>
    );
  };

  renderSearchInput = (ariaProps) => {
    const style = this.getMenuWidth();
    const activeItem = this.getActiveItemAt(this.state.activeItem);

    return (
      <Popup
        show={this.state.isOpen}
        onEntered={this.setInputFocus}
        onHide={() => this.setState({ isOpen: false })}
        rootClose={true}
        role="combobox"
        restoreFocus={false}
        enforceFocus={this.props.renderFooter ? 'auto' : 'off'}
        popperConfig={{
          modifiers: [{
            name: 'offset',
            options: {
              // Added negative offset(-35) which is height of dropdownMenu to move the popup above target.
              offset: [0, -35]
            }
          },
          {
            name: 'setInitialWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              // remove the initial width which was added initially
              state.elements.popper.style.width = '';
            },
            effect: ({ state }) => {
              // initial popper element doesn't render and doesn't have width hence taking total width
              // and calculating the popperOffset based on the total width of the page
              // hence assigning the reference element width for the popper element
              state.elements.popper.style.width = `${(state.elements.reference as HTMLElement).offsetWidth}px`;
              return () => {};
            }
          }]
        }}
        target={() => this.containerRef.current}
        placement={'bottom-start'}
        id={`mdf-dropdown-menu-portal-${this.id}`}
        className={`mdf-dropdown-menu-popup ${this.props.containerClassName || ''}`}
        flip={true}
        updateOnMount={false}
      >
        <div tabIndex={-1} className="mdf-dropdown-menu-items-container">
          <div className="input-container">
            <div className="mdf-dropdown-menu-search-input" style={{ width: style.width }}>
              <input ref={this.inputRef}
                type="text"
                value={this.state.searchValue}
                onChange={(e) => this.handleSearchInputChange(e)}
                onKeyDown = {(e) => e.stopPropagation()}
                onKeyUp= {(e) => this.handleKeyUp(e)}
                aria-autocomplete="list"
                aria-owns={this.listId}
                aria-activedescendant={activeItem?.id} />
              <span className="mdf-angle-down"> <CaretDown /></span>
            </div>
            <div className="mdf-dropdown-close" onClick={(e) => this.handleBackgroundClick(e)}></div>
          </div>
          <div
            {...ariaProps}
            style={{ minWidth: style.minWidth, maxWidth: style.maxWidth }}
            onScroll={(e) => e.stopPropagation()}
            className={'mdf-dropdown-menu-content'}>
            {this.state.type === this.SINGLE && this.renderItems()}
            {this.state.type === this.GROUPED && this.renderGroups()}
            {this.state.type === this.COMPLEX && this.renderComplex()}
            {this.props.renderFooter && this.renderFooter()}
          </div>
        </div>
      </Popup>
    );
  };

  navigateFocus = (event) => {
    if (this.state.isOpen && activeElement() !== this.containerInputRef.current) {
      if (event.key === this.ESC_KEY || (event.key === this.TAB_KEY && !this.props.renderFooter)) {
        this.containerInputRef.current?.focus();
      }
    }
  };

  // This method is used by handleBlur and handleFocus from the @synerg/vdl-react-components, so do not delete this method.
  setTimeout = (key, callback, duration) => {
    if (this._isMounted) {
      return this.timeoutManager.setTimeout(key, callback, duration);
    }
  };

  handleDropdownFocus = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleFocus(focusMixinProps, event);
  };

  handleDropdownBlur = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleBlur(focusMixinProps, event, () => {
      this.setState({ isOpen: false });
    });
  };

  render() {
    const { autofocus } = this.props;
    const ariaInvalid = resolveAriaProperty('MDFDropdownMenu', 'aria-invalid', 'ariaInvalid', this.props);
    const ariaRequired = resolveAriaProperty('MDFDropdownMenu', 'aria-required', 'ariaRequired', this.props);
    let classname = this.state.disabled ? 'mdf-dropdown-menu mdf-dropdown-menu-disabled' : 'mdf-dropdown-menu';
    const valueClassname = this.state.value ? 'mdf-dropdown-menu-value' : 'mdf-dropdown-menu-value mdf-dropdown-menu-placeholder';
    const closeIconClass = (!this.state.disabled && !this.state.isOpen && this.state.value?.value) ? 'mdf-close-icon' : 'mdf-close-icon hide-icon';
    const ariaProps = pick(this.props, ['aria-label', 'aria-labelledby']);

    // In case of ValidatedDropdownMenu, ValidatedField appends error className(this.props.className) based on validation.
    // so below condition needed only if app uses DropdownMenu instance and wants to set isValid.
    if (!this.props.validatedField && (this.props.isValid === false)) {
      classname = classname + ' mdf-dropdown-menu-error';
    }

    if (this.state.focused) {
      classname = classname + ' mdf-dropdown-menu-focused';
    }

    return (
      <div tabIndex={-1} onKeyDown={this.navigateFocus} onFocus={this.handleDropdownFocus} onBlur={this.handleDropdownBlur} className={'mdf-dropdown-container' + (this.props.className ? ' ' + this.props.className : '')}>
        <div className={classname}
          tabIndex={this.props.tabIndex || -1}
          role="combobox"
          aria-expanded={this.state.isOpen || false}
          aria-required={ariaRequired}
          aria-invalid={ariaInvalid}
          ref={this.containerRef}
          aria-disabled={this.state.disabled}
          onKeyPress={(k) => this.handleDropdownKeyPress(k)}
          onKeyDown={(k) => this.handleDropdownKeyDown(k)}
          onClick={(e) => this.handleDropDownMenuClick(e)}>
          <input id={this.id} ref={this.containerInputRef} {...ariaProps} className={valueClassname} value={this.state.value.value} placeholder={this.props.placeholder} autoFocus={autofocus} disabled={this.state.disabled} />
          <span className={closeIconClass}><CloseIcon onClick={(e) => this.handleClearClick(e)} /></span>
          <span className="mdf-angle-down"><CaretDown /></span>
        </div>
        {this.state.isOpen && this.renderSearchInput(ariaProps)}
      </div>
    );
  }
}
