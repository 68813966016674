import React from 'react';
import 'dragula/dist/dragula.min.css';

export interface IMDFDropTargetProps {
  // targetId
  targetId?: string;
  // Notify when the items are dropped and call application onItemDropped
  onItemDropped?: (id) => void;
  // To dispath application dragEnter
  dragEnter?: (e) => void;
  // Call Application dragL
  dragLeave?: () => void;
  // The children to render within this component.
  children: any;
  // CSS class name used to override styling.
  className?: string;
}

export const MDFDropTarget = (props: IMDFDropTargetProps) => {
  const dragOver = (ev) => {
    ev.preventDefault();
  };

  const drop = (ev) => {
    // Deleting ghost image element.
    const dragImage = document.getElementById('mdf-drag-image');

    if (dragImage && dragImage.parentNode) {
      dragImage.parentNode.removeChild(dragImage);
    }

    const droppedItem = ev.dataTransfer.getData('Text');

    if (droppedItem && props.onItemDropped) {
      props.onItemDropped(props.targetId);
    }

  };

  const dragEnter = (ev) => {
    props.dragEnter && props.dragEnter(ev);
  };

  const dragLeave = () => {
    props.dragLeave && props.dragLeave();
  };

  return (
    <div
      onDragOver={dragOver}
      id={props.targetId}
      onDrop={drop}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      className={`mdf-drop-target ${props.className ?? ''}`}
    >
      {props.children}
    </div>
  );
};

MDFDropTarget.displayName = 'MDFDropTarget';
