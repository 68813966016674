import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

export interface IColumnHeader {
  label: string;
  id: string;
  sortable?: boolean;
  className?: string;
}

export interface IMDFListHeaderProps {
  columnHeaders: IColumnHeader[];
  className?: string;
  onSort?: (columnId) => any;
  defaultSortable?: boolean;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
}

export const MDFListHeader = (props: IMDFListHeaderProps) => {
  const listHeaderRef = useRef(null);

  const handleHeaderClick = (id) => {
    props.onSort && props.onSort(id);
  };

  const renderColumnHeader = (column, idx) => {
    const currentSortColumn = props.sortBy === column.id;
    const sortable = column.sortable === false ? column.sortable : column.sortable || props.defaultSortable;

    return (
      <div className={classNames('mdf-list-header-column' , column.className)} key={idx}>
        <span className="mdf-list-header-column-name">{column.label}</span>
        {sortable && <span onClick={() => handleHeaderClick(column.id)}><i className={'fa fa-sort' + `${currentSortColumn ? '-' + props.sortDirection : ''}`}></i></span>}
      </div>
    );
  };

  const updateWidth = () => {
    if (listHeaderRef.current && listHeaderRef.current.parentElement) {
      const contentNodeRef = listHeaderRef.current.parentElement.querySelector('[data-list-header-id="mdfInfiniteList"]');

      if (contentNodeRef && contentNodeRef.clientWidth !== listHeaderRef.current.clientWidth) {
        listHeaderRef.current.style.width = contentNodeRef.clientWidth + 'px';
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div ref={listHeaderRef} className={classNames('mdf-list-header' , props.className)}>
      {props.columnHeaders.map(renderColumnHeader)}
    </div>
  );
};
