import React from 'react';
import ReactDOM from 'react-dom';
import CalenderIcon from 'adp-react-icons/lib/fa/calendar';
import PropTypes from 'prop-types';
import { INewsFeedItemProps, NewsFeedItem } from './NewsFeedItem';
import { MDFButton } from './MDFButton';
import classNames from 'classnames';
import { MDFIcon } from './MDFIcon';
import { MDFCircleIcon } from './MDFCirlceIcon';
import { FormatHelper } from '@adp-wfn/mdf-core';

const ISO_DATEONLY_FORMAT = /\d{4}-\d{2}-\d{2}$/;

export interface IFeedList {
  // Optional Date as string
  date?: string;
  // Title for each newsFeed item
  listTitle?: string;
  // Sub Title for each newsFeed item
  subTitle?: string;
  // Icon that goes inside the circle
  icon?: string;
  // ClassName to style the circle
  iconClassName?: string;
  // News Feed items
  feedItems: INewsFeedItemProps[];
}

export interface INewsFeedProps {
  title?: string;
  feedList?: IFeedList[];
  className?: string;
  onClick?: (item: any) => void;
  itemCount?: number;
  hasDateTime?: boolean;
}

export class NewsFeed extends React.Component<INewsFeedProps, any> {
  static propTypes = {
    title: PropTypes.string,
    feedList: PropTypes.any,
    className: PropTypes.string,
    onClick: PropTypes.func,
    itemCount: PropTypes.number,
    hasDateTime: PropTypes.bool
  };

  private newsFeedElement;

  constructor(props: INewsFeedProps) {
    super(props);

    this.newsFeedElement = React.createRef();
  }

  renderHeader = (date?: Date | string) => {
    // Assume the input Date is an ISOString of 'yyyy-mm-dd'
    const currentDate = new Date();
    const todayDate = currentDate.toISOString().split('T')[0];

    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const yesterday = new Date(Date.now() - millisecondsPerDay).toISOString().split('T')[0];

    // Check if the date is equal to the current date.
    if (typeof date === 'string') {
      if (ISO_DATEONLY_FORMAT.test(date)) {
        if (date === todayDate) {
          return FormatHelper.formatMessage('@@Today');
        }
        else if (date === yesterday) {
          return FormatHelper.formatMessage('@@Yesterday');
        }
      }
    }

    return date;
  };

  static getDerivedStateFromProps(props, state) {
    if (!state) {
      // If there is no state, then this is the first render.
      return {
        previousCount: props.itemCount || 0,
        itemCount: props.itemCount || 0
      };
    }

    if (props?.hasOwnProperty('itemCount') && (props.itemCount !== state?.previousCount)) {
      return {
        previousCount: props.itemCount,
        itemCount: props.itemCount
      };
    }

    return null;
  }

  handleRefresh = (scrollToTop: boolean) => {
    if (scrollToTop) {
      // scroll to the top and change the number indicator
      const getElement = ReactDOM.findDOMNode(this.newsFeedElement.current) as HTMLElement;
      getElement.scrollTop = 0;
    }

    // Once the items are refreshed set the itemCount back to 0 to remove the refreshIcon
    this.setState({ itemCount: 0 });
  };

  // render the refresh button only when the itemCount is greater than 0.
  renderRefresh = () => (
    <div className="newsFeed--refresh-button">
      <MDFButton
        buttonStyle="primary"
        onClick={() => this.handleRefresh(true)}
        iconClass={'fa fa-arrow-up'}
      >
        {FormatHelper.formatMessage('@@newUpdates')}
      </MDFButton>
    </div>
  );

  render() {
    const { feedList, className, hasDateTime } = this.props;

    return (
      <div className="newsFeed">
        {this.props.title &&
          <div className="newsFeed--header">
            <div className="newsfeed--title">{this.props.title}</div>
          </div>
        }
        <div
          className={classNames(className, 'newsFeedDetails')}
          ref={this.newsFeedElement}
          onClick={() => this.handleRefresh(false)}
        >
          {this.state.itemCount > 0 &&
            <div className="newsfeed--refresh">
              {this.renderRefresh()}
            </div>
          }
          {feedList && feedList.map((mainItem, index) => (
            <div key={`mainItem-${index}`}>
              <div className="newsfeed--headerWrapper">
                <div className={mainItem.icon ? '' : 'newsfeed--circle'}>
                  {
                    mainItem.icon ? <MDFCircleIcon size="xs" className={mainItem.iconClassName ?? 'newsFeed--statusCircle'}><MDFIcon className={mainItem.icon} /></MDFCircleIcon> : <CalenderIcon className="newsfeed--icon" />
                  }
                </div>
                <div className="newsfeed--blockHeader">{mainItem.date && this.renderHeader(mainItem.date) || mainItem.listTitle}</div>
              </div>
              {mainItem.subTitle && <div className="newsfeed--subHeader">{mainItem.subTitle}</div>}
              {mainItem.feedItems ? mainItem.feedItems.map((item) => (
                <NewsFeedItem
                  key={item.itemId}
                  url={item.url}
                  time={item.time}
                  hasDateTime={hasDateTime}
                  initials={item.initials}
                  itemId={item.itemId}
                  text={item.text}
                  onClick={this.props.onClick}
                />
              )) : (feedList.length - 1 === index) ? '' : <NewsFeedItem text={' '}/>
              }
            </div>
          ))
          }
        </div>
      </div>
    );
  }
}
