import React, { useEffect, useState } from 'react';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/languages/es';
import 'froala-editor/js/languages/fr';
import 'froala-editor/js/languages/en_ca';
import FroalaEditor from 'react-froala-wysiwyg';
import { LocaleHelper } from '@adp-wfn/mdf-core';
import '../scss/froalaEditor.scss';

declare const window: any;

const packageJSON = require('../../package.json');
const mdfComponentsVersion = packageJSON.version;

export const pathToHostedCSS = `https://${window.wfnCdnPath || 'static.workforcenow.adp.com/mas'}/mdf-components/${mdfComponentsVersion}/styles`;

export interface IMDFFroalaEditorProps {
  // An object containing Froala editor options listed here: https://www.froala.com/wysiwyg-editor/docs/options
  config: any;

  // Indicates whether the editor should be editable.
  disabled?: boolean;

  // Called whenever the value changes. The new value is passed in, along with a decorator function that adds Froala styling needed for use outside
  // of MDF applications, such as when including content generated by Froala within emails.
  onChange?: (value: string, decorator?: () => string) => void;

  // Set a reference to the Froala editor object. This is needed in order to call its methods to achieve things not accessible via properties (e.g. enable/disable).
  setFroala?: (editor: object) => void;

  // A string of text containing valid HTML.
  value?: string;

  // callback function to assign background colors to resolved and un-resolved placeholders incase of initital load of placeholders - need not to be set from application.
  froalaInitialized?: (froala) => void;

  // When using MDFFormValidatedFroalaEditor, verifies invalid status onblur.
  validationCheck?: () => void;

  // Label for the textarea (accessibility)
  labelTextarea?: string;
}

export const froalaStyleDecorator = (value: string): string => {
  return `
    <link href="${pathToHostedCSS}/froala_editor.pkgd.min.css" rel="stylesheet" type="text/css" />
    <link href="${pathToHostedCSS}/froala_style.min.css" rel="stylesheet" type="text/css" />

    <div class="fr-view">
      ${value}
    </div>
  `;
};

export const MDFFroalaEditor = (props: IMDFFroalaEditorProps) => {
  const [froala, setFroala] = useState(null);

  const onChange = (newValue: string) => {
    if (props.onChange) {
      props.onChange(newValue, () => froalaStyleDecorator(newValue));
    }
  };

  useEffect(() => {
    if (froala?.edit) {
      if (props.disabled) {
        froala.edit.off();
      }
      else {
        froala.edit.on();
      }
    }
  }, [props.disabled]);

  const getLanguage = () => {
    let language = LocaleHelper.getUserLocale();

    // Froala currently supports the following locales of interest to WFN: en_us, en_ca, es, fr. So we must convert the user's locale to one of these values.
    if (language.startsWith('en')) {
      language = language.replace('-', '_');
    }
    else {
      language = language.substring(0, 2);
    }

    return language;
  };

  // When using MDFFormValidatedFroalaEditor, blur event helps verify invalid status. When the component is touched and has no value, then the error message appears.
  const runValidationCheck = () => {
    if (props.validationCheck) {
      props.validationCheck();
    }
  };

  const config = {
    events: {
      blur: function() {
        runValidationCheck();
      }
    },
    fileUseSelectedText: true,
    fontFamily: {
      'arial,sans-serif': 'Sans Serif',
      '\'times new roman\',serif': 'Serif',
      'monospace': 'Fixed Width',
      '\'arial black\',sans-serif': 'Wide',
      '\'arial narrow\',sans-serif': 'Narrow',
      '\'comic sans ms\',sans-serif': 'Comic Sans MS',
      'garamond,\'times new roman\',serif': 'Garamond',
      'georgia,serif': 'Georgia',
      'tahoma,sans-serif': 'Tahoma',
      '\'trebuchet ms\',sans-serif': 'Trebuchet MS',
      'verdana,sans-serif': 'Verdana'
    },
    language: getLanguage(),
    toolbarSticky: false,
    zIndex: 1000,
    ...props.config,
    lineHeights: {
      'Default': '',
      '1.0': 1.0,
      '1.15': 1.15,
      '1.5': 1.5,
      '2.0': 2.0
    },
    attribution: false,
    emoticonsUseImage: false,
    key: 'lSA3D-17D1D2G2C1D1F1rXYb1VPUGRHYZNRJd1JVOOb1HAc1zG2B1A2B1D6B1C1C4E1B3=='
  };

  config.events.initialized = function() {
    setFroala(this);

    if (props.setFroala) {
      props.setFroala(this);
    }

    if (props.disabled && this.edit) {
      this.edit.off();
    }

    if (props.froalaInitialized) {
      props.froalaInitialized(this);
    }

    const setHtml2Pdf = () => this.opts.html2pdf = window.html2pdf;
    this.$box[0]?.removeAttribute('role');

    // Accessibility: editable text area requires role and aria-label.
    if (props.labelTextarea) {
      this.$box[0].setAttribute('aria-label', props.labelTextarea);
      this.$box[0].setAttribute('role', 'region');
    }

    const orderedListEl = this.$tb[0]?.querySelector('[id^=\'formatOLOptions\']');

    if (orderedListEl) {
      orderedListEl.setAttribute('aria-label', orderedListEl.title);
    }

    const unorderedListEl = this.$tb[0]?.querySelector('[id^=\'formatULOptions\']');

    if (unorderedListEl) {
      unorderedListEl.setAttribute('aria-label', unorderedListEl.title);
    }

    if (window.html2pdf) {
      setHtml2Pdf();
    }
    else {
      // The 2nd argument to addScript is the onload function called after the html2pdf library is fetched, but before it's
      // done executing/initializing. It's a large library, so we must give it a good head start so that it will have time to
      // finish & set window.html2pdf before we reference it.
      window.WFNShell?.addScript('/theme/static/editor/html2pdf.bundle.min.js', () => setTimeout(setHtml2Pdf, 500));
    }
  };

  return (
    <FroalaEditor
      config={config}
      tag="textarea"
      model={props.value}
      onModelChange={onChange}
    />
  );
};

MDFFroalaEditor.displayName = 'MDFFroalaEditor';
