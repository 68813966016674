import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { Alert } from '../../Alert';

export interface IWFNMockShellMMNavLevel2NoItems {
  name: string;
  level1ItemIndex: number;
  additionalSubnavMargin: number;
}

export const WFNMockShellMMNavLevel2NoItems = ({ name, level1ItemIndex, additionalSubnavMargin } : IWFNMockShellMMNavLevel2NoItems) => {
  const messageText = FormatHelper.formatMessage('@@label_profiles_header_resource_without_children', [name]);

  return (
    <div className="wfnmockshell-top-item--wrapper wfnmockshell-expanded">
      <div className="wfnmockshell-top-item--wrapper-dart" />
      <div className="wfnmockshell-sub mdf-mock-shell-wfnmockshell-slide-down flex" style={{ marginLeft: `calc(-${level1ItemIndex * 100}% + ${additionalSubnavMargin}px)` }}>
        <Alert className="text-center p-6" type="neutral" size="md" role="alert" alertStyle="inline">
          {messageText}
        </Alert>
      </div>
    </div>
  );
};

WFNMockShellMMNavLevel2NoItems.displayName = 'WFNMockShellMMNavLevel2NoItems';
