import React from 'react';
import { LayoutItem } from './LayoutItem';
import { renderResponsiveClass } from './ResponsiveClass';
import { LayoutBoxContext } from '../MDFContext';
import PropTypes from 'prop-types';
import { useMedia } from './MediaQueryListener';
import { FeatureFlags } from '@adp-wfn/mdf-core';

export type IGutterWidth = '5' | '10' | '15' | '20';
export type IColumnCount = 1 | 2 | 3 | 4 | 6 | 12;

export interface ILayoutBoxProps {
  // Style to apply for the layoutBox
  className?: string;
  // Number of columns per row
  columnCount?: IColumnCount;
  // Only accepts 5, 10, 15 or 20. Will apply half the gutterWidth to all four sides of the item.
  gutterWidth?: IGutterWidth;
  // only takes children that are a layoutItem
  children?: any;
  // to lay the cards horizontally or vertically
  isHorizontal?: boolean;
  // use the Waypoint version of the layout styles, will be ignored once the feature flag is removed
  isWaypoint?: boolean;
}

export function LayoutBox(props: ILayoutBoxProps) {
  const hasOldShell = (window as any).isLegacyAppShell || (window as any).isClassicShell;
  const hasWaypointCards = FeatureFlags.hasFeature('waypointCards') && !hasOldShell;
  const baseClass = props.isWaypoint || hasWaypointCards ? 'layoutBox2' : 'layoutBox';

  // Call useMedia only when vertical Alignment property/isHorizontal is set false.
  const columnCount = useMedia(
    // Media queries
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 768px)', '(min-width: 576px)', '(min-width: 360px)'],
    // Column counts (relates to above media queries by array index)
    [props.columnCount, Math.min(props.columnCount, 3), Math.min(props.columnCount, 2), Math.min(props.columnCount, 2), Math.min(props.columnCount, 1)],
    // Default column count
    props.columnCount,
    props.isHorizontal
  );

  // create arrays based on the count
  const splitArray = (arrayItems, size) => {
    const columnArray = [];

    // create empty number of arrays based on the size
    for (let i = 0; i < size; i++) {
      columnArray.push([]);
    }

    for (let j = 0; j < arrayItems.length; j++) {
      for (let i = 0; i < columnArray.length; i++) {
        if (j % size === i) {
          columnArray && columnArray[i].push(arrayItems[j]);
        }
      }
    }

    return columnArray;
  };

  // rendered only for vertical alignments/isHorizontal is set to false.
  const renderColumns = (columnData) => {
    // Horizontal rendering renders the columns as-is.
    if (props.isHorizontal) {
      return columnData;
    }

    const numberOfColumn = columnCount && Number(columnCount);
    const columnsArray: any = splitArray(columnData, numberOfColumn);

    return ((columnsArray as any[]).map((item: any, index) => (
      <div key={`lb-column-${index}`} className={`layoutBox layout-column ${renderResponsiveClass(numberOfColumn)} gutterWidth-${props.gutterWidth}`}>
        {item}
      </div>
    )));
  };

  const { className } = props;
  const columns: JSX.Element[] = [];

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === LayoutItem) {
        columns.push(child);
      }
      else {
        console.error('All children of the LayoutBox must be a LayoutItem component');
        return null;
      }
    }
    else {
      return null;
    }
  });

  const boxProps = {
    className: ''
  };

  if (props.isHorizontal) {
    boxProps.className = `${baseClass} horizontal-layout layout-row gutterWidth-${props.gutterWidth} ${(className || '')}`;
  }
  else {
    boxProps.className = `${baseClass} layout-row offsetPadding-${props.gutterWidth} ${(className || '')}`;
  }

  // remove the mdf-container and mdf-row since the container padding is 30px
  return (
    <LayoutBoxContext.Provider value={{ columnCount: props.columnCount, gutterWidth: props.gutterWidth, isHorizontal: props.isHorizontal }}>
      <div {...boxProps}>
        {renderColumns(columns)}
      </div>
    </LayoutBoxContext.Provider>
  );
}

LayoutBox.displayName = 'LayoutBox';

LayoutBox.proptypes = {
  gutterWidth: PropTypes.oneOf(['5', '10', '15', '20']),
  columnCount: PropTypes.oneOf([1, 2, 3, 4, 6, 12]) && PropTypes.number,
  isHorizontal: PropTypes.bool,
  className: PropTypes.string
};

LayoutBox.defaultProps = {
  isHorizontal: true,
  columnCount: 3,
  gutterWidth: 10
};
