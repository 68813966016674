import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FocusLock from 'react-focus-lock/dist/cjs';

export interface IMDFBackdropProps {
  // A css class or set of classes applied to the portal container
  containerClassName?: string;
  // Child nodes to render within the backdrop.
  children: React.ReactNode;
  // Show or hide the backdrop.
  show: boolean;
}

export const MDFBackdrop = (props: IMDFBackdropProps) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {

    props.show ? setOpen(true) : setOpen(false);

  }, [props.show]);

  return ReactDOM.createPortal(open && <div className={'mdf ' + (props.containerClassName || '')}><div className="mdf-backdrop"><FocusLock autoFocus={true} returnFocus={true}>{props.children}</FocusLock></div></div>, document.body);
};

MDFBackdrop.displayName = 'MDFBackdrop';
