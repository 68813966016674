import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { froalaStyleDecorator, MDFFroalaEditor, pathToHostedCSS } from './MDFFroalaEditor';
import { MDFModalDialog2 } from './MDFModalDialog2';
import { MDFCurrencyBox } from './MDFCurrencyBox';
import { MDFTextbox } from './MDFTextbox';
import { SdfDatePicker } from '@waypoint/react-components';
import { COUNTRY_TO_CURRENCY_CODE, FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
import { MDFTextarea } from './MDFTextarea';
import { MDFButton } from './MDFButton';
import { IMenuItem, MDFDropdownMenu } from './MDFDropdownMenu';
import { MDFNumberInput } from './MDFNumberInput';
import { DateHelper } from './DateHelper';

export interface IMDFFroalaEditorPlaceholdersProps {
  // A list of dropdown menu options corresponding to the currently displayed dropdown in the modal dialog.
  currentComboOptions?: IMenuItem[];

  // A CSS class name applied to the placeholder dialog container.
  dialogClassName?: string;

  // Indicates whether the editor should be editable.
  disabled?: boolean;

  // Indicates whether to prevent the editing of placeholders. If this property is true, placeholders will not be clickable.
  disablePlaceholderEdit?: boolean;

  // Placeholder dropdown menu placeholder text (sounds redundant, but isn't).
  dropdownPlaceholder?: string;

  // Options object passed to the Froala editor. See options listed here: https://www.froala.com/wysiwyg-editor/docs/options
  editorConfig?: any;

  // Label of Insert button.
  insertButtonLabel?: string;

  // Label for the fieldset legend.
  label: string;

  // Label for the textarea (accessibility)
  labelTextarea?: string;

  // Culture value of the currency input for insert placeholders
  culture?: string;

  // Called whenever the value changes. A change object is passed in.
  onChange?: (change: IMDFFroalaEditorPlaceholdersChange) => void;

  // Called every time a combo placeholder popup is launched. This event handler must set props.currentComboOptions to the options list corresponding to comboservice.
  onLaunchComboOptions?: (comboservice: string) => string[];

  // List of supported placeholder types. Populates the dropdown options.
  placeholders?: IPlaceholder[];

  // Use simple template string format (i.e. {templateName}).
  useSimpleTemplates?: boolean;

  // A string containing valid HTML to initialize the Froala editor content.
  value?: string;

  // One of `single` | `complex`
  comboType?: 'single' | 'complex';

  // Enable comboPaginate mode. The component will call `comboOnLoadOptions` when the user scrolls to the end of the list of items.
  comboPaginate?: boolean;

  // When `comboPaginate` is true, the component will call this function when the user scrolls to the end of the list of items.
  comboOnLoadOptions?: (query: string, previousOptions: IMenuItem[]) => Promise<any>;
}

export interface IMDFFroalaEditorPlaceholdersChange {
  isValid: boolean;
  value: string;
  froalaStyleDecorator: () => string;
}

interface IPlaceholder {
  comboService?: string;
  dataType?: string;
  desc?: string;
  groupName?: string;
  name: string;
  oid?: string;
}

export const MDFFroalaEditorPlaceholders = (props: IMDFFroalaEditorPlaceholdersProps) => {
  // fr-deletable is a Froala CSS class that enables an application to override the default Froala behavior preventing an element
  // with attribute contenteditable="false" (i.e. placeholders) from being deleted via the delete key.
  const froalaDeletableClass = 'fr-deletable';
  const getDefaultMenuItem = () => ({ id: '', value: '' });

  // Hooks
  const [froala, setFroala] = useState(null);
  const [isDialogVisible, setDialogVisible] = useState(false);
  const [placeholderNode, setPlaceholderNode] = useState(null);
  const [placeholderStringValue, setPlaceholderStringValue] = useState('');
  const [placeholderComboValue, setPlaceholderComboValue] = useState(getDefaultMenuItem());
  const [insertPlaceholderOption, setInsertPlaceholderOption] = useState(getDefaultMenuItem());

  // Validate whether all placeholders have a value.
  const validate = () => !Array.from(froala.el.querySelectorAll('span[oid]')).some((item: HTMLSpanElement) => item.innerHTML.startsWith('['));

  const onChange = (value: string) => {
    if (froala && props.onChange) {
      props.onChange({ value, isValid: validate(), froalaStyleDecorator: () => froalaStyleDecorator(value) });
    }
  };

  const createPlaceholderHTML = (placeholder: IPlaceholder) => {
    let displayedValue;
    let valueExists = false;

    // Check for an existing placeholder of the same kind. If one exists, use its value as the initial value of this new placeholder.
    const existingPlaceholder = froala.el.querySelector(`span[oid="${placeholder.oid}"]`);

    if (existingPlaceholder) {
      displayedValue = existingPlaceholder.innerHTML;
    }
    else {
      displayedValue = `[${placeholder.name}]`;
    }

    if (!(displayedValue.startsWith('[') && displayedValue.endsWith(']'))) {
      valueExists = true;
    }

    return `<span
      class="${froalaDeletableClass}"
      contenteditable="false"
      oid="${placeholder.oid}"
      label="${placeholder.name}"
      ${valueExists && 'data-hasvalue=true'}
      datatype="${placeholder.dataType}"
      comboservice="${placeholder.comboService}"
    >${displayedValue}</span>`;
  };

  const createTemplate = () => {
    return `{${props.placeholders[insertPlaceholderOption.id].name}}`;
  };

  const insertPlaceholder = () => {
    froala.html.insert(props.useSimpleTemplates ? createTemplate() : createPlaceholderHTML(props.placeholders[Number(insertPlaceholderOption.id)]), true);
    setInsertPlaceholderOption(getDefaultMenuItem());
    onChange(froala.html.get());

    // The newly inserted placeholder element is selected by default by Froala. We need to relocate the selection to after this new element so that
    // a subsequent placeholder insertion does not get nested within the previous placeholder insertion. Nobody wants nested placeholders.
    froala.selection.setAfter(froala.selection.element());
    froala.selection.restore();
  };

  const handleEditorClick = (event) => {
    if (event.target.hasAttribute('oid')) {
      console.log('item clicked, event=', event);
      setPlaceholderNode(event.target);
      setDialogVisible(true);

      const dataType = event.target.getAttribute('datatype');
      const isValueBlank = event.target.innerHTML.charAt(0) === '[';

      if (dataType === 'Calendar') {
        let isoDateString = event.target.getAttribute('data-isodate');

        if (!isoDateString) {
          try {
            isoDateString = DateHelper.getIsoDate(event.target.innerHTML);
          }
          catch (_error) {
            isoDateString = null;
          }
        }

        setPlaceholderStringValue(isValueBlank ? null : isoDateString);
      }
      else if (dataType === 'Combo') {
        if (props.onLaunchComboOptions) {
          props.onLaunchComboOptions(event.target.getAttribute('comboservice'));
        }
      }
      else if (dataType === 'TextArea') {
        setPlaceholderStringValue(isValueBlank ? '' : event.target.innerHTML.replace(/<br>/g, '\n'));
      }
      else if (dataType === 'Currency') {
        // Strip out formatting characters, leaving behind nothing but digits and the decimal separator.
        setPlaceholderStringValue(event.target.innerHTML.replace(new RegExp(`[^0-9${MDFNumberInput.getlocaleChar(props)}]`, 'g'), ''));
      }
      else {
        setPlaceholderStringValue(isValueBlank ? '' : event.target.innerHTML);
      }
    }
  };

  // When the application has provided a newly selected dropdown's option list, set it here so that the dropdown will use it.
  useEffect(() => {
    if (placeholderNode && placeholderNode.hasAttribute('comboservice') && props.currentComboOptions) {
      const currentValue = placeholderNode.innerHTML;

      setPlaceholderComboValue(props.currentComboOptions.find((option) => option.value === currentValue) || getDefaultMenuItem());
    }
  }, [placeholderNode, props.currentComboOptions]);

  // callback function to assign background colors to resolved and un-resolved placeholders incase of initital load of placeholders.
  const froalaInitialized = (component) => {
    if (window['isADPUnified']) {
      component.el.classList.add('one-ux');
    }

    // Accessibility: iframe requires a title attribute.
    const title = props.label || FormatHelper.formatMessage('@@TextEditor');
    component.$iframe[0].setAttribute('title', title);

    // Accessibility: editable text area requires role and custom aria-label.
    if (props.labelTextarea) {
      component.$el[0].setAttribute('aria-label', props.labelTextarea);
      component.$el[0].setAttribute('role', 'region');
    }

    setTimeout(() => {
      const placeHoldersOnLoad: HTMLElement[] = Array.from(component.el.querySelectorAll('span[oid]'));

      placeHoldersOnLoad?.forEach((item) => {
        const domNode = ReactDOM.findDOMNode(item) as Element;
        const dataType = item.getAttribute('datatype');

        // When user first selects US locale and later changes the locale to en-ca for testing
        // make sure the date is updated to current locale onload if the new attribute is available.
        const isoDate = item.getAttribute('data-isodate');

        if (dataType === 'Calendar' && isoDate) {
          item.innerText = FormatHelper.formatDate(isoDate, 'short', LocaleHelper.getUserLocale());
        }

        if (!(item.innerText.startsWith('[') && item.innerText.endsWith(']'))) {
          domNode.setAttribute('data-hasvalue', 'true');
        }

        domNode.classList.add(froalaDeletableClass);
      });
    }, 0);
  };

  // VDL colors: placeholders with no value use accent-5-light. placeholders with a value use accent-0-light.
  const config = {
    events: {},
    ...props.editorConfig,
    htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap', 'oid', 'datatype', 'comboservice'],
    iframe: true,
    iframeStyle: `
      html {
        background-color: white !important;
        height: auto !important;
      }

      span[oid] {
        background-color: #f9f08f;
        ${props.disablePlaceholderEdit ? '' : 'cursor: pointer;'}
      }

      span[data-hasvalue=true] {
        background-color: #89e3f9;
      }

      .fr-disabled span[oid] {
        cursor: auto;
      }

      body.fr-view blockquote {
        border-color: inherit;
        color: inherit;
      }
    `,
    iframeStyleFiles: [`${pathToHostedCSS}/froala_style.min.css`, `${pathToHostedCSS}/vdl-css-framework.css`]
  };

  if (!props.disablePlaceholderEdit) {
    config.events.click = handleEditorClick;
  }

  const dialogOkClicked = () => {
    const dataType = placeholderNode.getAttribute('datatype');
    let displayedValue = '';
    let valueExists = true;

    if (dataType === 'Calendar') {
      if (placeholderStringValue) {
        displayedValue = FormatHelper.formatDate(placeholderStringValue);
      }
    }
    else if (dataType === 'Combo') {
      if (placeholderComboValue) {
        displayedValue = placeholderComboValue.value;
      }
    }
    else if (dataType === 'ComboAsync') {
      if (placeholderComboValue) {
        displayedValue = placeholderComboValue.value;
      }
    }
    else if (dataType === 'TextArea') {
      if (placeholderStringValue) {
        displayedValue = placeholderStringValue.replace(/\n/g, '<br>');
      }
    }
    else if (dataType === 'Currency') {
      displayedValue = placeholderStringValue === null ? '' :
        FormatHelper.formatNumber(placeholderStringValue, { style: 'currency', currency: COUNTRY_TO_CURRENCY_CODE[LocaleHelper.getCountryCode()] }, props.culture);
    }
    else if (placeholderStringValue) {
      displayedValue = placeholderStringValue;
    }

    if (!displayedValue) {
      displayedValue = `[${placeholderNode.getAttribute('label')}]`;
      valueExists = false;
    }

    const oids = Array.from(froala.el.querySelectorAll(`[oid="${placeholderNode.getAttribute('oid')}"]`));

    oids.forEach((item: Element) => {
      item.innerHTML = displayedValue;

      if (dataType === 'Calendar') {
        placeholderStringValue && item.setAttribute('data-isodate', placeholderStringValue);
      }

      if (valueExists) {
        // this is needed to differntiate placeholders with value and with out value
        item.setAttribute('data-hasvalue', 'true');
      }
      else {
        // Removing the data-hasvalue attribute on span element when there is no value for the placeholder
        item.removeAttribute('data-hasvalue');
      }
    });

    setDialogVisible(false);
    onChange(froala.html.get());
  };

  const popupDropdownOnChange = (_index, item: IMenuItem) => {
    setPlaceholderComboValue(item);
  };

  const getInputComponent = () => {
    switch (placeholderNode && placeholderNode.getAttribute('datatype')) {
      case 'Calendar':
        return <SdfDatePicker onSdfChange={(event) => setPlaceholderStringValue(event.detail)} value={placeholderStringValue}/>;

      case 'Currency':
        return <MDFCurrencyBox onChange={setPlaceholderStringValue} value={placeholderStringValue} culture={props.culture}/>;

      case 'Text':
        return <MDFTextbox onChange={setPlaceholderStringValue} value={placeholderStringValue} ariaLabel={placeholderNode.getAttribute('label')} />;

      case 'TextArea':
        return <MDFTextarea onChange={setPlaceholderStringValue} value={placeholderStringValue} />;

      case 'Combo': {
        return (
          <MDFDropdownMenu
            type={props.comboType}
            placeholder={FormatHelper.formatMessage('@@selectOption')}
            onChange={popupDropdownOnChange}
            value={placeholderComboValue}
            items={props.currentComboOptions}
            isValid={true}
          />
        );
      }

      case 'ComboAsync': {
        return (
          <MDFDropdownMenu
            type={props.comboType}
            placeholder={FormatHelper.formatMessage('@@selectOption')}
            onChange={popupDropdownOnChange}
            value={placeholderComboValue}
            items={props.currentComboOptions}
            isValid={true}
            paginate={props.comboPaginate}
            onLoadOptions={props.comboOnLoadOptions}
          />
        );
      }
      default:
        return null;
    }
  };

  // Determine whether an unnecessarily tall modal dialog will be necessary due to a datatype that requires a popup, which, unfortunately, is restricted
  // to occupy the area within its parent's dimensions. Also allow the application to apply their own class name to the dialog container.
  const getDialogClassName = () => {
    let dialogClassName = `rtePlaceholderDialog ${props.dialogClassName ? props.dialogClassName : ''}`;
    const dataType = placeholderNode && placeholderNode.getAttribute('datatype');

    if (dataType === 'Calendar') {
      dialogClassName += ' rtePlaceholderDialog-tall';
    }

    return dialogClassName;
  };

  // Build the list of items for the dropdown containing the placeholder fields given the props.placeholders list. We need to group items by group name, and
  // set the id of each item to the index of the item in props.placeholders so that we can easily map a user selection to the corresponding item in props.placeholders
  // since that's where additional data resides. We'll need this additional data (i.e. dataType, name, & oid) when the user clicks on the placeholder in the editor,
  // which pops up a dialog that renders the name & an input component whose type corresponds to dataType, and that links the placeholder's value with other placeholders
  // that have the same oid. If we're using templates, then just build a simple list of the template string names.
  const getPlaceholderOptionsList = () => {
    if (props.useSimpleTemplates) {
      return props.placeholders.map((placeholder: IPlaceholder, index: number) => {
        return { id: index, value: `{${placeholder.name}} - ${placeholder.desc}` };
      });
    }
    else {
      const groupedMenuItems = [];
      const groups = {};

      props.placeholders.forEach((placeholder: IPlaceholder, index: number) => {
        if (!groups[placeholder.groupName]) {
          groups[placeholder.groupName] = [];
        }

        groups[placeholder.groupName].push({
          id: String(index),
          value: placeholder.name
        });
      });

      Object.keys(groups).sort().forEach((name) => {
        groupedMenuItems.push({
          name,
          items: groups[name]
        });
      });

      return groupedMenuItems;
    }
  };

  const placeholderListDropdownOnChange = (_index, item: IMenuItem) => {
    setInsertPlaceholderOption(item);
  };

  const groupType = () => {
    return props.useSimpleTemplates ? 'single' : 'grouped';
  };

  return (
    <div>
      <fieldset>
        <legend>{props.label}</legend>

        <div className="insertPlaceholderContainer">
          <MDFDropdownMenu type={groupType()} placeholder={props.dropdownPlaceholder} onChange={placeholderListDropdownOnChange} value={insertPlaceholderOption}
            items={getPlaceholderOptionsList()} isValid={true} disabled={props.disabled} />
          <MDFButton onClick={insertPlaceholder} disabled={insertPlaceholderOption.value === ''}>{props.insertButtonLabel || FormatHelper.formatMessage('@@Add')}</MDFButton>
        </div>

        <MDFFroalaEditor
          config={config}
          disabled={props.disabled}
          setFroala={setFroala}
          froalaInitialized={froalaInitialized}
          onChange={onChange}
          value={props.value}
        />
      </fieldset>

      <MDFModalDialog2
        closeable={true}
        containerClassName={getDialogClassName()}
        modalType="default"
        enforceFocus={false}
        okButtonText={FormatHelper.formatMessage('@@Ok')}
        onHide={() => setDialogVisible(false)}
        onOkClicked={dialogOkClicked}
        show={isDialogVisible}
        size="sm"
        title={placeholderNode && placeholderNode.getAttribute('label')}
      >
        {getInputComponent()}
      </MDFModalDialog2>
    </div>
  );
};

MDFFroalaEditorPlaceholders.displayName = 'MDFFroalaEditorPlaceholders';

MDFFroalaEditorPlaceholders.defaultProps = {
  comboType: 'single'
};
