import { ActionMenu as Menu } from '@synerg/vdl-react-components';
import React, { useState } from 'react';
import { generateId } from '@adp-wfn/mdf-core';
import classNames from 'classnames';
import { MDFIcon } from './MDFIcon';

export function ActionMenu(props) {
  const [actionId] = useState(generateId('mdf-ActionMenu-Container'));
  const { className, menuLabel, ...otherProps } = props;

  const renderMDFActionMenu = () => {
    const { iconClass, buttonClassName, position, ...menuProps } = otherProps;

    const menuTrigger = <div className="mdf-action-menu__button">{menuLabel} <MDFIcon className={iconClass} /></div>;

    return (
      <Menu icon={menuTrigger} position={position || 'bottom-start'} className={classNames('mdf', className)} buttonClassName={classNames('mdf-action-menu', buttonClassName)} {...menuProps} />
    );
  };

  return (
    <div id={actionId}>
      {menuLabel ? renderMDFActionMenu() : <Menu className={classNames('mdf', className)} {...otherProps} />}
    </div>
  );
}
