import React from 'react';
import { MDFCore } from '@adp-wfn/mdf-core';

import { IMDFFieldState } from './FieldState';
import { ValidationContainerContext } from './MDFContext';

export class MDFValidationContainer extends React.Component<any, any> {
  private model = {}; // We add a model to use when submitting the form
  private fieldMetaData = {};
  private fields = {};
  private buttons = {};
  private buttonClicked = false;
  private immediate = false;

  state: any = {
    isValid: true,
    isSubmitting: false,
    canSumbit: true,
    messages: [],
    isMounted: false
  };

  constructor(props) {
    super(props);

    let isValid = true;
    let canSubmit = true;

    if (props.hasOwnProperty('isValid')) {
      isValid = props.isValid;
    }

    if (props.hasOwnProperty('canSubmit')) {
      canSubmit = props.canSubmit;
    }

    if (props.hasOwnProperty('immediate')) {
      this.immediate = props.immediate;
    }

    // Treat the form as if a button was clicked if the form is initially invalid.
    this.buttonClicked = isValid === false;

    this.state = {
      isSubmitting: false,
      isValid: isValid,
      canSubmit: canSubmit,
      messages: [],
      context: {
        registerField: this.registerField,
        unregisterField: this.unregisterField,
        registerButton: this.registerButton,
        unregisterButton: this.unregisterButton,
        updateFieldMeta: this.updateFieldMeta,
        updateFieldState: this.updateFieldState,
        formIsValid: this.formIsValid,
        handleSubmit: this.handleSubmit,
        handleCancel: this.handleCancel,
        immediate: this.immediate,
        formIsReset: this.formIsReset,
        buttonClicked: this.buttonClicked
      },
      isMounted: false
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  componentWillMount() {
    this.model = {};
    this.fields = {};
    this.buttons = {};
    this.fieldMetaData = {};
  }

  componentWillReceiveProps(newProps: any) {
    if (newProps.hasOwnProperty('immediate')) {
      this.immediate = newProps.immediate;
    }

    this.setState((prevState, props) => {
      const numInvalidFields = this.handleServerErrors(props);

      if ((numInvalidFields > 0) || (newProps.isReset === true)) {
        if (newProps.isReset === true) {
          // reset each field so it gets updated to untouched
          Object.keys(this.fields).forEach((name) => {
            this.fields[name].reset?.();
          });

          // Once reset is complete send an event to the application to set the reset back to false.
          if (this.props.resetComplete) {
            this.props.resetComplete();
          }
        }

        if (prevState.isValid !== false) {
          return {
            isValid: false
          };
        }
      }
    });
  }

  validateComponent = (component): IMDFFieldState => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFValidationContainer.validateComponent(): Validating ${component.props.name} using value ${component.state.value}`);
    }

    const validState = component.validate(component.state.value);

    if (component.state.isValid !== validState.isValid) {
      this.fieldMetaData[component.props.name] = validState;
      component.updateState(validState);
    }

    return validState;
  };

  validateAllComponents = (): any => {
    let allAreValid = true;

    Object.keys(this.fields).forEach((name) => {
      const validState = this.fieldMetaData[name];
      allAreValid = allAreValid && validState.isValid;
    });

    if (this.state.isValid !== allAreValid) {
      this.setState({
        isValid: allAreValid,
        canSubmit: allAreValid
      });
    }

    return { allAreValid, canSubmit: allAreValid };
  };

  registerField = (component) => {
    if (component.props.name) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.registerField(): Field ${component.props.name} registered.`);
      }

      this.fields[component.props.name] = component;
      this.fieldMetaData[component.props.name] = component.state;
      this.model[component.props.name] = component.state.value;

      this.setState((prevState /* , props */) => {
        const newCanSubmit = prevState.canSubmit && component.state.isValid;

        Object.keys(this.buttons).forEach((name) => {
          if (MDFCore.shouldLog()) {
            console.log(`MDFValidationContainer.registerField().setState(): Button ${name} state updating. newCanSubmit =`, newCanSubmit);
          }

          this.buttons[name].updateButtonState(newCanSubmit);
        });

        if (this.state.isMounted) {
          if (prevState.isValid !== component.state.isValid) {
            this.props.onConfigChange?.({ name: component.props.name, model: this.model, fieldMetaData: this.fieldMetaData, isValid: (prevState.canSubmit && component.state.isValid), canSubmit: newCanSubmit });
          }
        }
        return { canSubmit: newCanSubmit };
      });
    }
  };

  unregisterField = (component) => {
    if (component.props.name) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.unregisterField(): Field ${component.props.name} is unregistered.`);
      }

      delete this.fields[component.props.name];

      const prevIsValid = this.state.isValid;
      const { allAreValid, canSubmit } = this.validateAllComponents();

      Object.keys(this.buttons).forEach((buttonName) => {
        if (MDFCore.shouldLog()) {
          console.log(`MDFValidationContainer.unregisterField().forEach(): Button ${buttonName} state updating. canSubmit =`, canSubmit);
        }

        this.buttons[buttonName].updateButtonState(canSubmit);
      });

      this.setState((/* prevState, props */) => (
        { isValid: allAreValid, canSubmit: canSubmit }
      ));

      if (this.state.isMounted) {
        if (prevIsValid !== allAreValid) {
          this.props.onConfigChange?.({ name: component.props.name, model: this.model, fieldMetaData: this.fieldMetaData, isValid: allAreValid, canSubmit: canSubmit });
        }
      }
    }
  };

  registerButton = (component) => {
    if (component.props.name) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.registerButton(): Button ${component.props.name} is registered. canSubmit = `, this.state.canSubmit);
      }

      this.buttons[component.props.name] = component;
      component.updateButtonState(this.state.canSubmit);
    }
  };

  unregisterButton = (component) => {
    if (component.props.name) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.unregisterButton(): Button ${component.props.name} is unregistered`);
      }

      delete this.buttons[component.props.name];
    }
  };

  updateFieldMeta = (component) => {
    this.fieldMetaData[component.props.name] = component.state;
  };

  isFieldStateEqual(name, fieldState) {
    const metaData = this.fieldMetaData[name];
    let isMetaDataEqual = false;

    // Comparing necessary fields on any of the user actions(focus, change, blur) as fieldState contains extra fields like showPopover, otherParams with gives
    // false positive results when compared fieldState with previous metaData which was set in earlier user action.
    if (metaData && fieldState && fieldState.value === metaData.value && fieldState.isValid === metaData.isValid && fieldState.validationMessage === metaData.validationMessage) {
      isMetaDataEqual = true;
    }

    return isMetaDataEqual;
  }

  updateFieldState = (name: string, fieldState: IMDFFieldState, callback) => {
    this.model[name] = fieldState.value;

    if (MDFCore.shouldLog()) {
      console.log('MDFValidationContainer.updateFieldState(): fieldState = ', fieldState);
      console.log(`MDFValidationContainer.updateFieldState(): this.fieldMetaData[${name}] = `, this.fieldMetaData[name]);
    }

    const fieldHasChanged = !this.isFieldStateEqual(name, fieldState);
    this.fieldMetaData[name] = fieldState;
    const { allAreValid, canSubmit } = this.validateAllComponents();

    Object.keys(this.buttons).forEach((buttonName) => {
      this.buttons[buttonName].updateButtonState(canSubmit);
    });

    callback && callback(fieldState);

    if (fieldHasChanged && this.props.onChange) {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.updateFieldState(): Field ${name} has changed.`);
      }

      this.props.onChange({ name: name, model: this.model, fieldMetaData: this.fieldMetaData, isValid: allAreValid, canSubmit: canSubmit });
    }

    this.setState((/* prevState, props */) => (
      { isValid: allAreValid, canSubmit: canSubmit }
    ));
  };

  getFieldState(name) {
    return this.fieldMetaData[name];
  }

  formIsValid = () => (
    { isValid: this.state.isValid, canSubmit: this.state.canSubmit }
  );

  formIsReset = () => this.props.isReset;

  updateModel = () => {
    Object.keys(this.fields).forEach((name) => {
      this.model[name] = this.fields[name].state.value;
    });
  };

  handleSubmit = (buttonName) => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFValidationContainer.handleSubmit(): ${buttonName} Submitting.`);
    }

    this.buttonClicked = true;

    this.setState({
      isSubmitting: true
    });

    const { allAreValid, canSubmit } = this.validateAllComponents();

    this.updateModel();

    this.props.onSubmit?.({ model: this.model, fieldMetaData: this.fieldMetaData, buttonName, isValid: allAreValid, canSubmit }).then((formState) => {
      if (MDFCore.shouldLog()) {
        console.log(`MDFValidationContainer.handleSubmit(): ${buttonName} Submitted. formState =`, formState);
      }

      this.setState({
        isSubmitting: false
      });

      this.props.onSubmitEnd?.(formState);
    });
  };

  handleCancel = (buttonName) => {
    if (MDFCore.shouldLog()) {
      console.log(`MDFValidationContainer.handleCancel(): ${buttonName} Cancelling.`);
    }

    this.buttonClicked = true;

    this.props.onCancel?.({ model: this.model, fieldMetaData: this.fieldMetaData, isValid: this.state.isValid, buttonName });
  };

  handleServerErrors = (props: any) => {
    const results = {};

    props.messages.forEach((item) => {
      if (results.hasOwnProperty(item.field)) {
        results[item.field] = results[item.field] + ',' + item.message;
      }
      else {
        results[item.field] = item.message;
      }
    });

    Object.keys(results).forEach((name) => {
      const component = this.fields[name];

      if (component) {
        component.updateState({ isValid: false, validationMessage: results[name] });
      }
    });

    return Object.keys(results).length;
  };

  // For an App using SidePanel validation (US1635715), the error label ~ <span className="mdf-label-error"> is rendered always at the MDFValidationContainer level (above Header/Body/Footer).
  // The SidePanel needs this label to be rendered in the Body. So mdf-label-error is being hidden if isSidePanelValidation = false and the SidePanel Body will display its own error.
  render() {
    return (
      <ValidationContainerContext.Provider value={this.state.context}>
        {this.props.isSidePanelValidation ?
          (<React.Fragment>{this.props.children}</React.Fragment>) :
          (
            <div>
              {(!this.state.isValid && this.buttonClicked) && <span className="mdf-label-error">{this.props.defaultErrorMessage}</span>}
              {this.props.children}
            </div>
          )
        }
      </ValidationContainerContext.Provider>
    );
  }
}
