import React, { useState } from 'react';
import { DeviceHelper, FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { OverlayTrigger, OverlayTriggerType, Placement, Popover } from '@synerg/vdl-react-components';
import { MDFContentPane } from './MDFContentPane';
import { MDFButton } from './MDFButton';
import { MDFIcon } from './MDFIcon';
import { SdfIcon } from '@waypoint/react-components';
import QuestionCircle from 'adp-react-icons/lib/fa/question-circle';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IMDFLegacyHelpIconProps {
  containerClassName?: string;
  helpMessage: string;
  iconClassName?: string;
  id?: string;
  placement?: Placement;
  tabIndex?: number;
  trigger?: OverlayTriggerType[];
  ariaLabel?: any;
  'aria-label'?: string;
  altText?: string;
}

export const MDFLegacyHelpIcon = (props: IMDFLegacyHelpIconProps) => {
  const { altText, containerClassName, trigger, placement, helpMessage, iconClassName, tabIndex } = props;
  const [id] = useState(props.id || generateId('MDFLegacyHelpIcon'));
  const isMobile = DeviceHelper.isMobileDevice();

  let Icon = iconClassName ? <MDFIcon altText={altText || 'Help'} className={iconClassName} /> : <QuestionCircle />;

  // In a OneUX context, use the OneUX action-help icon.
  if ((window['SynergConfig']?.theme === 'one') && !iconClassName) {
    Icon = <SdfIcon icon="action-help" />;
  }

  const ariaLabel = resolveAriaProperty(MDFLegacyHelpIcon.displayName, 'aria-label', 'ariaLabel', props);
  const [isAriaOwns, updateAriaOwns] = useState(false);

  const updateOnToggle = (show) => {
    show ? updateAriaOwns(true) : updateAriaOwns(false);
  };

  // Aria owns is needed to read the content of the popup and is required to add only when the Button is clicked.
  // hover state is not required for SR testing.
  return (
    <span className="mdf-simple-help-icon-container" {...isAriaOwns && { 'aria-owns': id }}>
      <OverlayTrigger
        trigger={isMobile ? ['click'] : (trigger || ['click', 'hover'])}
        placement={placement}
        onToggle={updateOnToggle}
        flip={true}
        overlay={
          <Popover id={id} className={`mdf-popover-maxWidth ${containerClassName || ''}`}>
            <MDFContentPane html={helpMessage} />
          </Popover>
        }
      >
        <MDFButton variant={'text'} aria-label={(ariaLabel || FormatHelper.formatMessage('@@HelpInformation'))} buttonStyle={'link'} tabIndex={tabIndex || 0}>
          {Icon}
        </MDFButton>
      </OverlayTrigger>
    </span>
  );
};

MDFLegacyHelpIcon.displayName = 'MDFLegacyHelpIcon';
