import React from 'react';
import PropTypes from 'prop-types';
import { ISlideInProps, SlideIn } from '@synerg/vdl-react-components';
import { SnackBar } from './SnackBar';
import { MDFHelpIcon } from './MDFHelpIcon';
import { DeviceHelper, FormatHelper } from '@adp-wfn/mdf-core';
import createChainedFunction from '@synerg/vdl-react-components/lib/util/create-chained-function';
import { MDFVersoView } from './MDFVersoView';

export interface IMDFSlideInProps extends ISlideInProps {
  onClose?: () => void;
  closeable?: boolean;
  snackBar?: React.ReactNode;
  showSnackBar?: boolean;
  title?: string;
  closeButtonLabel?: React.ReactNode;
  helpUrl?: string;
  showHelpIcon?: boolean;
  scrollToTop?: boolean;
  onScrollToTop?: () => void;
  // Accessibility feature - Moves focus to a specific element after the modal dialog closes.
  // Enable feature by passing matching string values to the modal dialog's restoreFocusNodeId property and the next focused element's id property.
  //  It is also necessary to pass in restoreFocus: false.
  restoreFocusNodeId?: string;
}

export class MDFSlideIn extends React.Component<IMDFSlideInProps, any> {
  private slideInBody;

  constructor(props: any) {
    super(props);

    // Introduced the state prop SlideInOpened to know when the slideIn mounts on DOM
    this.state = {
      slideInOpened: false,
      bodyHasSnackBar: false
    };
  }

  static propTypes = Object.assign(
    {},
    SlideIn.propTypes,
    {
      onClose: PropTypes.func,
      closeable: PropTypes.bool,
      modalOpen: PropTypes.bool,
      title: PropTypes.string,
      closeButtonLabel: PropTypes.node,
      scrollToTop: PropTypes.bool,
      onScrollToTop: PropTypes.func
    }
  );

  setScrollBehavior = (enabled: boolean) => {
    if (DeviceHelper.isADPMobileApp()) {
      if (enabled) {
        document.body.classList.add('mdf-mobile-modal-fix');
      }
      else if (!document.body.classList.contains('vdl-modal-open')) {
        // Remove the class when the last slide-in is closed.
        document.body.classList.remove('mdf-mobile-modal-fix');
      }
    }
  };

  handleEntered = () => {
    // Updating the state slideInOpened to true when slideIn loads
    this.slideInBody && this.setState({ slideInOpened: true });
    this.setScrollBehavior(true);
  };

  // Updating the slideInOpened state back to false in order to avoid the issues when opening the slideIn next time if state prop is true.
  slideInExited = () => {
    this.setState({ slideInOpened: false });
    this.setScrollBehavior(false);
  };

  // force the scroll to the top of the slide-in when scrollToTop property is set to true
  // To avoid being called all the time the application will have to set this property to false using onScrollToTop function.
  componentDidUpdate() {
    if (this.props.scrollToTop) {
      const scrollToContent = this.slideInBody && this.slideInBody.parentElement && this.slideInBody.parentElement.parentElement;

      if (scrollToContent && scrollToContent.classList.contains('vdl-slide-in-content')) {
        scrollToContent && (scrollToContent.scrollTop = 0);
        this.props.onScrollToTop && this.props.onScrollToTop();
      }
    }

    // TODO - remove this "if condition" when MDFSlideIn is removed, some applications are mixing old MDFSlideIn with new MDFSidePanel and these components do not know about each others z-index
    // so this condition manually sets it here
    if ((document.body.classList.contains('portal-open') && document.body.classList.contains('vdl-slide-in-open'))) {
      document.querySelector('.vdl-slide-in-backdrop') && document.querySelector('.vdl-slide-in-backdrop').setAttribute('style', 'z-index:1050 !important');
    }

    // Does slideInBody contain a snack bar, if so mdf-snackbar will need to be positioned:fixed instead of positioned:relative via hasBodySnackBar flag
    if (this.slideInBody && this.slideInBody.getElementsByClassName('mdf-snackbar').length !== 0) {
      // check hasBodySnackBar state to prevent infinite loop
      if (!this.state.hasBodySnackBar) {
        this.setState({ hasBodySnackBar: true });
      }
    }
  }

  renderSnackBar = () => {
    const snackBar = this.props.snackBar as JSX.Element;
    const showSnackBar = this.props.showSnackBar === false ? false : snackBar;

    if (!showSnackBar) {
      return null;
    }

    return (<SnackBar>{snackBar}</SnackBar>);
  };

  showHelpIcon = () => {
    if (this.props.showHelpIcon) {
      return (
        <MDFHelpIcon helpUrl={this.props.helpUrl} />
      );
    }
  };

  render() {
    let hasVersoView = false;

    React.Children.map(this.props.children, (child: any) => {
      if (child.type === MDFVersoView) {
        hasVersoView = true;
      }
    });

    // Passing 'closeSlideIn' property to child views
    const childrenWithProps = React.Children.map(this.props.children, (child: any): any => React.cloneElement(child, { closeSlideIn: this.props.onClose }));
    const closeButtonLabel = this.props.closeButtonLabel || (FormatHelper.formatMessage('@@Back'));
    const customProps: any = { ...this.props as ISlideInProps };
    const classname = hasVersoView ? ' mdf-verso-view-mods' : '';

    // The ADP React library seems to lose the container when it's in the ADP Mobile app.
    if (DeviceHelper.isADPMobileApp()) {
      customProps.container = document.body;
    }

    // containerClassName needs 'vdl-modal-open' and the ADP React component treats
    // it as a default value rather than appending a custom containerClassName to it.
    if (customProps.containerClassName) {
      customProps.containerClassName = 'vdl-modal-open ' + customProps.containerClassName;
    }

    // Since slide-in are direct child of the <body/> moved the mdf class as part of the slidein instead of being as part of the Slidein-Dialog
    // This proposed solution would work for versoView and other current slide-in views.
    customProps.className = customProps.className ? `mdf ${customProps.className}` : 'mdf';

    return (
      <SlideIn dialogClassName={classname} restoreFocusNodeId={this.props.restoreFocusNodeId ? this.props.restoreFocusNodeId : null} {...customProps}
        onEntered={createChainedFunction(this.props.onEntered, this.handleEntered)}
        onExited={createChainedFunction(this.props.onExited, this.slideInExited)}
      >
        <SlideIn.Header closeButton={this.props.closeable} closeButtonLabel={closeButtonLabel}>
          <SlideIn.Title>
            {this.props.title}
          </SlideIn.Title>
          {this.showHelpIcon()}
        </SlideIn.Header>
        <SlideIn.Body>
          <div ref={(slideInBody) => this.slideInBody = slideInBody} className={`${this.state.hasBodySnackBar ? 'mdf-slide-in-body-with-snackbar' : ''}`}>
            {this.props.show && childrenWithProps}
          </div>
        </SlideIn.Body>
        {this.renderSnackBar()}
      </SlideIn>
    );
  }
}
