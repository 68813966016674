import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { parse, parseFragment, serialize } from 'parse5';
import { has } from 'lodash';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { Alert } from '@synerg/vdl-react-components';

// Execute the script in the order that was received add set async false to the script tag
function executeScripts(scripts, nodeId) {
  scripts.forEach((script) => {
    const newScript = document.createElement('script');
    const node = document.getElementById(nodeId);

    if (script.src) {
      newScript.src = script.src;
    }

    newScript.async = false;

    if (has(script, 'attrs')) {
      script.attrs.forEach((attribute) => {
        if (attribute.name === 'src' && !newScript.src) {
          newScript.src = attribute.value;
        }
        else if (attribute.name !== 'async') {
          newScript[attribute.name] = attribute.value;
        }
      });
    }

    let scriptContent = script.childNodes && script.childNodes[0] ? script.childNodes[0].value : '';

    // Replace the macro value $$MDF_CONTENT_PANE_NODE_ID with this content pane's id
    // so that it can be used to place content inside this content pane properly.
    scriptContent = scriptContent && scriptContent.replace(/\$\$MDF_CONTENT_PANE_NODE_ID/g, nodeId);
    newScript.appendChild(document.createTextNode(scriptContent));

    // Execute script and append it to the content pane node
    const scriptLocation = node || document.head;
    scriptLocation.appendChild(newScript);
  });
}

function returnBodyTag(result, node) {
  if (result) {
    return result;
  }

  if (node.nodeName === 'body') {
    result = node;
  }
  else if (node.childNodes && node.childNodes.length > 0) {
    result = node.childNodes.reduce(returnBodyTag, result);
  }

  return result;
}

function findBodyTag(node) {
  return node.childNodes.reduce(returnBodyTag, null);
}

function returnScriptTags(result, node) {
  if (node.nodeName === 'script') {
    // Add the script tag to the results
    result.push(node);
  }
  else {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const childScripts = getScripts(node);

    if (childScripts && childScripts.length > 0) {
      result.push(...childScripts);
    }
  }

  return result;
}

function getScripts(node) {
  return node.childNodes ? node.childNodes.reduce(returnScriptTags, []) : [];
}

function removeScripts(node) {
  if (node.nodeName === 'script') {
    // Returning false removes this node from the filtered array.
    return false;
  }

  if (node.childNodes && node.childNodes.length > 0) {
    // Filter the child nodes
    node.childNodes = node.childNodes.filter(removeScripts);
  }

  return node;
}

function renderHTML(html: string) {
  if (!html) {
    // Render nothing if there's nothing to render.
    return null;
  }

  let htmlAST: any;

  if (html.toLowerCase().includes('<html')) {
    htmlAST = parse(html);
    htmlAST = findBodyTag(htmlAST);
  }
  else {
    htmlAST = parseFragment(html);
  }

  if (htmlAST.childNodes.length === 0) {
    return null;
  }

  const scripts = getScripts(htmlAST);
  htmlAST.childNodes = htmlAST.childNodes.filter(removeScripts);

  return {
    body: serialize(htmlAST),
    scripts
  };
}

export interface IMDFContentPaneProps {
  // A CSS class name for overriding styles.
  className?: string;
  // The alert error message content. @@NAPDB_Action_Delete_Error_Message is the standard default message. Developers may pass in a translation message.
  errorMessage?: string;
  // Should the content pane execute scripts when loading.
  executeScripts?: boolean;
  // Does the content of the content pane use the dojo javascript framework.
  hasDojo?: boolean;
  // The html content to display in the content pane.
  html?: string;
  // An id to assign to the content pane.
  id?: string;
  // Height of the legacy app iframe.
  legacyIframeHeight?: number;
  // ID assigned to the legacy app iframe
  legacyIframeId?: string;
  // Width of the legacy app iframe.
  legacyIframeWidth?: number;
  // Insert a revit/layout/ContentPane to hold the Dojo content.
  needsDojoParent?: boolean;
  // The MyADP Barbecue plate id for loading BBQ plates into the component.
  plateId?: string;
  // The MyADP Barbecue plate id for loading BBQ plates into the component.
  onLoadPlate?: (any) => void;
  // Should the content pane show an alert on error.
  showError?: boolean;
  // The url to load into the content pane.
  url?: string;
}

export const MDFContentPane = (props: IMDFContentPaneProps) => {
  const id = props.id || generateId('MDFContentPane');
  const url = props.url;
  const [html, setHtml] = useState(props.html);
  const [scripts, setScripts] = useState([]);
  const [renderTime, setRenderTime] = useState(0);
  const pane = useRef(null);
  const showError = props.showError;
  const [showAlert, setShowAlert] = useState(false);
  const errorMessage = !props.errorMessage ? FormatHelper.formatMessage('@@NAPDB_Action_Delete_Error_Message') : FormatHelper.formatMessage(props.errorMessage);

  const setHtmlAfterDelay = (delayedHtml) => {
    // This needs setTimeout so that the call to setHtml in the props.html effect doesn't override this call to setHtml.
    setTimeout(() => {
      setHtml(delayedHtml);
      setRenderTime(Date.now());
    }, 10);
  };

  // This effect will only run if props.url has a value or has changed.
  useEffect(() => {
    if (url) {
      // Check for whether a legacy app is being loaded in the unified shell; if so, let the shell load it within its legacy app iframe.
      if (window['isADPUnified'] && !window['isLegacyAppShell'] && window['WFNShell']?.isLegacyApp?.(url)) {
        const iframeHtml = `<iframe role="none" src="${window['WFNShell'].buildLegacyAppShellURL(url)}" height="${props.legacyIframeHeight || 300}" width="${props.legacyIframeWidth || 500}"></iframe>`;
        setHtmlAfterDelay(iframeHtml);
      }
      else if (props.hasDojo && props.needsDojoParent) {
        const revitHtml = `<div data-dojo-type="revit/layout/ContentPane" id="${generateId('MDFRevitPane')}" data-dojo-props="href: '${url}'"></div>`;
        setHtmlAfterDelay(revitHtml);
      }
      else {
        void fetch(url, { credentials: 'same-origin' }).then((response) => {
          void response.text().then((htmlText) => {
            if (response.status < 200 || response.status > 299) {
              setShowAlert((currentShowAlert) => !currentShowAlert);
            }

            const pageData = renderHTML(htmlText);
            setHtml(pageData.body);
            setScripts(pageData.scripts);
          });
        });
      }
    }
  }, [url]);

  // This effect will only run if props.html value changes. Similar to getDerivedStateFromProps.
  useEffect(() => {
    if (props.html) {
      // Check for whether a legacy app is being loaded in the unified shell; if so, let the shell load it within its legacy app iframe.
      if (window['isADPUnified'] && !window['isLegacyAppShell'] && props.hasDojo) {
        const iframeId = props.legacyIframeId ? `id="${props.legacyIframeId}"` : '';
        const iframeHtml = `<iframe ${iframeId} role="none" src="${window['WFNShell'].buildLegacyAppShellURLForHTML(props.html)}" height="${props.legacyIframeHeight || 300}" width="${props.legacyIframeWidth || 500}"></iframe>`;
        setHtml(iframeHtml);
      }
      else {
        const pageData = renderHTML(props.html);
        setHtml(pageData.body);

        if (props.executeScripts) {
          setScripts(pageData.scripts);
        }
        else {
          setRenderTime(Date.now());
        }
      }
    }
    else {
      setHtml(props.html);
    }
  }, [props.html]);

  // This effect will only run if props.plateId value changes.
  useEffect(() => {
    if (props.plateId) {
      const shell = window['WFNShell'];

      if (shell && pane.current) {
        const node = document.createElement('div');
        node.id = `${id}_${props.plateId}`;
        pane.current.appendChild(node);

        shell.getBarbecueManager().loadBBQPlate(props.plateId, node)
          .then((plateData: any) => {
            console.log(`MDFContentPane: Plate ${props.plateId} loaded.`);
            props.onLoadPlate?.(plateData);
          });
      }
      else {
        console.warn(`MDFContentPane: Can't load plate ${props.plateId}. Either the WFNShell is not present or there is no current node.`);
      }
    }
  }, [props.plateId]);

  // This effect will only run if scripts has changed and there are scripts to run.
  useEffect(() => {
    if (scripts.length > 0) {
      executeScripts(scripts, id);
      setRenderTime(Date.now());
    }
  }, [scripts]);

  // This effect will act like the componentDidMount lifecycle method.
  useEffect(() => {
    let widgets = [];

    if (renderTime && props.hasDojo && pane.current) {
      // eslint-disable-next-line @adp-wfn/adp-wfn/dojo-blacklist
      if (window['dojo']) {
        try {
          console.error('MDFContentPane: Dojo and Revolution are deprecated technologies and will stop working soon.');
          // eslint-disable-next-line @adp-wfn/adp-wfn/dojo-blacklist
          window['dojo'].parser.parse(pane.current).then((instances: any[]) => {
            widgets = instances;
          });
        }
        catch (e) {
          console.error('MDFContentPane: Error attempting to parse using Dojo.', e);
        }
      }
      else {
        console.error('MDFContentPane: Could not find dojo, so did not attempt to parse.');
      }
    }

    return () => {
      if (props.hasDojo && widgets.length > 0) {
        widgets.forEach((widget) => {
          // Use code similar to the revit/layout/ContentPane's destroyDescendants function
          // in order to try and make sure we get all the Dojo widgets.
          if (widget.destroyRecursive && !widget._destroyed) {
            widget.destroyRecursive();
          }
          else {
            // Use the basic destroy method if the widget doesn't have destroyDescendants
            widget.destroy();
          }
        });
      }
    };
  }, [renderTime]);

  // This effect is used to clean up React event handlers when the content changes.
  useEffect(() => {
    return () => {
      if (!props.hasDojo && pane?.current) {
        const contentRootNodeRef = pane.current.querySelector('[data-mdf-root-id="application_root"]');

        // Remove a mounted React component from the DOM and clean up its event handlers and state.
        // If no component was mounted in the container, calling this function does nothing.
        if (contentRootNodeRef) {
          ReactDOM.unmountComponentAtNode(contentRootNodeRef);
        }
      }
    };
  }, [url, props.html]);

  let className = 'MDFContentPane';

  if (props.className) {
    className += ' ' + props.className;
  }

  return (
    <div>
      <div ref={pane} id={id} className={className} dangerouslySetInnerHTML={{ __html: html }}></div>
      {showError && showAlert ? <Alert type="error" size="sm" closeable={false} className="vdl-alert--contentPane" >{<div>{errorMessage}</div>}</Alert> : ''}
    </div>
  );
};

MDFContentPane.displayName = 'MDFContentPane';
