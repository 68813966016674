import React, { useCallback, useRef, useState } from 'react';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { SdfFloatingPane, SdfIcon, SdfIconButton } from '@waypoint/react-components';
import { AttachmentPoint } from '@waypoint/web-components/types/behaviors/anchorable';
import { MDFContentPane } from './MDFContentPane';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IMDFSimpleHelpIconProps {
  containerClassName?: string;
  helpMessage: string;
  id?: string;
  placement?: AttachmentPoint;
  ariaLabel?: any;
  trigger?: any;
  portalEnabled?: boolean;
  // Aria-label should match the label text associated with the help icon.
  'aria-label'?: string;
  size?: 'auto' | 'xs' | 'sm'| 'md' | 'lg' | 'xl';
}

// Switch to use oneUX tooltip
// Supports only standard icon (action-only)
// We assume there are no actionable items inside the content.
// Supports only trigger with onClick, onHover is not consistent for accessibility
// Accessibility: Reads the entire content once the button is clicked even there are multiple lines
export const MDFSimpleHelpIcon = (props: IMDFSimpleHelpIconProps) => {
  const { placement, helpMessage, trigger, portalEnabled, size } = props;
  const [id] = useState(props.id || generateId('MDFSimpleHelpIcon'));
  const ariaLabel = resolveAriaProperty(MDFSimpleHelpIcon.displayName, 'aria-label', 'ariaLabel', props) ?? '';
  const helpPopover = useRef(null);

  if (trigger) {
    console.warn('Trigger property is deprecated defaults to onClick');
  }

  const afterClose = useCallback((event) => {
    event.stopImmediatePropagation();
  }, []);

  const afterOpen = useCallback((event) => {
    event.stopImmediatePropagation();
    helpPopover.current?.focus();
  }, []);

  const keyUp = useCallback((event) => {
    if (event.code === 'Escape' && helpPopover.current) {
      helpPopover.current.close();
    }
  }, []);

  return (
    <React.Fragment>
      <SdfIconButton
        className="ml-1 text-link"
        id={id}
        label={ariaLabel ? FormatHelper.formatMessage('@@HelpInformationOn') + ' ' + ariaLabel : FormatHelper.formatMessage('@@HelpInformation')}
        shape="circle"
      >
        <SdfIcon icon="action-help"/>
      </SdfIconButton>
      <SdfFloatingPane
        attachment={placement ? placement : 'bottom-start'}
        auto-attach={true}
        onKeyUp={keyUp}
        onSdfAfterClose={afterClose}
        onSdfAfterOpen={afterOpen}
        portal-enabled={portalEnabled}
        ref={helpPopover}
        show-arrow={true}
        style-bundles={['sdf-form-control-layout-partial']}
        tab-behavior="auto-close"
      >
        <div
          className={`p-3 text-base mdf-simple-help max-w-${size || 'xs'}`}
          role="region"
          tabIndex={-1}
        >
          <MDFContentPane html={helpMessage} />
        </div>
      </SdfFloatingPane>
    </React.Fragment>
  );
};

MDFSimpleHelpIcon.displayName = 'MDFSimpleHelpIcon';
