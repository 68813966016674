import React from 'react';
import { createPortal } from 'react-dom';
import { MDFButton as Button } from '../MDFButton';
import { Slotted } from '../Slotted';
import { SnackBar } from '../SnackBar';
import { IMDFWizardStep } from './MDFWizardStep';
import { FormatHelper, MDFCore } from '@adp-wfn/mdf-core';

export interface IMDFWizardFooter {
  // className to override footer styles
  className?: string;
  // Triggers application onNext when next button is clicked.
  onNext: (currentStep) => void;
  // Triggers application onPrevious when previous button is clicked.
  onPrevious: (currentStep) => void;
  // Triggers application onCancel when cancel button is clicked.
  onCancel: () => void;
  // Triggers application onDelegation when Delegate button is clicked.
  onDelegation: () => void;
  // The step to appear in the wizard.
  currentStep?: IMDFWizardStep;
  // Steps array
  steps?: IMDFWizardStep[];
  // To enable/disable the next button.
  isValid?: boolean;
  // Render the previous button.
  isFirstStep?: boolean;
  // Render the submit button
  isLastStep?: boolean;
  // To invoke when save button is clicked
  onSave?: () => void;
  // To invoke function when Submit button is clicked.
  onSubmit: () => void;
  // Return the slideinContainer from the wizardContainer
  slideinContainer: any;
  // Boolean value to show/hide DelegateButton
  hasDelegation: boolean;
  // id property to identify previous button for pendo support
  previousButtonId?: string;
  // id property to identify cancel button for pendo support
  cancelButtonId?: string;
  // id property to identify delegate button for pendo support
  delegateButtonId?: string;
  // id property to identify save button for pendo support
  saveButtonId?: string;
  // id property to identify submit button for pendo support
  submitButtonId?: string;
  // id property to identify next button for pendo support
  nextButtonId?: string;
  // To display the save button text
  saveButtonText?: string;
  // Show/hide Submit button
  hasSubmit?: boolean;
  // show/hide save button
  hasSave?: boolean;
  // child node to add within wizardFooter
  children?: React.ReactNode;
  // add disabled property to disable positive action buttons for partner Access save Changes
  disabled?: boolean;
}

export const MDFWizardFooter = (props: IMDFWizardFooter) => {
  const cancelButtonText = FormatHelper.formatMessage('@@Cancel');
  const saveButtonText = props.saveButtonText || FormatHelper.formatMessage('@@Save');
  const nextButtonText = FormatHelper.formatMessage('@@Next');
  // No translation is available for Delegate removed the commented line.
  const delegateButtonText = 'Delegate';
  const previousButtonText = FormatHelper.formatMessage('@@Previous');
  const submitButtonText = FormatHelper.formatMessage('@@Submit');
  let buttonSlot = null;

  if (props['onDelegationClick'] || props['onSaveHandleSelect'] || props['onSaveClick'] || props['onNextClick'] || props['onCancelClick']) {
    console.error('The click event is deprecated. Check out the docs https://bitbucket.es.ad.adp.com/projects/WFNMDF/repos/mdf-documentation/browse/docs/MDFWizardFooter.tsx.md?at=release/24.0.0 for the appropriate props');
  }

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === Slotted) {
        return buttonSlot = child;
      }
      else {
        return null;
      }
    }
  });

  function snackBarContent() {
    return (
      <div className="mdf-wizard-component--footer w-full">
        <div>
          {!props.isFirstStep &&
            <Button
              id={props.previousButtonId}
              buttonStyle="secondary"
              disabled={props.disabled}
              onClick={() => {
                props.onPrevious && props.onPrevious(props.currentStep);
              }}>
              <i className="mdf-wizard-component-footer-navbutton fa fa-chevron-left" />
              {previousButtonText}
            </Button>
          }
          <Button disabled={props.disabled} id={props.cancelButtonId} buttonStyle="link" onClick={props.onCancel}>{cancelButtonText}</Button>
        </div>
        <div className={'mdf-d-flex'}>
          {buttonSlot}
          {props.hasDelegation &&
            <Button
              id={props.delegateButtonId}
              buttonStyle="link"
              disabled={props.disabled}
              onClick={() => {
                props.onDelegation();
              }}>
              <i className="fa fa-share" />
              {delegateButtonText}
            </Button>
          }
          {props.hasSave &&
            <Button
              id={props.saveButtonId}
              className={'mdf-wizard-component-save'}
              disabled={props.disabled}
              buttonStyle="link"
              onClick={() => {
                props.onSave();
              }}>
              {saveButtonText}
            </Button>
          }
          {props.isLastStep ? props.hasSubmit &&
            <Button
              id={props.submitButtonId}
              buttonStyle="primary"
              disabled={!props.isValid || props.disabled}
              onClick={() => {
                props.onSubmit();
              }}>
              {submitButtonText}
            </Button> :
            <Button
              id={props.nextButtonId}
              buttonStyle="primary"
              disabled={!props.isValid || props.disabled}
              onClick={() => {
                props.onNext && props.onNext(props.currentStep);
              }}>
              {nextButtonText}
              <i className="fa fa-chevron-right" />
            </Button>
          }
        </div>
      </div>
    );
  }

  function getSnackBar() {
    // Do not render previous button for the firstStep
    // Enable the next click only when the step isValid
    return (
      <div className="mdf-wizard-footer-container">
        <SnackBar>{snackBarContent()}</SnackBar>
      </div>
    );
  }

  const getParentNode = () => {
    // DE686478 Fix - When using MDFWizard inside sidePanel/slideIn, Mimicked the functionality of the existing
    // versoView footer/sidePanel footer. To achieve this now moving MDFWizard footer snackbar from sidePanel/slideIn body section to outside.
    return props.slideinContainer.classList.contains('vdl-slide-in-content') ? props.slideinContainer : props.slideinContainer.parentNode;
  };

  if (props.slideinContainer?.tagName === 'SDF-FOCUS-PANE' || props.slideinContainer?.tagName === 'WFN-FOCUS-PANE-CLONE') {
    // Handle the sdf-focus-pane use case: make sure the footer is not hidden and the footer is a direct child of the focus pane.
    // Clear out any old buttons
    const oldFooter = props.slideinContainer.querySelector('[slot=custom-buttons]');

    if (MDFCore.shouldLog()) {
      console.log('MDFWizardFooter(): props.slideinContainer =', props.slideinContainer);
      console.log('MDFWizardFooter(): oldFooter =', oldFooter);
    }

    if (oldFooter) {
      props.slideinContainer.removeChild(oldFooter);
    }

    // Create the new set of footer buttons.
    const customElementSlot = document.createElement('div');
    customElementSlot.slot = 'custom-buttons';
    customElementSlot.classList.add('mdf-wizard-footer-container', 'w-full');
    props.slideinContainer.hideFooter = false;
    props.slideinContainer.append(customElementSlot);
    return createPortal(snackBarContent(), customElementSlot);
  }
  else {
    return props.slideinContainer ? createPortal(getSnackBar(), getParentNode()) : getSnackBar();
  }
};

MDFWizardFooter.displayName = 'MDFWizardFooter';

MDFWizardFooter.defaultProps = {
  hasSave: true,
  hasSubmit: true
};
