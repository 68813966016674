import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export type IMDFCircleIconSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg';

export interface IMDFCircleIconProps {
  // The children to render within this component.
  children: React.ReactNode;
  // A CSS class name for overriding styles.
  className?: string;
  // The size of the MDFCircleIcon. One of xs, sm, md or lg.
  size: IMDFCircleIconSize;
}

export const MDFCircleIcon = (props: IMDFCircleIconProps) => {
  const { className, size } = props;
  const classes = [
    `mdf-circle-icon--${size}`,
    className
  ];

  return (
    <div className={classNames('mdf-circle-icon', classes)}>
      {props.children}
    </div>
  );
};

MDFCircleIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg'])
};

MDFCircleIcon.displayName = 'MDFCircleIcon';
