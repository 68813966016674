import React from 'react';
import { DeviceHelper } from '@adp-wfn/mdf-core';
import PropTypes from 'prop-types';
import { ITimePickerProps, TimePicker } from '@synerg/vdl-react-components';
import ClockIcon from 'adp-react-icons/lib/fa/clock-o';
import { DateHelper } from '../..';

export type ITimePickerSize = 'sm' | 'lg';

export interface IMDFTimePickerProps extends ITimePickerProps {
  // To use mobile date picker if supported
  adaptive?: boolean;
  /* to allow size to be either small or large */
  size?: ITimePickerSize;
}

interface IMDFTimePickerState {
  // date object of the time
  value: Date;
  // formatted Time value in 24-hour format for mobile picker
  formattedValue: string;
}

const REGEX_TIME_FORMAT = /^\s*(\d{0,2})\s*:?\s*(\d{0,2}?)\s*$/i;

export class MDFTimePicker extends React.Component<IMDFTimePickerProps, IMDFTimePickerState> {
  static propTypes = {
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string,
    adaptive: PropTypes.bool,
    value: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.any,
    size: PropTypes.oneOf(['sm', 'lg'])
  };

  useDevicePicker = false;
  timeInput = null;

  constructor(props: IMDFTimePickerProps) {
    super(props);
    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();

    // set default state of the component
    let state: IMDFTimePickerState = {
      value: null,
      formattedValue: null
    };

    if (props.value) {
      const parsedTime = this.parseTime(DateHelper.getDate(props.value));

      state = {
        value: parsedTime.value,
        formattedValue: parsedTime.formattedValue
      };
    }

    this.state = state;
  }

  static defaultProps = {
    size: 'lg'
  };

  static displayName = 'MDFTimePicker';

  // When new props are received, compare the value and render the component if the value is changed.
  componentWillReceiveProps(nextProps: IMDFTimePickerProps) {
    if (nextProps.value !== this.state.value) {
      const parsedTime = this.parseTime(nextProps.value);

      this.setState({
        value: parsedTime.value,
        formattedValue: parsedTime.formattedValue
      });
    }
  }

  // When time picker is changed, update the state
  onChange = () => {
    if (this.useDevicePicker) {
      const parsedTime = this.parseTime(this.timeInput.value);

      this.setState({
        value: parsedTime.value,
        formattedValue: parsedTime.formattedValue
      });

      if (this.props.onChange) {
        this.props.onChange(parsedTime.value, parsedTime.formattedValue);
      }
    }
  };

  // when timeicon is clicked, this method opens mobile picker
  private onTimeIconClick = (event) => {
    event.stopPropagation();

    if (DeviceHelper.isIOS()) {
      this.timeInput.focus();
    }
    else {
      this.timeInput.click();
    }
  };

  private parseTime = (value?: string | Date) => {
    // the value can be either HH:MM (html5) or ISO date string or date.
    let dateTime = null;

    // If the value is HH:MM from mobile picker, parse the string using regex and convert to Date object.
    if (typeof value === 'string' && REGEX_TIME_FORMAT.test(value)) {
      const parsedRegexValues = value.match(REGEX_TIME_FORMAT);

      if (parsedRegexValues.length === 3) {
        dateTime = new Date();
        dateTime.setHours(Number(parsedRegexValues[1]), Number(parsedRegexValues[2]), 0, 0);
      }
    }

    // If dateTime is still null, use DateHelper to get the Date object.
    if (!dateTime) {
      dateTime = DateHelper.getDate(value);
    }

    // calculating 24 hours time format which is required for mobile picker
    const formattedValue = `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;

    return {
      value: dateTime,
      formattedValue: formattedValue
    };
  };

  render() {
    const { className, ...rest } = this.props;

    return (<div className={`vdl-date-time-picker vdl-date-time-picker--${this.props.size} ${this.props.className}`}>
      {this.useDevicePicker &&
        <div>
          <input
            placeholder={this.props.placeholder}
            type="time"
            className={'vdl-date-time-picker__input'}
            value={this.state.formattedValue}
            aria-readonly={this.props.readOnly}
            readOnly={this.props.readOnly}
            ref={(input) => {
              this.timeInput = input;
            }}
            onChange={this.onChange}
          />
          <span className={'vdl-date-time-picker__select'}>
            <span className={'vdl-date-time-picker__select'}>
              <button
                tabIndex={-1}
                className="vdl-date-time-picker__select__picker"
                disabled={this.props.readOnly}
                aria-disabled={this.props.readOnly}
                onClick={this.onTimeIconClick}
              >
                <ClockIcon aria-hidden="true" />
              </button>
            </span>
          </span>
        </div>
      }
      {!this.useDevicePicker &&
        <TimePicker
          className={`vdl-date-time-picker--${this.props.size}`}
          {...rest}
        />
      }
    </div>);
  }
}
