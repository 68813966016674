import React, { useImperativeHandle, useRef } from 'react';
import classNames from 'classnames';
import { MDFIcon } from './MDFIcon';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export const MDFActionMenuOption = React.forwardRef((props: any, ref: any) => {
  const { focused, className, disabled, onClick } = props;
  const inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    linkOnClick(event) {
      return !disabled ? (onClick && onClick(event)) : null;
    },
    isDisabled() {
      return disabled;
    },
    focus: () => {
      inputRef.current.focus();
    }
  }));
  const linkClasses = {
    ['vdl-action-link']: true,
    ['vdl-action-link--focus']: !disabled && focused,
    ['vdl-action-link--focus--disabled']: disabled && focused,
    ['vdl-action-link--disabled']: disabled
  };
  const ariaLabel = resolveAriaProperty(MDFActionMenuOption.displayName, 'aria-label', 'ariaLabel', props);

  return (
    <a ref={inputRef} tabIndex={disabled ? -1 : 0} role="menuitem" aria-label={ariaLabel} className={classNames(linkClasses, className)} onClick={() => props.onClick && props.onClick()} id={props.id}>
      <MDFIcon className={`vdl-action-link__icon ${props.iconClass}`} />
      <span title={props.text}>{props.text}</span>
    </a>
  );
});

MDFActionMenuOption.displayName = 'MDFActionMenuOption';
