import React, { useRef } from 'react';
import classNames from 'classnames';
import { SdfIcon } from '@waypoint/react-components';

export const ListItem = (props) => {
  const { disabled, draggable, id, isAvailable, isSelected, listClass, value, onSelect, onMove, handleKeyboardSelection } = props;
  const clickCount = useRef(0);
  const singleClickTimer = useRef(null);

  const onListClick = () => {
    const bindObject = { [value]: isSelected };
    onSelect(bindObject);
  };

  const onDoubleListClick = (evt?: any) => {
    const bindObject = { [value]: isSelected };
    onSelect(bindObject);

    if (!evt) {
      onMove(bindObject);
    }
    else {
      setTimeout(() => {
        clickCount.current = 0;
        onMove(bindObject);
      }, 300);
    }
  };

  const handleClicks = (e) => {
    clickCount.current++;
    e.target.focus();

    if (clickCount.current === 1) {
      singleClickTimer.current = setTimeout(() => {
        clickCount.current = 0;
        onListClick();
      }, 300);
    }
    else if (clickCount.current === 2) {
      clearTimeout(singleClickTimer.current);
      clickCount.current = 0;
      onDoubleListClick();
    }
  };

  const handleKeyDown = (event) => {
    // Handle item preselection state, arrow key navigation, and selection by keyboard only
    (event.keyCode === 40 || event.keyCode === 38 || event.key === 'Enter' || event.key === ' ') ? handleKeyboardSelection(event, id) : '';
  };

  let listStyle = isAvailable ? 'available' : 'selected';

  if (isSelected) {
    listStyle += ' active';
  }

  const disabledClass = disabled ? 'disabledItem' : '';

  return (
    <li
      id={id}
      unselectable="on"
      className={classNames('vdl-dual-select__list-group-item', disabledClass, listClass, listStyle)}
      data-value={value}
      draggable={draggable}
      tabIndex={-1}
      role="option"
      aria-selected={isSelected ? true : false}
      onDoubleClick={onDoubleListClick}
      onClick={handleClicks}
      onKeyDown={handleKeyDown}
    >
      <div className="flex">
        <div id="checked-icon" className="checked-indicator">
          {isSelected && (
            <SdfIcon aria-hidden="true" icon="action-confirm"></SdfIcon>
          )}
        </div>
        <span>{props.label}</span>
      </div>
    </li>
  );
};
