import React from 'react';
import { DropdownList, IDropdownListProps, IDropdownListState } from '@synerg/vdl-react-components';
import { omit } from 'lodash';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

import { DeviceHelper, FormatHelper } from '@adp-wfn/mdf-core';

export interface IMDFDropDownData {
  groupName?: string;
  className?: string;
  items?: any[];
}

export interface IMDFDropdownListProps extends IDropdownListProps {
  // The element id for this component.
  id?: string;
  // Used to indicate that the value entered into an input field does not conform to the format expected.
  ariaInvalid?: any;
  'aria-invalid'?: any;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
  // Used to indicate that the input field is required.
  required?: boolean;
  // Allow this component to use a native mobile control.
  adaptive?: boolean;
  // An array of items to display in the dropdown.
  options?: IMDFDropDownData[];
}

class MobileDropDownList extends React.Component<IMDFDropdownListProps, IDropdownListState> {
  onChange = (e) => {
    if (this.props.onChange) {
      if (this.props.textField && this.props.valueField) {
        const targetObject = this.props.data && this.props.data[e.target.selectedOptions[0].getAttribute('data-index')];
        this.props.onChange(targetObject);
      }
      else {
        this.props.onChange(e.target.value);
      }
    }
  };

  // return boolean value if the given option exists in the disabledItems.
  isDisabledItem = (option: any, valueField?: string): boolean => {
    if (valueField) {
      return this.props.disabledItems.find((element) => element[valueField] === option[valueField]) !== null;
    }

    return this.props.disabledItems.find((element) => element === option) !== null;
  };

  render() {
    const textField = this.props.textField ? this.props.textField.toString() : null;
    const valueField = this.props.valueField || null;
    let classNames = 'mdf-mobile-dropdownlist__input-container vdl-dropdown-list__input-container';

    if (this.props.disabled) {
      classNames += ' mdf-mobile-dropdownlist--disabled';
    }

    // set the selected value for dropdowns that have text/value field or with just option
    const setSelected = (option) => {
      let selectedValue = false;

      if (this.props.value === null || this.props.value === undefined || option === null || option === undefined) {
        return selectedValue;
      }

      const value = typeof this.props.value === 'string' ? this.props.value : this.props.value[valueField];

      if (valueField && option[valueField]?.toLowerCase() === value.toLowerCase()) {
        selectedValue = true;
      }
      else if (!textField) {
        if (option === this.props.value) {
          selectedValue = true;
        }
      }

      return selectedValue;
    };

    // Set the placeholder value if the default value is either null or empty string (only if it is not present in the list else empty string is selected)
    const setPlaceholderSelected = () => {
      let showPlaceholder = false;

      if (this.props.value === null) {
        showPlaceholder = true;
      }
      else if (this.props.value === '' || this.props.value[valueField] === '') {
        showPlaceholder = !this.props.data?.some((item) => {
          return item === '' || item[valueField] === '';
        });
      }

      return showPlaceholder;
    };

    // constructing the list of options to appear
    const options = this.props.data.map((option, index) => (
      <option selected={setSelected(option)} key={index} data-index={index} disabled={this.props.disabledItems && this.isDisabledItem(option, valueField)} value={valueField ? option[valueField] : option} className="vdl-list__option">
        {textField ? option[textField] : option}
      </option>
    ));

    return (
      <div className={classNames}>
        <select
          onChange={this.onChange}
          disabled={this.props.disabled}>
          <option disabled {...(setPlaceholderSelected() && { selected: true })} value="" key={-1}> {this.props.placeholder || FormatHelper.formatMessage('@@selectOption')}</option>
          {options}
        </select>
      </div>
    );
  }
}

// When data property is passed to this component render the normal DropDownlList
// when options property is passed to this component pass function pointers to groupBy property and render customGroupComponent specific to the new data structure.
export class MDFDropdownList extends React.Component<IMDFDropdownListProps, IDropdownListState> {
  useDevicePicker = false;

  constructor(props) {
    super(props);

    // Show the device picker only when the adaptive prop is true and its in mobile/tablet. Device picker doesn't support filtering and groupby options. Hence if filter and groupby are in the props, then ignore device picker and show actual DropDownList
    this.useDevicePicker = this.props.adaptive
      && !this.props.filter
      && !this.props.groupBy
      && DeviceHelper.isMobileDevice();
  }

  static displayName = 'MDFDropdownList';

  // pass function pointer to groupBy property to support grouping order
  // parse the groupName from the options array.
  groupByOption = (item) => {
    const options = this.props.options;
    let groupName = '';

    options.forEach((data) => {
      data.items && data.items.forEach((value) => {
        if (value === item) {
          return groupName = data.groupName;
        }
      });
    });

    return groupName;
  };

  // pass customGroupComponent to groupComponent property to render the groupName with custom className.
  customGroupComponent = (props) => {
    const options = this.props.options;
    let groupClassName = '';

    options.forEach((option) => {
      if (option.groupName === props.item) {
        return groupClassName = option.className ? option.className : '';
      }
    });

    return (
      <div className={groupClassName}>{props.item}</div>
    );
  };

  render() {
    const { id, required } = this.props;
    const DropDownListComponent: any = this.useDevicePicker ? MobileDropDownList : DropdownList;
    const { options, groupBy, groupComponent, data, ...customProps } = omit(this.props, 'adaptive');
    const ariaInvalid = resolveAriaProperty('MDFDropdownList', 'aria-invalid', 'ariaInvalid', this.props);
    const ariaDescribedby = resolveAriaProperty('MDFDropdownList', 'aria-describedby', 'ariaDescribedby', this.props);
    let results = data ? data : [];

    // concatenate all the items array within the options array.
    options?.forEach((item) => {
      results = results.concat(item.items);
    });

    return (
      <div>
        <DropDownListComponent id={id} aria-invalid={ariaInvalid} aria-required={required} aria-describedby={ariaDescribedby} data={results} groupBy={groupBy ? groupBy : options && this.groupByOption} groupComponent={groupComponent ? groupComponent : options && this.customGroupComponent} {...customProps} />
      </div>
    );
  }
}
