/**
 * Props
 * Title
 * Subtitle
 * Position : one of center or left
 * Weight: semibold or light
 * none
 * cardHeader - className
 *      cardHeaderTitle
 *      cardActionCorner - className
 *              iconClass => onclick
 *              anychildren
 * NEED WORK
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { colorNames, colorStyles, VDLColors } from '../../util/colorStyles';

export interface ISmallCardBottomProps {
  // Assign className for Bottom Card
  className?: string;
  // Background color to be applied for the bottom Section
  bottomBackgroundColor?: VDLColors;
  // 	An Option to add grey Border line to SmallCardBottom
  applyTopBorderLine?: boolean;
  // Allow any children to render for Card Bottom
  children?: any;
}

export class SmallCardBottom extends React.Component<ISmallCardBottomProps, any> {
  static propTypes = {
    className: PropTypes.string,
    bottomBackgroundColor: PropTypes.oneOf(colorNames())
  };

  render() {
    const { className } = this.props;
    const bottomBackgroundColor = this.props.bottomBackgroundColor ? (`${colorStyles(this.props.bottomBackgroundColor)} mdf-cardBackground` || '') : '';
    const applyLineBorder = (this.props.applyTopBorderLine && 'mdf-cardLineBorder') || '';

    return (
      <div className={classNames(`small-card-bottom ${bottomBackgroundColor} ${applyLineBorder}`, className)}>
        {this.props.children}
      </div>
    );
  }
}
