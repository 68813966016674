import React, { useState } from 'react';
import { generateId } from '@adp-wfn/mdf-core';
import { SdfBusyIndicator } from '@waypoint/react-components';
import { BusyIndicator } from '@synerg/vdl-react-components';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export type IMDFBusyIndicatorSize = 'sm' | 'md' | 'lg' | 'xl';

export interface IMDFBusyIndicatorProps {
  ariaLabel?: string;
  size?: IMDFBusyIndicatorSize;
  overlay?: boolean;
  fullScreen?: boolean;
  className?: string;
  'aria-label'?: string;
  style?: Record<string, any>;
}

export const MDFBusyIndicator = (props: IMDFBusyIndicatorProps) => {
  const { size, overlay, fullScreen } = props;
  const [id] = useState(generateId('MDFBusyIndicator'));
  const ariaLabel = resolveAriaProperty(MDFBusyIndicator.displayName, 'aria-label', 'ariaLabel', props);

  const isADPUnified = window['isADPUnified'];

  return (
    <>
      {
        isADPUnified ?
          <SdfBusyIndicator
            size={size}
            overlay={overlay}
            fullscreen={fullScreen}
            labelAria={ariaLabel}
            id={id}
          /> :
          <BusyIndicator
            size={size}
            overlay={overlay}
            fullScreen={fullScreen}
            className={props.className}
            aria-label={ariaLabel}
            style={props.style}
          />
      }
    </>
  );
};

MDFBusyIndicator.displayName = 'MDFBusyIndicator';

MDFBusyIndicator.defaultProps = {
  size: 'md'
};
