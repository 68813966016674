import React from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { Button, ProgressBar } from '@synerg/vdl-react-components';
import { SnackBar } from './SnackBar';

export enum StepType {
  Step = 'step', Finish = 'finish', Complete = 'complete'
}

export interface IWizardProps {
  // The step to appear in the wizard
  currentStep?: number;
  // Custom class for wizard.
  className?: string;
  // To enable/disable the next button.
  isValid?: boolean;
  // To override the default text of next button.
  nextButtonText?: string;
  // To override the default text of back button.
  backButtonText?: string;
  // To override the default text of save button.
  saveButtonText?: string;
  // To override the default text of cancel button.
  cancelButtonText?: string;
  // To invoke function when cancel button is clicked.
  onCancelClick?: () => void;
  // To invoke function when save button is clicked.
  onSaveClick?: () => void;
  // To invoke function when next button is clicked.
  onNextClick?: () => void;
  // To invoke function when back button is clicked.
  onBackClick?: () => void;
}

export interface IWizardStepProps {
  stepType: StepType;
  title?: string;
  children?: any;
  index: number;
}

export interface IWizardHeaderProps {
  isStep: boolean;
  currentStep: number;
  stepsCount: number;
  title?: string;
}

export interface IWizardFooterProps {
  isStep: boolean;
  hasBackButton: boolean;
  nextButtonText: string;
  backButtontext: string;
  saveButtonText: string;
  cancelButtontext: string;
  onNextClick: (e) => void;
  onBackClick: (e) => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
  isValid?: boolean;
}

interface IWizardState {
  // The current step to appear.
  currentStep: number;
  isValid: boolean;
}

// **************************  Wizard Step ****************************/
export class WizardStep extends React.Component<IWizardStepProps, {}> {
  render() {
    return <div className="mdf-wizard-step">{this.props.children}</div>;
  }
}

export class Wizard extends React.Component<React.PropsWithChildren<IWizardProps>, IWizardState> {
  // list of all steps
  wizardSteps: JSX.Element[] = [];
  // total active steps excluding
  regularSteps = 0;

  DEFAULT_NEXT_BUTTON_TEXT: string;
  DEFAULT_BACK_BUTTON_TEXT: string;
  DEFAULT_SAVE_BUTTON_TEXT: string;
  DEFAULT_CANCEL_BUTTON_TEXT: string;

  constructor(props: any) {
    super(props);

    // bind events
    this.onNextClick = this.onNextClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);

    // Getting steps from the children
    React.Children.forEach(props.children, (child: any): any => {
      if (child.type === WizardStep) {
        this.wizardSteps.push(child);

        if (!child.props.stepType || child.props.stepType === StepType.Step) {
          this.regularSteps++;
        }
      }
    });

    this.state = {
      currentStep: props.currentStep || 0,
      isValid: props.isValid || true
    };

    this.DEFAULT_CANCEL_BUTTON_TEXT = FormatHelper.formatMessage('@@Cancel');
    this.DEFAULT_NEXT_BUTTON_TEXT = FormatHelper.formatMessage('@@Next');
    this.DEFAULT_BACK_BUTTON_TEXT = FormatHelper.formatMessage('@@Back');
    this.DEFAULT_SAVE_BUTTON_TEXT = FormatHelper.formatMessage('@@Save');
  }

  componentWillReceiveProps(newProps: IWizardProps) {
    if (this.shouldRender(newProps)) {
      this.setState({
        currentStep: newProps.currentStep,
        isValid: newProps.isValid
      });
    }
  }

  private shouldRender(newProps) {
    return this.state.currentStep !== newProps.currentStep || this.state.isValid !== newProps.isValid;
  }

  onNextClick = (e) => {
    e.preventDefault();

    if (this.props.onNextClick) {
      this.props.onNextClick();
    }
    else {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };

  onBackClick = (e) => {
    e.preventDefault();

    if (this.props.onBackClick) {
      this.props.onBackClick();
    }
    else {
      this.setState({ currentStep: this.state.currentStep - 1 });
    }
  };

  onCancelClick = () => {
    if (this.props.onCancelClick) {
      this.props.onCancelClick();
    }
  };

  onSaveClick = () => {
    if (this.props.onSaveClick) {
      this.props.onSaveClick();
    }
  };

  render() {
    const steps: JSX.Element[] = this.wizardSteps;
    const { currentStep } = this.state;

    const wizardStep: JSX.Element = steps[currentStep];
    const isStep = (!wizardStep.props.stepType || wizardStep.props.stepType === StepType.Step);
    const stepTitle = wizardStep.props.title;
    const isCompleteStep = wizardStep.props.stepType === StepType.Complete;

    return (
      <div className={`mdf-wizard ${this.props.className || ''}`}>
        {!isCompleteStep &&
          <WizardHeader isStep={isStep} currentStep={currentStep} stepsCount={this.regularSteps} title={stepTitle}/>
        }

        {this.props.children[currentStep]}

        {!isCompleteStep && <SnackBar>
          <WizardFooter
            isStep={isStep}
            hasBackButton={currentStep > 0}
            nextButtonText={this.props.nextButtonText || this.DEFAULT_NEXT_BUTTON_TEXT}
            backButtontext={this.props.backButtonText || this.DEFAULT_BACK_BUTTON_TEXT}
            saveButtonText={this.props.saveButtonText || this.DEFAULT_SAVE_BUTTON_TEXT}
            cancelButtontext={this.props.cancelButtonText || this.DEFAULT_CANCEL_BUTTON_TEXT}
            onNextClick={this.onNextClick}
            onBackClick={this.onBackClick}
            onSaveClick={this.onSaveClick}
            onCancelClick={this.onCancelClick}
            isValid={this.props.isValid !== false}
          />
        </SnackBar>
        }
      </div >
    );
  }
}

// ************************** Wizard Header ****************************/
export class WizardHeader extends React.Component<IWizardHeaderProps, any> {
  DEFAULT_REVIEW_HEADER: string;

  constructor(props: IWizardHeaderProps) {
    super(props);

    this.DEFAULT_REVIEW_HEADER = FormatHelper.formatMessage('@@Review');
  }

  render() {
    let headerPrefixText: string;

    if (this.props.isStep) {
      headerPrefixText = FormatHelper.formatMessage('@@WizardStepPrefix', {
        currentStep: this.props.currentStep + 1,
        stepsCount: this.props.stepsCount
      });
    }
    else {
      headerPrefixText = this.DEFAULT_REVIEW_HEADER;
    }

    const progressPercentage = (100 / (this.props.stepsCount + 1)) * (this.props.currentStep + 1);

    return (
      <div className="mdf-wizard-header">
        <span className="mdf-wizard-header-prefix"> {headerPrefixText} </span>
        <span className="mdf-wizard-header-title"> {this.props.title} </span>
        <ProgressBar className="mdf-wizard-header-progress" percentage={progressPercentage} />
      </div>
    );
  }
}

// ************************** Wizard Footer ****************************/
export class WizardFooter extends React.Component<IWizardFooterProps, any> {
  render() {
    return (
      <div className="mdf-wizard-footer">
        {this.props.isStep && this.props.hasBackButton &&
          <Button buttonStyle="link" className="mdf-wizard-footer-navbutton vdl-text-xs-left" onClick={this.props.onBackClick}>
            <i className="mdf-wizard-footer-navbutton fa fa-chevron-left" /> {this.props.backButtontext}
          </Button>
        }

        {this.props.isStep &&
          <Button buttonStyle="link" className="mdf-wizard-footer-navbutton vdl-text-xs-right" onClick={this.props.onNextClick} disabled={!this.props.isValid}>
            {this.props.nextButtonText} <i className="mdf-wizard-footer-navbutton fa fa-chevron-right" />
          </Button>
        }

        {!this.props.isStep &&
          <Button buttonStyle="secondary" className="mdf-wizard-footer-navbutton" onClick={this.props.onCancelClick}>{this.props.cancelButtontext}</Button>
        }

        {!this.props.isStep &&
          <Button buttonStyle="primary" className="mdf-wizard-footer-navbutton" onClick={this.props.onSaveClick} disabled={!this.props.isValid}>{this.props.saveButtonText}</Button>
        }
      </div>
    );
  }
}
