import Select from 'react-responsive-ui/commonjs/Select';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const CountrySelect = ({
  value,
  onChange,
  iconComponent,
  options,
  ...rest
}) => {

  const optionsWithIcons = useMemo(() => {
    return options.map((option) => {
      return {
        ...option,
        // eslint-disable-next-line react/display-name
        icon: () => React.createElement(iconComponent, { country: option.value })
      };
    });
  }, [options, iconComponent]);

  return (
    <Select
      {...rest}
      value={value}
      icon
      saveOnIcons
      onChange={onChange}
      options={optionsWithIcons}
    />
  );
};

CountrySelect.propTypes = {
  /**
   * A two-letter country code.
   * Example: "US", "RU", etc.
   */
  value: PropTypes.string,

  /**
   * Updates the `value`.
   */
  onChange: PropTypes.func.isRequired,

  // `<select/>` options.
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      divider: PropTypes.bool
    })
  ).isRequired
};

export default CountrySelect;
