import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Alert, DropdownButton, IAlertType } from '@synerg/vdl-react-components';
import { SmallCard } from '../SmallCard/SmallCard';
import { SmallCardContent, SmallCardMiddle } from '../SmallCard/SmallCardMiddle';
import { MDFButton } from '../MDFButton';
import PlusIcon from 'adp-react-icons/lib/fa/plus';
import { IRuleRow, RuleRow } from './RuleRow';
import { ILabelValuePair, IRuleColumn } from './RuleCell';
import { FormatHelper } from '@adp-wfn/mdf-core';
import { RuleCardContext } from '../MDFContext';
import { RulesetActions } from './RuleSet';
import { SdfFocusPane } from '@waypoint/react-components';

const MAX_ALLOWED_ROWS = 999;

export interface IRuleCard {
  setJoinOperator: string;
  rowJoinOperator: string;
  showAlert?: boolean;
  alertTitle?: string;
  alertContent?: string;
  alertType?: IAlertType;
  ruleRows?: IRuleRow[];
  cardIndex?: number;
  isValid: boolean;
  validationMessage?: string;
}

export interface IRuleCardProps extends IRuleCard {
  // A CSS classname to override the styles of this component.
  className?: string;

  // The border/box shadow style for the card. Defaults to 'shadow'.
  cardStyle?: 'shadow' | 'flat' | 'dash' | 'blank';

  // The title text for this component.
  title?: string;

  // To enable/disable the component
  disabled?: boolean;

  // Number of max allowed rows per rule card.
  maxAllowedRows?: number;

  // The items to display in the field Names dropdown.
  fieldNames?: IRuleColumn[];

  // The items to display in the join dropdown.
  joinOperatorOptions?: ILabelValuePair<string>[];

  // options to display or hide join operator on the header
  showHeaderJoinOperator?: boolean;

  // to render join operator dropdownlist on the card
  renderJoinOperator: boolean;

  canMoveUp: boolean;

  canMoveDown: boolean,

  // to disable the copy button on the card
  canCopy: boolean;

  // to disable delete button on the card
  isSingleSet: boolean;

  // to be able to delete default row(first row)
  canDeleteDefaultRow?: boolean;

  // Id for each cards
  id?: string;

  // Child nodes to render within the SmallCard
  children: any;

  // Called when component changes any data to update the state of rule set component.
  onChange?: (cardIndex: number, card: IRuleCard, changeType: string) => void;

  // passed down event handler to child component (Async function call to load dynamic data for the value field (MDFSelectBox))
  onFetchValueOptions: (fieldName: any, inputValue: string, prevOptions: any) => Promise<any>;
}

const onAddNewRow = (props) => {
  const ruleRows = [...props.ruleRows];
  ruleRows.push({ field: undefined, operator: undefined, values: [{ value: undefined, label: undefined }], ...(props?.showHeaderJoinOperator === false && { joinOperator: 'and' }) });

  if (props.onChange) {
    props.onChange(props.cardIndex, { ruleRows: ruleRows }, RulesetActions.ADD_NEW_SET_ROW);
  }
};

const handleSetJoinOperatorChange = (e, props) => {
  const setJoinOperator = e?.value;

  if (props.onChange) {
    props.onChange(props.cardIndex, { setJoinOperator: setJoinOperator }, RulesetActions.BETWEEN_SET_JOIN_OPERATOR);
  }
};

const handleRowJoinOperatorChange = (e, props) => {
  const rowJoinOperator = e?.value;

  if (props.onChange) {
    props.onChange(props.cardIndex, { rowJoinOperator: rowJoinOperator }, RulesetActions.BETWEEN_ROW_JOIN_OPERATOR);
  }
};

const onAddRuleSet = (e: any, props) => {
  if (props.onChange) {
    if (e.value === 'ADD_NEW_SET') {
      props.onChange(props.cardIndex,
        {
          ruleRows: null,
          rowJoinOperator: 'and',
          setJoinOperator: 'and',
          isValid: false,
          validationMessage: ''
        },
        RulesetActions.ADD_NEW_SET_FROM_CARD);
    }
    else {
      props.onChange(props.cardIndex,
        {
          data: [
            {
              ruleRows: null,
              rowJoinOperator: 'and',
              setJoinOperator: 'and',
              isValid: false,
              validationMessage: ''
            }
          ],
          setJoinOperator: 'and',
          isValid: false, validationMessage: ''
        },
        RulesetActions.ADD_NEW_GROUP_FROM_CARD);
    }
  }
};

const onMove = (props, changeType) => {
  if (props.onChange) {
    props.onChange(props.cardIndex, null, changeType);
  }
};

const onCopyCard = (props) => {
  const ruleRows = props.ruleRows?.map((row: IRuleRow) => {
    return { ...row };
  });
  const setJoinOperator = props.setJoinOperator;
  const rowJoinOperator = props.rowJoinOperator;
  const showAlert = props.showAlert;
  const alertTitle = props.alertTitle;
  const alertContent = props.alertContent;
  const alertType = props.alertType;

  if (props.onChange) {
    props.onChange(props.cardIndex, { ruleRows: ruleRows, rowJoinOperator: rowJoinOperator, setJoinOperator: setJoinOperator, showAlert: showAlert, alertTitle: alertTitle, alertContent: alertContent, alertType: alertType }, RulesetActions.COPY_SET_FROM_CARD);
  }
};

export const RuleCardHeader = (props) => {
  const [showModal, setShowModal] = useState(false);
  const title = props.title ?? `${FormatHelper.formatMessage('@@RuleSet')} ${(props.cardIndex ?? 1)}`;

  const resetModal = () => {
    setShowModal(false);
  };

  const deleteRuleSet = () => {
    let card = null;

    if (props.isSingleSet) {
      card = {
        ruleRows: [{ field: undefined, operator: undefined, values: [{ value: undefined, label: undefined }] }],
        rowJoinOperator: 'and',
        setJoinOperator: 'and',
        isValid: false,
        validationMessage: ''
      };
    }

    if (props.onChange) {
      props.onChange(props.cardIndex, card, RulesetActions.DELETE_SET_FROM_CARD);
    }

    resetModal();
  };

  const onDeleteRuleSet = () => {
    setShowModal(true);
  };

  return (
    <div className="rule-titleheader">
      <div className="rule-titleheader-row"><h2 className="rule-titleheader-row-medium">{title}</h2></div>
      {props.showHeaderJoinOperator &&
        <div className="rule-titleheader-row">
          <DropdownButton
            buttonStyle="link"
            disabled={props.disabled}
            valueField="value"
            textField="label"
            data={props.joinOperatorOptions}
            onSelect={(e) => {
              handleRowJoinOperatorChange(e, props);
            }}
          >
            {props.joinOperatorOptions?.find((item: ILabelValuePair<string>) => item.value === props.rowJoinOperator)?.label || ''}
          </DropdownButton>
        </div>}
      <div className="rule-titleheader-row-end">
        {props.canMoveUp && <>
          <MDFButton
            buttonStyle="link"
            iconClass="fa fa-arrow-up"
            disabled={props.disabled}
            onClick={() => {
              onMove(props, RulesetActions.MOVE_UP_FROM_CARD);
            }}
          >
            {FormatHelper.formatMessage('@@MoveUp')}
          </MDFButton>
          <div className="rule-titleheader-row-divider" />
        </>
        }
        {props.canMoveDown && <>
          <MDFButton
            buttonStyle="link"
            disabled={props.disabled}
            iconClass="fa fa-arrow-down"
            onClick={() => {
              onMove(props, RulesetActions.MOVE_DOWN_FROM_CARD);
            }}
          >
            {FormatHelper.formatMessage('@@MoveDown')}
          </MDFButton>
          <div className="rule-titleheader-row-divider" />
        </>
        }
        <MDFButton
          buttonStyle="link"
          disabled={!props.canCopy || !!props.disabled}
          iconClass="fa fa-files-o"
          onClick={() => {
            onCopyCard(props);
          }}
        >
          {FormatHelper.formatMessage('@@Copy')}
        </MDFButton>
        <div className="rule-titleheader-row-divider" />
        <MDFButton
          buttonStyle="link"
          disabled={props.disabled}
          iconClass="fa fa-trash-o"
          onClick={onDeleteRuleSet}
        >
          {FormatHelper.formatMessage('@@Delete')}
        </MDFButton>
        {showModal &&
          <SdfFocusPane
            pane-type="floating"
            accept-label={FormatHelper.formatMessage('@@Yes')}
            dismiss-label={FormatHelper.formatMessage('@@No')}
            heading={FormatHelper.formatMessage('@@Delete_Rule_Set_Modal_Header')}
            spacing="normal"
            closeable={true}
            visible={showModal}
            onSdfAccept={deleteRuleSet}
            onSdfDismiss={resetModal}>
            {FormatHelper.formatMessage('@@Delete_Rule_Set_Modal_Body')}
          </SdfFocusPane>
        }
      </div>
    </div>
  );
};

export const RuleCardAlert = (props) => {
  const { showAlert, alertTitle, alertContent, alertType } = props;

  return (
    <React.Fragment>
      {showAlert &&
        <div className="rule-alert">
          <Alert type={alertType} size="sm" closeable={false} title={alertTitle}>{alertContent}</Alert>
        </div>
      }
    </React.Fragment>
  );
};

export const RuleCardRows = (props) => {
  const ruleRows = [...props.ruleRows];
  const maxAllowedRows = props.maxAllowedRows ?? MAX_ALLOWED_ROWS;

  return (
    <React.Fragment>
      {
        ruleRows.map((row, idx) => {
          return (idx < maxAllowedRows &&
            <RuleRow
              key={idx}
              cardIndex={props.cardIndex}
              disabled={props.disabled}
              rowIndex={idx}
              disabledItems={props.disabledItems}
              fieldNames={props.fieldNames}
              aria-label={props['aria-label'] ? props['aria-label'] : FormatHelper.formatMessage('@@Rule')}
              operatorOptions={props.fieldNames?.find((fieldName) => fieldName.value === row.field)?.operatorOptions || []}
              rowJoinOperator={props.joinOperatorOptions?.find((item: ILabelValuePair<string>) => item.value === props.rowJoinOperator)?.valueLabel || ''}
              renderRowJoinOperator={idx !== ruleRows.length - 1}
              renderDelete={props.canDeleteDefaultRow === true ? true : (ruleRows.length !== 1)}
              joinOperatorOptions={props.joinOperatorOptions}
              showJoinOperator={props.showJoinOperator}
              showJoinOperatorComponent={!props.showHeaderJoinOperator}
              {...row}
            />);
        })
      }
    </React.Fragment>
  );
};

export const RuleCardFooter = (props) => {
  const maxAllowedRows = props.maxAllowedRows ?? MAX_ALLOWED_ROWS;

  return (
    props.ruleRows?.length < maxAllowedRows && <div className="rule-bottom">
      <MDFButton
        buttonStyle="secondary"
        disabled={props.disabled}
        iconClass="fa fa-plus"
        onClick={() => {
          onAddNewRow(props);
        }}
      >
        {FormatHelper.formatMessage('@@AddRuleToThisSet')}
      </MDFButton>
    </div>
  );
};

export const RuleCardBottom = (props) => {
  const addSetOptions = [
    {
      label: FormatHelper.formatMessage('@@add_new_set'),
      value: 'ADD_NEW_SET'
    }
  ];

  // Add the ADD_NEW_GROUP option if the user has that capability
  if (props.canAddNewGroup) {
    addSetOptions.push({
      label: FormatHelper.formatMessage('@@add_new_group'),
      value: 'ADD_NEW_GROUP'
    });
  }

  return (
    <div className="rule-operator">
      {props.renderJoinOperator &&
        <DropdownButton
          buttonStyle="link"
          className="rule-operator-button"
          disabled={props.disabled}
          data={props.joinOperatorOptions}
          valueField="value"
          textField="valueLabel"
          onSelect={(e) => {
            handleSetJoinOperatorChange(e, props);
          }}
        >
          {props.joinOperatorOptions?.find((item: ILabelValuePair<string>) => item.value === props.setJoinOperator)?.valueLabel || ''}
        </DropdownButton>
      }
      <div className="rule-seperator">
        <DropdownButton
          buttonStyle="secondary"
          className="rule-seperator-popup"
          data={addSetOptions}
          disabled={props?.disabled || props?.disableAdd}
          valueField="value"
          textField="label"
          onSelect={(e) => {
            onAddRuleSet(e, props);
          }}
        >
          <PlusIcon /> {FormatHelper.formatMessage('@@Add')}
        </DropdownButton>
      </div>
    </div>
  );
};

export const RuleCard = (props) => {
  let header = null;
  let alert = null;
  let rows = null;
  let footer = null;
  let bottom = null;

  // Accessibility feature: Each dropdown field requires a visible label. This is provided via a default, disabled menu item in the drop down menu.
  const ATTRIBUTE_DEFAULT_OPTION = {
    label: FormatHelper.formatMessage('@@Select_Attribute'),
    isDisabled: true
  } as IRuleColumn;

  const OPERATOR_DEFAULT_OPTION = {
    label: FormatHelper.formatMessage('@@Select_Operator'),
    isDisabled: true
  } as ILabelValuePair<string>;

  const VALUE_DEFAULT_OPTION = {
    label: FormatHelper.formatMessage('@@selectValue'),
    isDisabled: true
  } as ILabelValuePair<string>;

  const [fieldNamesList, setFieldNamesList] = useState([...props.fieldNames]);
  const [disabledItemsList, setDisabledItemsList] = useState([]);

  useEffect(() => {
    // Accessibility feature: add default menu item to Select Attribute field
    const hasDefault = props.fieldNames?.find((column) => {
      return column.value === ATTRIBUTE_DEFAULT_OPTION.value;
    });
    const fieldNames = [ATTRIBUTE_DEFAULT_OPTION];

    if (!hasDefault) {
      fieldNames.push(...props.fieldNames);
    }

    // Accessibility feature: add default menu items to Select Operator and Select Value fields
    fieldNames.forEach((item) => {
      if (item.operatorOptions && item.operatorOptions.length >= 1) {
        const hasOperatorDefault = item.operatorOptions?.find((option) => {
          return option.label === OPERATOR_DEFAULT_OPTION.label;
        });

        if (!hasOperatorDefault) {
          item.operatorOptions.splice(0, 0, OPERATOR_DEFAULT_OPTION);
        }
      }

      if (item.valueOptions && item.valueOptions.length >= 1) {
        const hasValueDefault = item.valueOptions?.find((option) => {
          return option.label === VALUE_DEFAULT_OPTION.label;
        });

        if (!hasValueDefault) {
          item.valueOptions.splice(0, 0, VALUE_DEFAULT_OPTION);
          disabledItemsList.push(VALUE_DEFAULT_OPTION);
        }
      }
    });

    setFieldNamesList(fieldNames);
    setDisabledItemsList([...disabledItemsList]);

  }, [props.fieldNames]);

  React.Children.forEach(props.children, (child: any): any => {
    if (child) {
      if (child.type === RuleCardHeader) {
        return header = <RuleCardHeader {...child.props} {...props} />;
      }
      else if (child.type === RuleCardAlert) {
        alert = <RuleCardAlert {...child.props} {...props} />;
      }
      else if (child.type === RuleCardRows) {
        rows = <RuleCardRows {...child.props} {...props} fieldNames={fieldNamesList} disabledItems={disabledItemsList} />;
      }
      else if (child.type === RuleCardFooter) {
        footer = <RuleCardFooter {...child.props} {...props} />;
      }
      else if (child.type === RuleCardBottom) {
        bottom = <RuleCardBottom {...child.props} {...props} />;
      }
      else {
        console.error('The RuleCard children must be at most one of each of these four components: RuleCardHeader, RuleCardRows, RuleCardFooter, or RuleCardBottom');
      }
    }
    else {
      return null;
    }
  });

  return (
    <React.Fragment>
      <RuleCardContext.Provider value={{
        ruleRows: props.ruleRows,
        onChange: props.onChange,
        onFetchValueOptions: props.onFetchValueOptions
      }}>
        <div className="rule-card">
          <SmallCard cardStyle={props.cardStyle} className={classNames(props.className)}>
            <SmallCardMiddle>
              <SmallCardContent>
                <div id={`rule-container-${props.cardIndex}`} className="rule-container">
                  {header}
                  {alert}
                  {rows}
                  {footer}
                </div>
              </SmallCardContent>
            </SmallCardMiddle>
          </SmallCard>
        </div>
        {bottom}
      </RuleCardContext.Provider>
    </React.Fragment>
  );
};

RuleCard.defaultProps = {
  canCopy: true,
  cardIndex: 0,
  fieldNames: [],
  ruleRows: [],
  cardStyle: 'shadow'
};

RuleCard.displayName = 'RuleCard';
