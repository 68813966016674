import React, { useEffect, useRef, useState } from 'react';

export interface IMDFMaskedContentProps {
  // Accessibility message for component
  'aria-label'?: any;
  // Additional data to pass to onFetchValue. i.e. employee id etc
  additionalData?: any;
  // A CSS classname to override the styles of this component.
  className?: string;
  // The initial masked value
  maskedValue: string;
  // Callback for real value
  onFetchValue: (additionalData: any) => Promise<any>;
  // Timeout, defaults to 10 seconds
  timeout?: number;
  // To display as a readOnly without border and background color styles
  readOnly?: boolean;
}

export const MDFMaskedContent = (props: IMDFMaskedContentProps) => {
  const [value, setValue] = useState('');
  const timeoutRef = useRef(null);

  const handleOnClick = () => {
    props.onFetchValue && props.onFetchValue(props.additionalData).then((unmaskedValue) => {
      if (unmaskedValue) {
        setValue(unmaskedValue);
        timeoutRef.current = setTimeout(() => {
          timeoutRef.current = null;
          setValue('');
        }, props.timeout || 10000);
      }
    });
  };

  useEffect(() => {
    if (timeoutRef.current !== null) {
      setValue('');
      clearTimeout(timeoutRef.current);
    }
  }, [props.maskedValue]);

  const className = props.className ? 'mdf-masked-content ' + props.className : 'mdf-masked-content';
  const icon = value ? 'fa fa-spin fa-clock-o' : 'fa fa-eye';

  return (
    <div className={props.readOnly ? 'mdf-masked-content-readonly' : className}>
      <span>{value ? value : props.maskedValue}</span>
      <a onClick={handleOnClick} tabIndex={0} href="javascript:void(0);" {...(props['aria-label'] && { 'aria-label': props['aria-label'] })}><i className={icon} /></a>
    </div>
  );
};

MDFMaskedContent.displayName = 'MDFMaskedContent';
