import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Overlay, Popover } from '@synerg/vdl-react-components';
import IconThinClose from 'adp-react-icons/lib/adp/close-thin';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';

export type IMDFDialogOrientation = 'right' | 'left';

export interface IMDFPopupDialogProps {
  // A string to apply custom class to MDFPopupDialog content
  className?: string;

  // When `true` The modal will show itself
  isOpen: boolean;

  // Callback fired when the MDFPopupDialog is closed
  onClose?: () => void;

  // Whether the MDFPopupDialog should open from left of screen to right`right` or right of screen to left`left`
  orientation?: IMDFDialogOrientation;

  // A title to display in the MDFPopupDialog header
  title?: string;

  // The id of the element that opened the MDFPopupDialog so the dialog can position itself
  targetId?: string;

  // A label to pass the MDFPopupDialog close button
  closeButtonAriaLabel?: string;

  // A string to apply custom class to MDFPopupDialog container.
  containerClassName?: string;

  // A reference to the target element that opened the MDFPopupDialog so the dialog can position itself
  targetRef?: any;

  // The component's children.
  children: React.ReactNode;

  // A caption for screen readers to read while entering the filter
  caption?: string;

  // To disable close on click outside the waypoint floating pane.
  disableCloseOnClickOutside?: boolean;
}

export const MDFPopupDialog = ({
  onClose,
  className,
  containerClassName,
  title,
  targetId,
  targetRef,
  children,
  isOpen,
  orientation,
  closeButtonAriaLabel,
  caption
}: IMDFPopupDialogProps) => {
  const rootId = useRef(generateId('mdf-popup-dialog')).current;
  const [showBackdrop, setShowBackdrop] = useState(isOpen || false);
  const dialogClassName = orientation === 'left' ? 'mdf-popup-dialog-left' : 'mdf-popup-dialog';

  const handleCloseClick = () => {
    onClose && onClose();

    if (targetId) {
      document.getElementById(targetId).focus();
    }
    else if (targetRef) {
      (ReactDOM.findDOMNode(targetRef) as HTMLElement).focus();
    }
  };

  const renderHeader = () => {
    return (
      <div className={'popup-dialog-header'}>
        {title && <h4 className="popup-dialog-title">{title}</h4>}
        <div className="popup-dialog-close">
          <button
            type="button"
            className={classNames('mdf-popup-dialog-close-button', className)}
            aria-label={closeButtonAriaLabel || FormatHelper.formatMessage('@@Close')}
            onClick={handleCloseClick}
          >
            <IconThinClose />
          </button>
        </div>
      </div>
    );
  };

  const renderDialog = () => {
    return (
      <div className={classNames(dialogClassName, className)}>
        <div className="popup-dialog-content">
          {children}
        </div>
      </div >
    );
  };

  return (
    <>
      {showBackdrop ? (
        <div className={'vdl-modal-backdrop in'} />
      ) : null}
      <Overlay
        flip={true}
        popperConfig={{
          modifiers: [{
            name: 'hide',
            enabled: false
          }]
        }}
        updateOnMount={true}
        rootClose={false}
        placement={orientation === 'right' ? 'bottom-start' : 'bottom-end'}
        onHide={handleCloseClick}
        onEntering={() => {
          setShowBackdrop(true);
        }}
        onExited={() => {
          setShowBackdrop(false);
        }}
        target={() => {
          return (targetId ? document.getElementById(targetId) : targetRef && ReactDOM.findDOMNode(targetRef)) as HTMLElement;
        }}
        show={isOpen}>
        <Popover caption={caption} id={rootId} header={renderHeader()} className={classNames('mdf-popup-dialog-container', containerClassName || '')}>{renderDialog()}</Popover>
      </Overlay>
    </>
  );
};

MDFPopupDialog.displayName = 'MDFPopupDialog';

MDFPopupDialog.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  targetId: PropTypes.string,
  containerClassName: PropTypes.string
};

MDFPopupDialog.defaultProps = {
  orientation: 'right',
  isOpen: false
};
