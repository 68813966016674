import React, { useEffect, useState } from 'react';
import { IStatusIcon, StatusIcon } from '../StatusIcon';
import classNames from 'classnames';

export interface IMDFWizardStep {
  // add or override any styles
  className?: string;
  // set one of the statusType to show status of the step
  status?: IStatusIcon['statusType'];
  // Allow any children
  children?: any;
  // step label or name
  title?: string;
  // to indicate the flow reached the lastStep to enable submit button
  isLastStep?: boolean;
  // To render MDFWizardStep when wizard has subSteps
  isChild?: boolean;
  // View to be rendered when the step is clicked
  viewName?: string;
  // Unique key to identify each step to support common views. If not provided then fallback to viewName
  viewKey?: string;
  // Triggers stepClick to change the content within the wizardStep
  stepClick?: (e) => void;
  // Pass Sub wizardSteps
  subSteps?: IMDFWizardStep[];
  // First view to load when the page is loaded.
  currentView?: string;
  // To disable step
  disabled?: boolean;
  // Trigger application stepChange when a step is clicked
  onStepChange?: (clickedItem) => void;
  // expand children
  expanded?: boolean;
  // triggers on parent step click
  onExpand?: (clickedItem) => void;
}

export const getStepKey = (step: IMDFWizardStep) => (step?.viewKey || step?.viewName);

export const MDFWizardStep = (props: IMDFWizardStep) => {
  const [isOpen, setIsOpen] = useState(props.expanded);
  const currentView = props.currentView;
  const DEFAULT = 'default';
  let isExpanded = false;
  let hasSubSteps = false;
  let wizardSubSteps = [];

  useEffect(() => {
    setIsOpen(props.expanded);
  }, [props.expanded]);

  if (props.subSteps && props.subSteps.length > 0) {
    wizardSubSteps = props.subSteps;
    hasSubSteps = true;
    isExpanded = props.subSteps.some((step) => currentView === getStepKey(step));
  }

  const toggleSteps = (item) => {
    props.onExpand && props.onExpand(item);

    if (item.subSteps) {
      const itemFound = item.subSteps.some((step) => currentView === getStepKey(step));

      !itemFound && setIsOpen(!isOpen);
    }
  };

  const onStepClick = (e, clickedItem) => {
    e.stopPropagation();
    props.stepClick(clickedItem);
    props.onStepChange && props.onStepChange(clickedItem);
  };

  const stepElement = (item) => {
    const stepKey = getStepKey(item);
    const activeStatus = classNames({
      'active': currentView === stepKey && !item.disabled,
      'disabled-status': item.disabled,
      'current-status': !item.disabled && currentView === stepKey && DEFAULT === item.status
    });

    const onStepKeyDown = (e, clickedItem) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onStepClick(e, clickedItem);
      }
    };

    const onParentKeyDown = (e, clickedItem) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggleSteps(clickedItem);
      }
    };

    if (hasSubSteps) {
      return (<div className={`mdf-wizard-component--parent ${activeStatus}`} role="button" tabIndex={!item.disabled ? 0 : -1} onKeyDown={(e) => !item.disabled && onParentKeyDown(e, item)} onClick={() => !item.disabled && toggleSteps(item)} >
        <div className={`mdf-wizard-component--step ${activeStatus}`}>
          <StatusIcon size="xxs" statusType={item.status} className={`mdf-wizard-status ${activeStatus}`} />
          <span title={item.title} className="mdf-wizard-title">{item.title}</span>
        </div>
        <i className={(isOpen || isExpanded) ? 'fa fa-caret-down' : 'fa fa-caret-right'}></i>
      </div>);
    }
    else {
      return (<div className={`mdf-wizard-component--step ${activeStatus}`} role="button" tabIndex={!item.disabled ? 0 : -1} onKeyDown={(e) => !item.disabled && onStepKeyDown(e, item)} onClick={(e) => !item.disabled && onStepClick(e, item)}>
        <StatusIcon
          size="xxs"
          statusType={item.status}
          className={`mdf-wizard-status ${!item.disabled && currentView === stepKey && DEFAULT === item.status && 'current-status ' || ''}`} />
        <span title={item.title} className="mdf-wizard-title">{item.title}</span>
      </div>);
    }
  };

  return (
    <div>
      {stepElement(props)}
      {hasSubSteps &&
        <div
          className={'mdf-wizard-component--step--child ' + (isOpen || isExpanded ? 'mdf-wizard-component--step--expand' : 'mdf-wizard-component--step--collapse')}
        >
          {wizardSubSteps.map((item, index) => (
            <MDFWizardStep
              key={index}
              {...item}
              stepClick={props.stepClick}
              currentView={currentView}
              isChild={true}
              isLastStep={(wizardSubSteps.length - 1) === index}
              onStepChange={props.onStepChange}>
            </MDFWizardStep>
          ))}
        </div>
      }
      {(!props.isLastStep && (!hasSubSteps || (hasSubSteps && !isOpen))) &&
        <div className="mdf-wizard-component--step--line" />
      }
    </div>
  );
};

MDFWizardStep.displayName = 'MDFWizardStep';

MDFWizardStep.defaultProps = {
  expanded: true
};
