export type VDLColors = 'black'| 'white' | 'accent-0-lightest' | 'accent-0-lighter' | 'accent-0-light' | 'accent-0' | 'accent-0-dark' | 'accent-0-darker' | 'accent-1-lightest' | 'accent-1-lighter' | 'accent-1-light' | 'accent-1' | 'accent-1-dark' | 'accent-1-darker' | 'accent-2-light' | 'accent-2' | 'accent-2-dark' | 'accent-2-darker' | 'accent-3-light' | 'accent-3' | 'accent-3-dark' | 'accent-3-darker' | 'accent-4-light' | 'accent-4' | 'accent-4-dark' | 'accent-4-darker' | 'accent-5-lightest' | 'accent-5-lighter' | 'accent-5-light' | 'accent-5' | 'accent-5-dark' | 'accent-5-darker' | 'accent-6-lightest' | 'accent-6-lighter' | 'accent-6-light' | 'accent-6' | 'accent-6-dark' | 'accent-7-light' | 'accent-7' | 'accent-7-dark' | 'accent-7-darker' | 'neutral-lightest' | 'neutral-lighter' | 'neutral-light' | 'neutral-mid' | 'neutral' | 'neutral-dark' | 'neutral-darker';

const colorMap: Record<VDLColors, string> = {
  'black': 'black',
  'white': 'white',
  'accent-0-lightest': 'alert-info-50',
  'accent-0-lighter': 'alert-info-100',
  'accent-0-light': 'alert-info-300',
  'accent-0': 'alert-info-400',
  'accent-0-dark': 'alert-info-600',
  'accent-0-darker': 'alert-info-800',
  'accent-1-lightest': 'priority-minor-50',
  'accent-1-lighter': 'priority-minor-100',
  'accent-1-light': 'priority-minor-200',
  'accent-1': 'priority-minor-300',
  'accent-1-dark': 'priority-minor-600',
  'accent-1-darker': 'priority-minor-700',
  'accent-2-light': 'brand-secondary-200',
  'accent-2': 'brand-secondary-400',
  'accent-2-dark': 'brand-secondary-600',
  'accent-2-darker': 'brand-secondary-800',
  'accent-3-light': 'brand-primary-300',
  'accent-3': 'brand-primary-500',
  'accent-3-dark': 'brand-primary-700',
  'accent-3-darker': 'brand-primary-800',
  'accent-4-light': 'status-new-300',
  'accent-4': 'status-new-400',
  'accent-4-dark': 'status-new-700',
  'accent-4-darker': 'status-new-800',
  'accent-5-lightest': 'status-changed-50',
  'accent-5-lighter': 'status-changed-100',
  'accent-5-light': 'status-changed-200',
  'accent-5': 'status-changed-300',
  'accent-5-dark': 'status-changed-400',
  'accent-5-darker': 'status-changed-700',
  'accent-6-lightest': 'status-failed-50',
  'accent-6-lighter': 'status-failed-100',
  'accent-6-light': 'status-failed-200',
  'accent-6': 'status-failed-500',
  'accent-6-dark': 'status-failed-700',
  'accent-7-light': 'brand-quaternary-200',
  'accent-7': 'brand-quaternary-300',
  'accent-7-dark': 'brand-quaternary-600',
  'accent-7-darker': 'brand-quaternary-700',
  'neutral-lightest': 'gray-50',
  'neutral-lighter': 'gray-100',
  'neutral-light': 'gray-200',
  'neutral-mid': 'gray-300',
  'neutral': 'gray-400',
  'neutral-dark': 'gray-600',
  'neutral-darker': 'gray-700'
};

const darkColors = [
  'black',
  'accent-0-dark',
  'accent-0-darker',
  'accent-1-dark',
  'accent-1-darker',
  'accent-2-dark',
  'accent-2-darker',
  'accent-3-dark',
  'accent-3-darker',
  'accent-4-dark',
  'accent-4-darker',
  'accent-5-darker',
  'accent-6',
  'accent-6-dark',
  'accent-7-dark',
  'accent-7-darker',
  'neutral-dark',
  'neutral-darker'
];

const colorNamesSource = [
  'black',
  'white',
  'accent-0-lightest',
  'accent-0-lighter',
  'accent-0-light',
  'accent-0',
  'accent-0-dark',
  'accent-0-darker',
  'accent-1-lightest',
  'accent-1-lighter',
  'accent-1-light',
  'accent-1',
  'accent-1-dark',
  'accent-1-darker',
  'accent-2-light',
  'accent-2',
  'accent-2-dark',
  'accent-2-darker',
  'accent-3-light',
  'accent-3',
  'accent-3-dark',
  'accent-3-darker',
  'accent-4-light',
  'accent-4',
  'accent-4-dark',
  'accent-4-darker',
  'accent-5-lightest',
  'accent-5-lighter',
  'accent-5-light',
  'accent-5',
  'accent-5-dark',
  'accent-5-darker',
  'accent-6-lightest',
  'accent-6-lighter',
  'accent-6-light',
  'accent-6',
  'accent-6-dark',
  'accent-7-light',
  'accent-7',
  'accent-7-dark',
  'accent-7-darker',
  'neutral-lightest',
  'neutral-lighter',
  'neutral-light',
  'neutral-mid',
  'neutral',
  'neutral-dark',
  'neutral-darker'
];

export const colorStyles = (color) => {
  return `simple-${color}-bg simple-${darkColors.includes(color) ? 'white' : 'black'}`;
};

export const waypointColorStyles = (color) => {
  return `bg-${colorMap[color]} simple-${darkColors.includes(color) ? 'white' : 'black'}`;
};

export const colorValue = (color) => {
  // The CSS variables use the same names as the old SCSS variables.
  return `var(--${color})`;
};

export const colorNames = () => {
  // Return a copy of the array so that applications don't inadvertently change the source array.
  return colorNamesSource.map((item) => item);
};
