import React from 'react';
import PropTypes from 'prop-types';
import { FilterColumn } from './FilterColumn';
import { IMDFDialogOrientation, MDFPopupDialog } from '../MDFPopupDialog';
import { MDFPopupDialog2 } from '../MDFPopupDialog2';
import classNames from 'classnames';
import { MDFIcon } from '../MDFIcon';
import { isEqual } from 'lodash';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { SdfButton } from '@waypoint/react-components';

export interface IMDFFilterTags {
  id: string;
  value: string;
}

export type IMDFFilterTagPosition = 'vertical' | 'horizontal';

export interface IMDFFilterProps {
  // A caption for screen readers to read while entering the filter
  caption?: string;
  // To pass children of Type FilterColumn
  children?: any;
  // To apply application classname
  className?: string;
  // Disable the apply button
  disabledApplyButton?: boolean;
  // allow user to provide label
  filterLabel?: string;
  // Adjusts the filter tag position relative to the filter label
  filterTagPosition?: IMDFFilterTagPosition;
  // accept filterTags props when an onApply is selected.
  filterTags?: IMDFFilterTags;
  // The id for this filter component
  id?: string;
  // Dispatch an event so the application sends back the items to the filterTags array
  onApply?: () => void;
  // Dispatch an event so the application send back the data to clear all the selectedItems filter Component
  onClearAll?: () => void;
  // Typically similar to the onClearAll event.
  onClose?: () => void;
  // trigger once a tag is removed from the filterTags object and return the item that got removed with the entire FilterTag Array
  onRemoveFilter?: (itemToRemove, filterTags) => void;
  // Opens the Filter to the right or left. Defaults to 'right' which will open Filter to align on left edge and expand out to the right.
  orientation?: IMDFDialogOrientation;
  // When set, removes the internal padding from the filter button row.
  tight?: boolean;
  // Use a Waypoint-based popup dialog.
  useWaypoint?: boolean;
}

export class MDFFilter extends React.Component<IMDFFilterProps, any> {
  static propTypes = {
    caption: PropTypes.string,
    className: PropTypes.string,
    disabledApplyButton: PropTypes.bool,
    filterLabel: PropTypes.string,
    filterTagPosition: PropTypes.string,
    filterTags: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.string
    }),
    id: PropTypes.string,
    onApply: PropTypes.func,
    onClearAll: PropTypes.func,
    onClose: PropTypes.func,
    onRemoveFilter: PropTypes.func,
    orientation: PropTypes.string,
    tight: PropTypes.bool,
    useWaypoint: PropTypes.bool
  };

  static defaultProps = {
    orientation: 'right',
    filterTagPosition: 'vertical'
  };

  private id: string;

  constructor(props) {
    super(props);

    this.id = props.id || generateId('MDFFilter');

    this.state = {
      isOpen: false,
      filterTags: props.filterTags
    };
  }

  openFilter = () => {
    this.setState({
      isOpen: true
    });
  };

  // update the filterTags state
  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.filterTags, state.filterTags)) {
      return {
        filterTags: props.filterTags
      };
    }

    return null;
  }

  onHidePopupDialog = () => {
    this.setState({ isOpen: false });
    this.props.onClose && this.props.onClose();
  };

  onClearAllClick = () => {
    this.props.onClearAll && this.props.onClearAll();
  };

  onApplyClick = () => {
    this.props.onApply && this.props.onApply();
    this.setState({ isOpen: false });
  };

  renderButtons = () => (
    <div className={'mdf-controlButtons'}>
      <SdfButton emphasis="secondary" onClick={() => this.onClearAllClick()}>
        <span>{FormatHelper.formatMessage('@@label_clear_all')}</span>
      </SdfButton>
      <SdfButton emphasis="primary" onClick={() => this.onApplyClick()} disabled={this.props.disabledApplyButton || undefined}>
        <span>{FormatHelper.formatMessage('@@Apply')}</span>
      </SdfButton>
    </div>
  );

  removeFilterItem = (tagItem) => {
    const newState = this.state.filterTags.filter((item) => item.value !== tagItem.value && item.id !== tagItem.id);

    this.props.onRemoveFilter && this.props.onRemoveFilter(tagItem, newState);

    this.setState({
      filterTags: newState
    });
  };

  handleKeyDown = (e: React.KeyboardEvent, tag) => {
    if (e.key === 'Enter' || e.key === 'Delete' || e.key === 'Backspace') {
      this.removeFilterItem(tag);
    }
  };

  // called for every tag that is rendered
  renderFilterTag = (tag) => (
    <div tabIndex={0} role="button" aria-label={`${FormatHelper.formatMessage('@@RemoveFilter')} ${tag.value}`} className="mdf-filter-tag" onClick={() => this.removeFilterItem(tag)} onKeyDown={(e) => this.handleKeyDown(e, tag)}>
      <span>{tag.value}</span>
      <MDFIcon className="icon-close-thin mdf-tagIcon" />
    </div>
  );

  render() {
    const columns: JSX.Element[] = [];
    const { className, children, filterTagPosition, orientation, tight } = this.props;
    const PopupDialog = this.props.useWaypoint ? MDFPopupDialog2 : MDFPopupDialog;

    // do not allow more than 3 filterColumn as children
    React.Children.forEach(children, (child: any, i): any => {
      if (i >= 3) {
        throw new Error('The number of columns must be 3 or less');
      }

      // when the child is null (render set to false) just ignore and return null
      if (child) {
        if (child.type === FilterColumn) {
          columns.push(child);
        }
        else {
          console.error('All children of the MDFFilter must be a FilterColumn');
          return null;
        }
      }
      else {
        return null;
      }
    });

    return (
      <div className={`mdf-filter-wrapper ${tight ? 'mdf-filter-wrapper--tight ' : ''}${filterTagPosition}--${orientation}`}>
        <SdfButton className={'filterButton'} emphasis="secondary" id={this.id} icon="action-filter" onClick={this.openFilter}>
          <span className="font-size-base">
            {this.props.filterLabel || FormatHelper.formatMessage('@@Filters')}
          </span>
        </SdfButton>
        {
          this.state.filterTags?.length > 0 &&
            <div className="mdf-filter-wrapper mdf-filtertags">
              {this.state.filterTags.map(this.renderFilterTag)}
            </div>
        }
        <React.Fragment>
          {
            <PopupDialog isOpen={this.state.isOpen}
              targetId={this.id}
              onClose={this.onHidePopupDialog}
              orientation={this.props.orientation}
              caption={this.props.caption}
              disableCloseOnClickOutside={true}
            >
              <div className={classNames('mdf-filter-wrapper mdf-filter', className)}>
                <div className={classNames('mdf-filterColumns', className)}>
                  {columns}
                </div>
                {this.renderButtons()}
              </div>
            </PopupDialog>
          }
        </React.Fragment>
      </div >
    );
  }
}
