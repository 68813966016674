import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IStepNavigation } from './StepNavigation';
import { MDFIcon } from '../MDFIcon';
import { colorValue } from '../../util/colorStyles';

export interface INavStep extends Omit<IStepNavigation, 'sequential'> {
  // To set the navigation step state to active. StepNavigation component sets this property to 'true' in case of non-sequencial navigation.
  active?: boolean;

  // To set the navigation step state to complete.
  complete?: boolean;

  // Id for each step.
  id?: string;

  // The class name of the icon to be displayed in the step.
  icon?: string;

  // Specifies lastChild or not. StepNavigation component sets this property.
  lastChild?: boolean;

  // Specifies step position in container. StepNavigation component sets this property.
  position?: number;
}

type NavStepDirection = 'forwards' | 'backwards';

export const NavStep = (props: INavStep) => {
  const { active, icon, id, lastChild, position, showLines, stepNavType } = props;
  const [direction, setDirection] = useState<NavStepDirection>((props.complete === true) ? 'forwards' : 'backwards');
  const [complete, setComplete] = useState(props.complete);

  useEffect(() => {
    if (props.complete !== complete) {
      setDirection(props.complete === true ? 'forwards' : 'backwards');
      setComplete(props.complete);
    }
  }, [props.complete]);

  const getStylesForElementAccent = (activeAccent, incompleteAccent) => {
    const activeAccentColorValue = colorValue(activeAccent);
    const incompleteAccentColorValue = colorValue(incompleteAccent);

    const activeIndicator = {
      color: activeAccentColorValue,
      borderColor: activeAccentColorValue
    };

    const inactiveIndicator = {
      color: incompleteAccentColorValue,
      borderColor: incompleteAccentColorValue
    };

    const activeLine = {
      borderColor: activeAccentColorValue
    };

    const inactiveLine = {
      borderColor: incompleteAccentColorValue
    };

    return { activeIndicator, inactiveIndicator, activeLine, inactiveLine };
  };

  const { activeIndicator, inactiveIndicator, activeLine, inactiveLine } = useMemo(() => {
    return getStylesForElementAccent(props.activeAccent, props.incompleteAccent);
  }, [props.activeAccent, props.incompleteAccent]);

  const stepClasses = {
    'step-navigation__step': true,
    'active': active,
    'complete': complete,
    [direction]: showLines
  };

  return (
    <div id={id} key={position} className="h-full relative flex flex-row flex-no-wrap items-stretch step-navigation">
      <div className={classNames(stepClasses)}>
        <div style={active ? activeIndicator : inactiveIndicator} className="border-solid text-center step-navigation__step-indicator mdf-circle-icon--xs">
          { stepNavType === 'numerical' && !icon && !complete ? <span className="step-navigation__step-indicator-number">
            { position }
          </span> : null }
          { complete ? <MDFIcon className="fa fa-check"/> : null}
          { !complete && icon ? <MDFIcon className={'block text-center step-icon ' + icon}/> : null }
        </div>

        { !lastChild && showLines ? <>
          <div style={inactiveLine} className="absolute border-l-2 border-r-0 border-dashed bottom-0 step-navigation__step-line"></div>
          <div style={activeLine} className="absolute border-solid border-r-0 bottom-0 step-navigation__step-line active"></div>
        </> : null}
      </div>
      <div className="flex-auto">
        {props.children}
      </div>
    </div>
  );
};

NavStep.displayName = 'NavStep';

NavStep.propTypes = {
  active: PropTypes.bool,
  complete: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.string,
  lastChild: PropTypes.bool,
  position: PropTypes.number
};

NavStep.defaultProps = {
  active: false,
  complete: false,
  lastChild: false,
  stepNavType: 'numerical',
  activeAccent: 'accent-0-dark',
  incompleteAccent: 'neutral-dark'
};
