import React from 'react';
import { WfnBadge } from '@adp-wfn/mdf-wc-react';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

type Status = 'default' | 'success' | 'info' | 'error' | 'warning' | 'attention' | 'pending' | 'start' | 'tip' | 'neutral' | 'changed' | 'complete' | 'failed' | 'new' | 'in-progress';
const statusMap = {
  'default': 'default',
  'success': 'complete',
  'info': 'default',
  'error': 'failed',
  'warning': 'changed',
  'attention': 'failed',
  'pending': 'in-progress',
  'start': 'new',
  'tip': 'default',
  'changed': 'changed',
  'complete': 'complete',
  'failed': 'failed',
  'new': 'new',
  'in-progress': 'in-progress'
};

export interface IBadgeProps {
  // The style of the Badge
  status?: Status;
  // Add a title to the Badge, title will take precedence over children prop
  title?: string
  // Add any styles
  className?: string;
  // The OneUX icon to use
  icon?: IconName;
  // Whether to show the icon
  withIcon?: boolean;
  // transculded content, title will take precedence over children prop
  children?: React.ReactNode
}

const defaultProps = {
  status: 'default',
  withIcon: false
};

export const MDFBadge = (props: IBadgeProps) => {
  const { title, children } = props;

  const derivedProps = {
    status: statusMap[props.status],
    icon: props.icon,
    withIcon: props.withIcon
  };

  return (
    <WfnBadge {...derivedProps}>{title || children}</WfnBadge>
  );
};

MDFBadge.displayName = 'Badge';
MDFBadge.defaultProps = defaultProps;
