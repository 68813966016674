import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { cloneNode, createElement, objType, toPx, unitConvert } from './utils';

export class Worker {
  private prop = {
    src: null,
    container: null,
    overlay: null,
    canvas: null,
    img: null,
    pdf: null,
    pageSize: null
  };

  private progress = {
    val: 0,
    state: null,
    n: 0,
    ratio: null,
    stack: []
  };

  // zoom: Custom property added to change the size of the content on the PDF
  private opt = {
    filename: 'file.pdf',
    margin: [0, 0, 0, 0],
    image: {
      type: 'jpeg',
      quality: 0.95
    },
    enableLinks: false,
    html2canvas: {} as any,
    jsPDF: {} as any,
    pagebreak: {
      mode: ['css', 'legacy'],
      before: [],
      after: [],
      avoid: []
    },
    zoom: 1
  };

  private linkInfo = [];

  constructor(opt) {
    // Set progress, optional settings, and return.
    this.setProgress(1, Worker, 1, [Worker]);
    this.set(opt);
  }

  private getType = (src) => {
    switch (objType(src)) {
      case 'string':
        return 'string';
      case 'element':
        return src.nodeName.toLowerCase === 'canvas' ? 'canvas' : 'element';
      default:
        return 'unknown';
    }
  };

  from = (src, type?) => {
    console.log('Worker.from(): Entering. src =', src);
    type = type || this.getType(src);
    console.log('Worker.from(): type =', type);

    switch (type) {
      case 'string':
        return this.set({ src: createElement('div', { innerHTML: src }) });
      case 'element':
        return this.set({ src: src });
      case 'canvas':
        return this.set({ canvas: src });
      case 'img':
        return this.set({ img: src });
      default:
        return this.error('Unknown source type.');
    }
  };

  to = (target) => {
    // Route the 'to' request to the appropriate method.
    switch (target) {
      case 'container':
        return this.toContainer();
      case 'canvas':
        return this.toCanvas();
      case 'img':
        return this.toImg();
      case 'pdf':
        return this.toPdf();
      default:
        return this.error('Invalid target.');
    }
  };

  private handlePageBreaks = () => {
    // Setup root element and inner page height.
    const root = this.prop.container;
    const pxPageHeight = this.prop.pageSize.inner.px.height * this.opt.zoom;

    // Check all requested modes.
    const modeSrc = [...this.opt.pagebreak.mode];
    const mode = {
      avoidAll: modeSrc.indexOf('avoid-all') !== -1,
      css: modeSrc.indexOf('css') !== -1,
      legacy: modeSrc.indexOf('legacy') !== -1
    };

    // Get arrays of all explicitly requested elements.
    const select = {};

    ['before', 'after', 'avoid'].forEach((key) => {
      const all = mode.avoidAll && key === 'avoid';

      select[key] = all ? [] : [].concat(this.opt.pagebreak[key] || []);

      if (select[key].length > 0) {
        select[key] = Array.prototype.slice.call(root.querySelectorAll(select[key].join(', ')));
      }
    });

    // Get all legacy page-break elements.
    let legacyEls = root.querySelectorAll('.html2pdf__page-break');
    legacyEls = Array.prototype.slice.call(legacyEls);

    // Loop through all elements.
    const els = root.querySelectorAll('*');
    Array.prototype.forEach.call(els, (el) => {
      // Setup pagebreak rules based on legacy and avoidAll modes.
      let rules = {
        before: false,
        after: mode.legacy && legacyEls.indexOf(el) !== -1,
        avoid: mode.avoidAll
      };

      // Add rules for css mode.
      if (mode.css) {
        // TODO: Check if this is valid with iFrames.
        const style = window.getComputedStyle(el);
        // TODO: Handle 'left' and 'right' correctly.
        // TODO: Add support for 'avoid' on breakBefore/After.
        const breakOpt = ['always', 'page', 'left', 'right'];
        const avoidOpt = ['avoid', 'avoid-page'];

        rules = {
          before: rules.before || breakOpt.indexOf(style.breakBefore || style.pageBreakBefore) !== -1,
          after: rules.after || breakOpt.indexOf(style.breakAfter || style.pageBreakAfter) !== -1,
          avoid: rules.avoid || avoidOpt.indexOf(style.breakInside || style.pageBreakInside) !== -1
        };
      }

      // Add rules for explicit requests.
      Object.keys(rules).forEach((key) => {
        rules[key] = rules[key] || select[key].indexOf(el) !== -1;
      });

      // Get element position on the screen.
      // TODO: Subtract the top of the container from clientRect.top/bottom?
      const clientRect = el.getBoundingClientRect();

      // Avoid: Check if a break happens mid-element.
      if (rules.avoid && !rules.before) {
        const startPage = Math.floor(clientRect.top / pxPageHeight);
        const endPage = Math.floor(clientRect.bottom / pxPageHeight);
        const nPages = Math.abs(clientRect.bottom - clientRect.top) / pxPageHeight;

        // Turn on rules.before if the el is broken and is at most one page long.
        if (endPage !== startPage && nPages <= 1) {
          rules.before = true;
        }
      }

      // Before: Create a padding div to push the element to the next page.
      if (rules.before) {
        const pad = createElement('div', {
          style: {
            display: 'block',
            height: pxPageHeight - (clientRect.top % pxPageHeight) + 'px'
          }
        });

        el.parentNode.insertBefore(pad, el);
      }

      // After: Create a padding div to fill the remaining page.
      if (rules.after) {
        const pad = createElement('div', {
          style: {
            display: 'block',
            height: pxPageHeight - (clientRect.bottom % pxPageHeight) + 'px'
          }
        });

        el.parentNode.insertBefore(pad, el.nextSibling);
      }
    });
  };

  private handleContainerLinks = () => {
    // Retrieve hyperlink info if the option is enabled.
    if (this.opt.enableLinks) {
      // Find all anchor tags and get the container's bounds for reference.
      const container = this.prop.container;
      const links: NodeList = container.querySelectorAll('a');
      const containerRect = unitConvert(container.getBoundingClientRect(), this.prop.pageSize.k);
      this.linkInfo = [];

      // Loop through each anchor tag.
      links.forEach((link: Element) => {
        // Treat each client rect as a separate link (for text-wrapping).
        const clientRects = link.getClientRects();

        for (const rect of Array.from(clientRects)) {
          const clientRect = unitConvert(rect, this.prop.pageSize.k);
          clientRect.left -= containerRect.left;
          clientRect.top -= containerRect.top;

          const page = Math.floor(clientRect.top / this.prop.pageSize.inner.height) + 1;
          const top = this.opt.margin[0] + clientRect.top % this.prop.pageSize.inner.height;
          const left = this.opt.margin[1] + clientRect.left;

          this.linkInfo.push({ page, top, left, clientRect, link });
        }
      });
    }
  };

  toContainer = () => {
    console.log('Worker.toContainer(). Entering.');

    // Set up function prerequisites.
    if (!this.prop.src) {
      this.error('Cannot duplicate - no source HTML.');
    }

    if (!this.prop.pageSize) {
      this.setPageSize();
    }

    return new Promise<Worker>((resolve) => {
      // Define the CSS styles for the container and its overlay parent.
      const overlayCSS: any = {
        position: 'fixed',
        overflow: 'hidden',
        zIndex: 1000,
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      };

      const containerCSS: any = {
        position: 'absolute',
        width: (this.prop.pageSize.inner.width * this.opt.zoom) + this.prop.pageSize.unit,
        left: 0,
        right: 0,
        top: 0,
        height: 'auto',
        margin: 'auto',
        backgroundColor: 'white'
      };

      // Set the overlay to hidden (could be changed in the future to provide a print preview).
      overlayCSS.opacity = 0;

      // Create and attach the elements.
      const source = cloneNode(this.prop.src, this.opt.html2canvas.javascriptEnabled);
      this.prop.overlay = createElement('div', { className: 'html2pdf__overlay', style: overlayCSS });
      this.prop.container = createElement('div', { className: 'html2pdf__container', style: containerCSS });
      this.prop.container.appendChild(source);
      this.prop.overlay.appendChild(this.prop.container);
      document.body.appendChild(this.prop.overlay);

      this.handlePageBreaks();
      this.handleContainerLinks();
      resolve(this);
    });
  };

  toCanvas = () => {
    console.log('Worker.toCanvas(). Entering.');
    let newPromise;

    // Set up function prerequisites.
    if (document.body.contains(this.prop.container)) {
      newPromise = Promise.resolve(this);
    }
    else {
      newPromise = this.toContainer();
    }

    // Fulfill prerequisites then create the canvas.
    return new Promise<Worker>((resolve, reject) => {
      newPromise
        .then(() => {
          // Handle old-fashioned 'onrendered' argument.
          const options = Object.assign({}, this.opt.html2canvas);
          delete options.onrendered;

          return html2canvas(this.prop.container, options);
        })
        .then((canvas) => {
          // Handle old-fashioned 'onrendered' argument.
          this.opt.html2canvas?.onRendered?.(canvas);
          this.prop.canvas = canvas;
          document.body.removeChild(this.prop.overlay);
          resolve(this);
        })
        .catch((error) => reject(error));
    });
  };

  toImg = () => {
    let newPromise;

    // Set up function prerequisites.
    if (this.prop.canvas) {
      newPromise = Promise.resolve(true);
    }
    else {
      newPromise = this.toCanvas();
    }

    // Fulfill prerequisites then create the image.
    return new Promise<Worker>((resolve, reject) => {
      newPromise
        .then(() => {
          const imgData = this.prop.canvas.toDataURL('image/' + this.opt.image.type, this.opt.image.quality);
          this.prop.img = document.createElement('img');
          this.prop.img.src = imgData;

          resolve(this);
        })
        .catch((error) => reject(error));
    });
  };

  private handlePdfLinks = () => {
    // Add hyperlinks if the option is enabled.
    if (this.opt.enableLinks) {
      // Attach each anchor tag based on info from toContainer().
      this.linkInfo.forEach((l) => {
        this.prop.pdf.setPage(l.page);
        this.prop.pdf.link(l.left, l.top, l.clientRect.width, l.clientRect.height, { url: l.link.href });
      });

      // Reset the active page of the PDF to the final page.
      const nPages = this.prop.pdf.internal.getNumberOfPages();
      this.prop.pdf.setPage(nPages);
    }
  };

  toPdf = () => {
    console.log('Worker.toPdf(). Entering.');
    let newPromise;

    // Set up function prerequisites.
    if (this.prop.canvas) {
      newPromise = Promise.resolve(true);
    }
    else {
      newPromise = this.toCanvas();
    }

    // Fulfill prerequisites then create the image.
    return new Promise<Worker>((resolve, reject) => {
      newPromise
        .then(() => {
          // Create local copies of frequently used properties.
          const canvas = this.prop.canvas;
          const opt = this.opt;

          // Calculate the number of pages.
          const pxFullHeight = canvas.height;
          const pxPageHeight = Math.floor(canvas.width * this.prop.pageSize.inner.ratio);
          const nPages = Math.ceil(pxFullHeight / pxPageHeight);

          // Define pageHeight separately so it can be trimmed on the final page.
          let pageHeight = this.prop.pageSize.inner.height;

          // Create a one-page canvas to split up the full image.
          const pageCanvas = document.createElement('canvas');
          const pageCtx = pageCanvas.getContext('2d');
          pageCanvas.width = canvas.width;
          pageCanvas.height = pxPageHeight;

          // Initialize the PDF.
          this.prop.pdf = this.prop.pdf || new jsPDF(opt.jsPDF);

          for (let page = 0; page < nPages; page++) {
            // Trim the final page to reduce file size.
            if ((page === nPages - 1) && (pxFullHeight % pxPageHeight !== 0)) {
              pageCanvas.height = pxFullHeight % pxPageHeight;
              pageHeight = pageCanvas.height * this.prop.pageSize.inner.width / pageCanvas.width;
            }

            // Display the page.
            const w = pageCanvas.width;
            const h = pageCanvas.height;
            pageCtx.fillStyle = 'white';
            pageCtx.fillRect(0, 0, w, h);
            pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

            // Add the page to the PDF.
            if (page) {
              this.prop.pdf.addPage();
            }

            const imgData = pageCanvas.toDataURL('image/' + opt.image.type, opt.image.quality);
            this.prop.pdf.addImage(imgData, opt.image.type, opt.margin[1], opt.margin[0], this.prop.pageSize.inner.width, pageHeight);
          }

          this.handlePdfLinks();
          resolve(this);
        })
        .catch((error) => reject(error));
    });
  };

  output = (type, options, src) => {
    // Redirect requests to the correct function (outputPdf / outputImg).
    src = src || 'pdf';

    if (src.toLowerCase() === 'img' || src.toLowerCase() === 'image') {
      return this.outputImg(type);
    }
    else {
      return this.outputPdf(type, options);
    }
  };

  outputPdf = (type, options) => {
    let newPromise;

    // Set up function prerequisites.
    if (this.prop.pdf) {
      newPromise = Promise.resolve(true);
    }
    else {
      newPromise = this.toPdf();
    }

    // Fulfill prerequisites then perform the appropriate output.
    return new Promise<any>((resolve, reject) => {
      newPromise
        .then(() => {
          /* Currently implemented output types:
           *    https://rawgit.com/MrRio/jsPDF/master/docs/jspdf.js.html#line992
           *  save(options), arraybuffer, blob, bloburi/bloburl,
           *  datauristring/dataurlstring, dataurlnewwindow, datauri/dataurl
           */
          resolve(this.prop.pdf.output(type, options));
        })
        .catch((error) => reject(error));
    });
  };

  outputImg = (type) => {
    let newPromise;

    // Set up function prerequisites.
    if (this.prop.img) {
      newPromise = Promise.resolve(true);
    }
    else {
      newPromise = this.toImg();
    }

    // Fulfill prerequisites then perform the appropriate output.
    return new Promise<any>((resolve, reject) => {
      newPromise
        .then(() => {
          switch (type) {
            case undefined:
            case 'img':
              resolve(this.prop.img);
              break;

            case 'datauristring':
            case 'dataurlstring':
              resolve(this.prop.img.src);
              break;

            case 'datauri':
            case 'dataurl':
              document.location.href = this.prop.img.src;
              resolve(this.prop.img.src);
              break;

            default:
              reject(new Error(`Image output type "${type}" is not supported.`));
          }
        })
        .catch((error) => reject(error));
    });
  };

  save = (filename?) => {
    let newPromise;

    // Set up function prerequisites.
    if (this.prop.pdf) {
      newPromise = Promise.resolve(true);
    }
    else {
      newPromise = this.toPdf();
    }

    this.set(filename ? { filename: filename } : undefined);

    // Fulfill prerequisites, update the filename (if provided), and save the PDF.
    return new Promise<Worker>((resolve, reject) => {
      newPromise
        .then(() => {
          this.prop.pdf.save(this.opt.filename);
          resolve(this);
        })
        .catch((error) => reject(error));
    });
  };

  set = (opt) => {
    console.log('Worker.set(): Entering. opt =', opt);
    // TODO: Implement ordered pairs?

    // Silently ignore invalid or empty input.
    if (objType(opt) !== 'object') {
      console.log('Worker.set(): opt is not an object. Exiting.');
      return this;
    }

    // Build an array of setter functions to queue.
    Object.keys(opt || {}).forEach((key) => {
      switch (key) {
        case 'margin':
          this.setMargin(opt.margin);
          break;

        case 'jsPDF':
          this.opt.jsPDF = opt.jsPDF;
          this.setPageSize();
          break;

        case 'pageSize':
          this.setPageSize(opt.pageSize);
          break;

        default:
          if (key in this.prop) {
            this.prop[key] = opt[key];
          }
          else {
            // Set any other properties in opt.
            this.opt[key] = opt[key];
          }

          break;
      }
    });

    console.log('Worker.set(): Exiting. this.prop =', this.prop);
    console.log('Worker.set(): Exiting. this.opt =', this.opt);
    return this;
  };

  get = (key, cbk) => {
    const val = (key in this.prop) ? this.prop[key] : this.opt[key];
    return cbk ? cbk(val) : val;
  };

  setMargin = (margin: number | number[]) => {
    // Parse the margin property: [top, left, bottom, right].
    switch (objType(margin)) {
      case 'number':
        margin = [margin as number, margin as number, margin as number, margin as number];
        // Fallthrough

      case 'array':
        if ((margin as number[]).length === 2) {
          margin = [margin[0], margin[1], margin[0], margin[1]];
        }

        if ((margin as number[]).length === 4) {
          break;
        }
        // Fallthrough

      default:
        return this.error('Invalid margin array.');
    }

    // Set the margin property, then update pageSize.
    this.opt.margin = margin as number[];
    this.setPageSize();
  };

  getPageSize = (orientation, unit?, format?) => {
    // Decode options object
    if (typeof orientation === 'object') {
      const options = orientation;

      orientation = options.orientation;
      unit = options.unit || unit;
      format = options.format || format;
    }

    // Default options
    unit = unit || 'mm';
    format = format || 'a4';
    orientation = ('' + (orientation || 'P')).toLowerCase();

    const formatAsString = ('' + format).toLowerCase();

    // Size in pt of various paper formats
    const pageFormats = {
      'a0': [2383.94, 3370.39],
      'a1': [1683.78, 2383.94],
      'a2': [1190.55, 1683.78],
      'a3': [841.89, 1190.55],
      'a4': [595.28, 841.89],
      'a5': [419.53, 595.28],
      'a6': [297.64, 419.53],
      'a7': [209.76, 297.64],
      'a8': [147.40, 209.76],
      'a9': [104.88, 147.40],
      'a10': [73.70, 104.88],
      'b0': [2834.65, 4008.19],
      'b1': [2004.09, 2834.65],
      'b2': [1417.32, 2004.09],
      'b3': [1000.63, 1417.32],
      'b4': [708.66, 1000.63],
      'b5': [498.90, 708.66],
      'b6': [354.33, 498.90],
      'b7': [249.45, 354.33],
      'b8': [175.75, 249.45],
      'b9': [124.72, 175.75],
      'b10': [87.87, 124.72],
      'c0': [2599.37, 3676.54],
      'c1': [1836.85, 2599.37],
      'c2': [1298.27, 1836.85],
      'c3': [918.43, 1298.27],
      'c4': [649.13, 918.43],
      'c5': [459.21, 649.13],
      'c6': [323.15, 459.21],
      'c7': [229.61, 323.15],
      'c8': [161.57, 229.61],
      'c9': [113.39, 161.57],
      'c10': [79.37, 113.39],
      'dl': [311.81, 623.62],
      'letter': [612, 792],
      'government-letter': [576, 756],
      'legal': [612, 1008],
      'junior-legal': [576, 360],
      'ledger': [1224, 792],
      'tabloid': [792, 1224],
      'credit-card': [153, 243]
    };

    // Unit conversion
    const unitConversionFactor = {
      pt: 1,
      mm: 72 / 25.4,
      cm: 72 / 2.54,
      in: 72,
      px: 72 / 96,
      pc: 12,
      em: 12,
      ex: 6
    };

    const k = unitConversionFactor[unit];

    if (!k) {
      throw new Error(`Invalid unit: ${unit}`);
    }

    let pageHeight;
    let pageWidth;

    // Dimensions are stored as user units and converted to points on output
    if (pageFormats.hasOwnProperty(formatAsString)) {
      pageHeight = pageFormats[formatAsString][1] / k;
      pageWidth = pageFormats[formatAsString][0] / k;
    }
    else {
      try {
        pageHeight = format[1];
        pageWidth = format[0];
      }
      catch (err) {
        throw new Error(`Invalid format: ${format}`);
      }
    }

    // Handle page orientation
    if (orientation === 'p' || orientation === 'portrait') {
      if (pageWidth > pageHeight) {
        const tmp = pageWidth;
        pageWidth = pageHeight;
        pageHeight = tmp;
      }
    }
    else if (orientation === 'l' || orientation === 'landscape') {
      if (pageHeight > pageWidth) {
        const tmp = pageWidth;
        pageWidth = pageHeight;
        pageHeight = tmp;
      }
    }
    else {
      throw new Error(`Invalid orientation: ${orientation}`);
    }

    // Return information (k is the unit conversion ratio from pts)
    return {
      width: pageWidth,
      height: pageHeight,
      unit,
      k
    };
  };

  setPageSize = (pageSize = undefined) => {
    // Retrieve page-size based on jsPDF settings, if not explicitly provided.
    pageSize = pageSize || this.getPageSize(this.opt.jsPDF);

    // Add 'inner' field if not present.
    if (!pageSize.hasOwnProperty('inner')) {
      pageSize.inner = {
        width: pageSize.width - this.opt.margin[1] - this.opt.margin[3],
        height: pageSize.height - this.opt.margin[0] - this.opt.margin[2]
      };

      pageSize.inner.px = {
        width: toPx(pageSize.inner.width, pageSize.k),
        height: toPx(pageSize.inner.height, pageSize.k)
      };

      pageSize.inner.ratio = pageSize.inner.height / pageSize.inner.width;
    }

    // Attach pageSize to this.
    this.prop.pageSize = pageSize;

    // Return this for command chaining.
    return this;
  };

  setProgress = (val, state, n, stack) => {
    // Immediately update all progress values.
    if (val != null) {
      this.progress.val = val;
    }

    if (state != null) {
      this.progress.state = state;
    }

    if (n != null) {
      this.progress.n = n;
    }

    if (stack != null) {
      this.progress.stack = stack;
    }

    this.progress.ratio = this.progress.val / this.progress.state;

    // Return this for command chaining.
    return this;
  };

  updateProgress = (val, state = null, n = null, stack = null) => {
    // Immediately update all progress values, using setProgress.
    return this.setProgress(
      val ? this.progress.val + val : null,
      state ? state : null,
      n ? this.progress.n + n : null,
      stack ? this.progress.stack.concat(stack) : null
    );
  };

  error = (msg) => {
    throw new Error(msg);
  };

  using = (opt) => this.set(opt);
  saveAs = (filename) => this.save(filename);
  export = (type, options, src) => this.output(type, options, src);
}
