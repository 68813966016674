import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import vdlPrefix from '../util/vdlPrefix';
import PlusCircle from 'adp-react-icons/lib/fa/plus-circle';
import MinusCircle from 'adp-react-icons/lib/fa/minus-circle';
import ExpandIcon from 'adp-react-icons/lib/fa/caret-down';
import { ComponentManager } from '@adp-wfn/mdf-core';
import { MDFIcon } from './MDFIcon';
import { Transition } from 'react-transition-group';
import { isEqual } from 'lodash';

export interface ITreeItem {
  // item label
  label: React.ReactNode;

  // item Icon
  icon?: string;

  // item Disabled
  disabled?: boolean;

  // Children items of this item node
  items?: ITreeItem[];

  // Whether this node is expanded initially
  expanded?: boolean;

  // viewItem
  viewName?: string;

  isTree?: boolean;

  // item Visited.
  isVisited?: boolean;

  // Accessibility
  accordionId?: string;
}

export interface ITreeItemProps {
  // Data item bound to the left nav item
  item: ITreeItem;

  // Selected item in the entire left nav menu
  selectedItem?: ITreeItem;

  // Callback fired when an item is expanded (expanded = true) or collapsed (expanded = false)
  onExpand: (item: ITreeItem, expanded: boolean) => void;

  // Callback fired when an item is selected
  onSelect: (item: ITreeItem) => void;

  // Callback fired when an item is clicked
  onClick?: (item: ITreeItem) => void;

  // disable an item
  disabled?: ITreeItem;

  // Show icon
  icon?: ITreeItem;

  // viewNAme
  viewItem?: string;

  // tree item or just leftnav
  isTree?: boolean;
  [x: string]: any;
}

export interface ITreeItemState {
  // Indicates if a left nav item is expanded
  expanded?: boolean;
}

export class MDFTreeItem extends React.Component<ITreeItemProps, ITreeItemState> {
  static propTypes = {
    item: PropTypes.object.isRequired,
    selectedItem: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    onClick: PropTypes.func
  };

  state: ITreeItemState = {
    expanded: this.props.item.expanded || false
  };

  handleClick = (event) => {
    const { item, onClick, onSelect } = this.props;

    // It's not enough to tell React to stop propagation, we need to also stop propagation of
    // of the native event to sibling event handlers we may not be aware of that would still
    // propagate the event up the tree.
    event.stopPropagation();
    event.nativeEvent?.stopImmediatePropagation();

    if (item.items?.length) {
      this.toggleExpandCollapse(event);
    }

    if (onClick) {
      onClick(item);
    }

    if (onSelect) {
      onSelect(item);
    }
  };

  toggleExpandCollapse = (event) => {
    const { item, onExpand } = this.props;

    // It's not enough to tell React to stop propagation, we need to also stop propagation of
    // of the native event to sibling event handlers we may not be aware of that would still
    // propagate the event up the tree.
    event.stopPropagation();
    event.nativeEvent && event.nativeEvent.stopImmediatePropagation();

    onExpand(item, !this.state.expanded);

    this.setState({
      expanded: !this.state.expanded
    });
  };

  handleKeyPress = (e) => {
    e.stopPropagation();
    e.nativeEvent?.stopImmediatePropagation();

    if (e.key === 'Enter') {
      this.handleClick(e);
    }
  };

  renderLabel = (item, viewItem) => {
    let label;
    const CustomView = viewItem && ComponentManager.getComponent(viewItem);

    // If a view is passed used that view instance else display the normal label
    if (viewItem) {
      label = <CustomView {...item} />;
    }
    else {
      label = item.icon ? <div className={item.className}> <MDFIcon className={classNames('treeItem--labelIcon', item.icon)} />{item.label}</div> : item.label;
    }

    return label;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.item && (props.item.expanded !== state.expanded)) {
      return {
        expanded: props.item.expanded
      };
    }

    return null;
  }

  render() {
    const { selectedItem, item, onExpand, onSelect, viewItem, isTree } = this.props;
    const expanded = this.state.expanded;
    const hasChildren = !!(item.items && item.items.length);
    const activeItemSelected = isEqual(item, selectedItem) && !item.disabled;

    const navItemClasses = {
      [vdlPrefix('left-nav__item')]: true,
      [vdlPrefix('left-nav__item--selected')]: isTree ? activeItemSelected : activeItemSelected && !hasChildren,
      [vdlPrefix('left-nav__item--has-children')]: hasChildren,
      [vdlPrefix('left-nav__item--expanded')]: hasChildren && this.state.expanded,
      [vdlPrefix('left-nav__item--collapsed')]: hasChildren && !this.state.expanded,
      [vdlPrefix('left-nav__item--disabled')]: item.disabled
    };

    const transitionStyles = {
      entering: vdlPrefix('left-nav__item__items--expanding'),
      entered: vdlPrefix('left-nav__item__items--expanded'),
      exited: vdlPrefix('left-nav__item__items--collapsed'),
      exiting: vdlPrefix('left-nav__item__items--collapsing')
    };

    const navItemIconClasses = {
      [vdlPrefix('left-nav__item__expand-icon')]: true,
      [vdlPrefix('left-nav__item__selected')]: activeItemSelected
    };

    const isVisitedItem = {
      [vdlPrefix('left-nav__item__label')]: true,
      [vdlPrefix('left-nav__item__label--isVisited')]: item.isVisited
    };

    let ItemsIcon = <ExpandIcon className={vdlPrefix('left-nav__item__expand-icon')} />;
    ItemsIcon = isTree ? (expanded ? <MinusCircle onClick={this.toggleExpandCollapse} className={classNames(navItemIconClasses)} /> : <PlusCircle onClick={this.toggleExpandCollapse} className={classNames(navItemIconClasses)} />) : ItemsIcon;
    const treeItemClassName = isTree ? 'treeItem' : '';
    let isAccordionHeader;
    hasChildren && !isTree && this.toggleExpandCollapse ? isAccordionHeader = true : isAccordionHeader = false;

    return (
      <div
        className={classNames(treeItemClassName, navItemClasses)}
        onClick={this.handleClick}
        onKeyPress={this.handleKeyPress}
      >
        <label
          role="button"
          tabIndex={0}
          className={classNames(isVisitedItem)}
          onClick={(hasChildren && !isTree) ? this.toggleExpandCollapse : undefined}
          aria-expanded={isAccordionHeader ? this.state.expanded : null}
          aria-controls={isAccordionHeader ? this.props.accordionId : null}
        >
          {hasChildren && ItemsIcon}
          {this.renderLabel(item, viewItem)}
          {!isTree && <div className={activeItemSelected && 'selectItemArrow'} />}
        </label>
        {
          hasChildren &&
          <div className={vdlPrefix('left-nav__item__scrollbox')} id={this.props.accordionId}>
            <Transition
              in={this.state.expanded}
              timeout={300}
            >
              {(state) => (
                <div className={transitionStyles[state]}>
                  <div className={vdlPrefix('left-nav__item__items')}>
                    {
                      (item.items as any[]).map(
                        (childItem, index) => (
                          <div key={`TreeItem${index}`}>
                            <div className={vdlPrefix('left-nav__item__horizontalLine')}></div>
                            <MDFTreeItem
                              key={index}
                              item={childItem}
                              onClick={childItem.disabled ? undefined : this.props.onClick}
                              onExpand={onExpand}
                              onSelect={onSelect}
                              selectedItem={selectedItem}
                              isTree={isTree}
                              viewItem={viewItem}
                            />
                          </div>
                        )
                      )
                    }
                  </div>
                </div>
              )}
            </Transition>
          </div>
        }
      </div>
    );
  }
}
