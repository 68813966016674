import React from 'react';
import { IButtonProps } from '@synerg/vdl-react-components';
import { SdfButton } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';
import { DeviceHelper, NativeHelper } from '@adp-wfn/mdf-core';
import { IMobileSsoParams, IOpenPageParams } from './MDFButton';

// Notes
// The MDFButton2 wraps the SdfButton component from @waypoint/react-components.

export interface IMDFButton2Props extends IButtonProps {
  // Set the id attribute on the component.
  id?: string;
  // The Font Awesome or ADP Icon Font to use for the buttons icon.
  iconClass?: string;
  // SSO support from the mobile app.
  mobileSsoParams?: IMobileSsoParams;
  // Open page support for Mobile running in Cordova.
  openPageParams?: IOpenPageParams;
  // Set the tabIndex attribute on the component.
  tabIndex?: number;
  // Set the title attribute of the component. Used primarily for text that might overflow or hover 'help'.
  title?: string;
}

export const MDFButton2 = (props: IMDFButton2Props) => {
  const {
    busy,
    buttonStyle,
    children,
    className,
    disabled,
    iconClass,
    id,
    mobileSsoParams,
    openPageParams,
    title,
    type,
    variant,
    onClick
  } = props;

  const legacyIcon = iconClass?.includes('fa-') || iconClass?.includes('icon-');

  if (legacyIcon) {
    console.error(`MDFButton2(): The iconClass property [${iconClass}] contains fa- or icon-, indicating a legacy icon. Please replace this with the corresponding Waypoint icon name or remove the iconClass property.`);
  }

  if (props.forceUpperCase) {
    console.error('MDFButton2(): The forceUpperCase property is deprecated and ignored. It will be removed in a future release. Please remove this property.');
  }

  if (props.tabIndex) {
    console.error('MDFButton2(): The tabIndex property is deprecated and ignored. It will be removed in a future release. Please remove this property.');
  }

  // Do not pass altText to mdfIcon for icon + text only scenario
  const iconAltText = props.children ? undefined : (props['aria-label'] || iconClass);

  const handleMobileOnClick = () => {
    if (mobileSsoParams) {
      NativeHelper.openPageWithSso(mobileSsoParams);
    }
    else {
      NativeHelper.openPage(openPageParams);
    }
  };

  const sdfButtonProps: any = {
    action: buttonStyle === 'danger' ? 'destructive' : undefined,
    busy: busy ? true : undefined,
    buttonTitle: iconAltText,
    className,
    disabled: disabled ? true : undefined,
    emphasis: (buttonStyle === 'primary' || buttonStyle === 'secondary') ? buttonStyle : undefined,
    icon: !legacyIcon ? iconClass : undefined,
    id,
    iconOnly: !children && iconClass ? true : undefined,
    submit: type === 'submit' ? true : undefined,
    title: title ?? undefined,
    variant: buttonStyle === 'link' || variant === 'text' ? 'text' : undefined,
    onClick: (DeviceHelper.isADPMobileApp() && (mobileSsoParams || openPageParams)) ? handleMobileOnClick : onClick
  };

  if (legacyIcon && sdfButtonProps.iconOnly) {
    sdfButtonProps.className = `${sdfButtonProps.className ?? ''} icon-only`;
    sdfButtonProps.iconOnly = undefined;
  }

  return (
    <SdfButton {...sdfButtonProps}>
      {(legacyIcon) ? <MDFIcon className={`${children ? 'mr-2 ' : ''}${iconClass}`} /> : ''}
      {children}
    </SdfButton>
  );
};

MDFButton2.displayName = 'MDFButton2';
