import React from 'react';
import PropTypes from 'prop-types';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import any from 'ramda/es/any';
import clone from 'ramda/es/clone';
import equals from 'ramda/es/equals';
import find from 'ramda/es/find';
import includes from 'ramda/es/includes';
import keys from 'ramda/es/keys';
import lensProp from 'ramda/es/lensProp';
import map from 'ramda/es/map';
import omit from 'ramda/es/omit';
import prop from 'ramda/es/prop';
import propEq from 'ramda/es/propEq';
import set from 'ramda/es/set';

import dragula from 'react-dragula';
import 'dragula/dist/dragula.min.css';
import classNames from 'classnames';

import Action from './Action';
import { moveLeftRightAll, moveLeftToRight, moveRightLeftAll, moveRightToLeft, moveWithin, removeIsSelectedInCollection, removeValueInCollection, retrieveOptionsList, updateValueInCollection } from './DualListHelper';
import { ListItem } from './DualListItem';
import { Textbox } from '../Textbox';

// Maintain single options list
// update state for left and right options
// left options uses 'available' and right side sets className with 'selected'
// uses style from VDL dualSelect
// move and drag items is possible only when disabled is false.
// move options/listitems using the action controls
//   1. When option is moved from left to right update the state.leftoptions with an attribute 'isMoved' to true
//   2. When an option is selected set the option with an attribute of 'isSelected' to true and ready to be moved
//   3. Action controls moveLeftAll, moveLeft, moveRight and moveRightAll - using its own onClick event to update the state with the attributes.
//   4. Check if the item has listClass and pass it to the listItem
//   5. The application can sent the new sorted da
// Move options using Dragula
//   1. Move between containers (right and left containers)
//   2. Tried to use the same functionality of  moveLeft and moveRight methods(actions).
//   3. Currently moves the items that are selected since moveLeft and moveRight is already using the isSelected Property
//   4. To Do: Need to somehow collpase the items onDrag event
//   3. Drag within the same container
//   4. To do: highlight the option once the item is dragged.
//   Added listClass Props to support user class for each list item.
//   Added disabled feature.
//   **Validation**
//   Set error state only to the Right side when required property is true and isValid props is false.
//   Avoid onBlur to the action controls setting the tabIndex to -1 for those controls.
export interface IMultiSelectItem {
  label: string;
  value: string;
  id?: string;
}

export interface IDoubleListBoxProps {
  // id for accessibility support
  id?: string;

  // Accessibility message for when component is invalid.
  'aria-invalid'?: any;

  // Array of options
  options?: IMultiSelectItem[];

  // Array of selected items (as strings)
  selected?: string[];

  // Enable Filter
  filter?: boolean;

  // OnChange event that is called when an item is moved/dragged and dropped from left to right
  onChange?: (item) => void;

  // To disable the component.
  disabled?: boolean;

  // Required property For validation
  required?: boolean;

  // Text to display when the component is invalid
  invalidLabelText?: string;

  // Is this component valid
  isValid?: boolean;

  // Label Text to show on the right side.
  rightLabel?: string;

  // Label Text to show on the left side.
  leftLabel?: string;

  // Called when onSelect is passed and click on list items
  onSelect?: (value) => void;

  // Shows sequence numbers for selected items.
  showSelectedSequence?: boolean;

  // Detects blur event; used in handling error messaging.
  onBlur?: (value) => void;

  // Detects focus event; used in handling error messaging.
  onFocus?: (value) => void;

  // Placeholder text when filter option is being used.
  placeholder?: string;

  // Passing keyboardInstructionsId from MDFDualMultiSelect
  ariaDescribedby?: string;

  // Accessibility feature. Identify the next focusable element on the page by id.
  manageFocusNodeId?: string;
}

export default class DoubleListBox extends React.Component<IDoubleListBoxProps, any> {
  containers: HTMLElement[];
  screenReaderDivRef: React.RefObject<HTMLDivElement>;

  static propTypes = {
    id: PropTypes.string,
    options: PropTypes.array,
    selected: PropTypes.array,
    filter: PropTypes.bool,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    invalidLabelText: PropTypes.string,
    isValid: PropTypes.bool,
    onSelect: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    ariaDescribedby: PropTypes.string,
    manageFocusNodeId: PropTypes.string
  };

  static defaultProps = {
    options: [],
    selected: []
  };

  constructor(props) {
    super(props);

    const dualListOptions = DoubleListBox.setLeftRightOptions(this.props.options, this.props.selected);

    this.state = {
      leftOptions: dualListOptions.leftOptions,
      rightOptions: dualListOptions.rightOptions,
      leftSearchTerm: '',
      rightSearchTerm: '',
      leftTextboxValue: '',
      rightTextboxValue: ''
    };

    this.containers = [];

    this.screenReaderDivRef = React.createRef();
  }

  static setLeftRightOptions(propsOptions, selected) {
    const options = clone(propsOptions);

    return {
      leftOptions: options.map(
        (option) => {
          if (includes(option.value, selected)) {
            return set(lensProp('isMoved'), true, option);
          }

          return option;
        }
      ),
      rightOptions: selected
        .map((item) => find(propEq(item, 'value'))(options))
        .filter((item) => !!item)
    };
  }

  collectContainers = (container: HTMLElement) => {
    !this.props.disabled && this.containers.push(container);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { options, selected } = nextProps;

    // Compare options and selected values of nextProps , prevState and return the updated duallist.
    // Added sort method on selected values to sort before it compares because ramda equals method compares values of an array at each Index.
    if (!equals(retrieveOptionsList(options), retrieveOptionsList(prevState.leftOptions))) {
      return DoubleListBox.setLeftRightOptions(options, selected);
    }
    else if (!equals(selected.slice().sort(), (map(prop('value'), prevState.rightOptions)).sort())) {
      const leftOptions = map((option) => omit(['isMoved', 'isSelected'], option), prevState.leftOptions);
      return DoubleListBox.setLeftRightOptions(leftOptions, selected);
    }

    return null;
  }

  componentDidMount() {
    if (!this.props.disabled) {
      const draggable = (el) => !el.classList.contains('no-dnd');

      // Instantiate dragula, tell it which containers hold draggable tiles, and which tiles are draggable
      const drake = dragula(this.containers, {
        accepts: draggable,
        moves: draggable
      });

      drake.on('drop', (el, target, source, sibling) => {
        // Ensure nothing happens to the DOM that react doesn't know about.
        drake.cancel(true);

        // Functional setState to avoid setting state with an out of date object
        this.setState(this.updateListLayout(el, target, source, sibling));
      });

      drake.on('drag', (el) => {
        this.setState(el.className.includes('selected') ? { rightOptions: this.setAsSelected(this.state.rightOptions, el.dataset.value) } : { leftOptions: this.setAsSelected(this.state.leftOptions, el.dataset.value) });
      });
    }
  }

  setAsSelected = (options, selectedValue) =>
    options.map((option) => {
      if (option.value === selectedValue) {
        option.isSelected = true;
      }

      return option;
    });

  // upon dropping the item update the options based on the container if it is selected or available
  updateListLayout = (_el, target, source, sibling) => (
    () => {
      // need it to style the selected element
      const rightContainer = target && target.className.includes('selected');
      const leftContainer = target && target.className.includes('available');
      const listCollection = leftContainer ? this.state.leftOptions : this.state.rightOptions;
      let targetValue;

      // Dragging within the same container where target and source are equal.
      if (target === source) {
        if (sibling) {
          targetValue = sibling.getAttribute('data-value');
        }

        // when there is no sibling send targetValue as empty
        const newState = moveWithin(listCollection, targetValue);

        return newState && leftContainer ? this.setState({ leftOptions: newState }) : this.moveRightWithin(newState);
      }

      // Dragging between right and left container
      // If the targetContainer is right call moveRight
      if (leftContainer) {
        this.moveLeft(sibling);
      }
      else if (rightContainer) {
        this.moveRight(sibling);
      }

      removeIsSelectedInCollection(this.state.leftOptions);
      removeIsSelectedInCollection(this.state.rightOptions);
    }
  );

  isOptionSelected = (options: any[] = []) => any((option: any) => option.isSelected === true)(options);

  onLeftSelect = (obj) => {
    if (!this.props.disabled) {
      this.handleSelectedItem(obj, 'leftOptions');
    }
  };

  onRightSelect = (obj) => {
    if (!this.props.disabled) {
      this.handleSelectedItem(obj, 'rightOptions');
    }
  };

  // call handleChange when dragging within the right side container
  moveRightWithin = (rightOptions) => {
    this.setState({ rightOptions: rightOptions });
    this.handleChange(rightOptions);
  };

  handleChange = (rightOptions) => {
    const { onChange } = this.props;

    if (onChange) {
      const selectedValues = map(prop('value'), rightOptions);
      onChange(selectedValues);
    }
  };

  handleSelectedItem = (obj, stateLabel) => {
    const newState = {};
    const value = keys(obj)[0];

    if (obj[value]) {
      newState[stateLabel] = removeValueInCollection(value, this.state[stateLabel]);
    }
    else {
      newState[stateLabel] = updateValueInCollection(value, this.state[stateLabel]);
    }

    this.setState(newState);

    if (this.props.onSelect) {
      this.props.onSelect(value);
    }
  };

  addTextToScreenReaderDiv = (node, moveDirection) => {
    // only when using keyboard, add the text to the ref element for accessibility
    // while dragging the options, node is null
    if (node) {
      const label = (moveDirection === 'right') ? this.props.rightLabel : this.props.leftLabel;
      const containerSide = (moveDirection === 'right') ? 'leftOptions' : 'rightOptions';
      const selectedCount = this.state[containerSide].filter((item) => item.isSelected).length;
      this.screenReaderDivRef.current.innerText = FormatHelper.formatMessage('@@MDFDualListSelectAnnouncement', [selectedCount, label]);
    }
  };

  moveRight = (node) => {
    const newState = moveLeftToRight(this.state, node);
    this.setState(newState);
    this.handleChange(newState.rightOptions);
    this.addTextToScreenReaderDiv(node, 'right');
  };

  moveRightAll = () => {
    const newState = moveLeftRightAll(this.state);
    this.setState(newState);
    this.handleChange(newState.rightOptions);
  };

  moveLeft = (node) => {
    const newState = moveRightToLeft(this.state);
    this.setState(newState);
    this.handleChange(newState.rightOptions);
    this.addTextToScreenReaderDiv(node, 'left');
  };

  moveLeftAll = () => {
    const newState = moveRightLeftAll(this.state);
    this.setState(newState);
    this.handleChange(newState.rightOptions);
  };

  leftChange = (event) => {
    this.setState({ leftSearchTerm: event });
    this.setState({ leftTextboxValue: event });
  };

  rightChange = (event) => {
    this.setState({ rightSearchTerm: event });
    this.setState({ rightTextboxValue: event });
  };

  handleFocus = (containerSide) => {
    const containerSideOptions = containerSide + 'Options';

    // If the active element if a selected item, programmatically set focus otherwise it will get lost
    if (this.state[containerSideOptions].find((item) => item.id === document.activeElement.id).isSelected) {
      const index = this.state[containerSideOptions].findIndex((item) => item.id === document.activeElement.id);
      let itemToFocusId;

      // Find the next unselected item
      for (let i = index + 1; i < this.state[containerSideOptions].length; i++) {
        if (!(this.state[containerSideOptions][i].isSelected || this.state[containerSideOptions][i].isMoved)) {
          itemToFocusId = this.state[containerSideOptions][i].id;
          break;
        }
      }

      // There were no next unselected options, so find the closest previous item
      if (!itemToFocusId) {
        for (let i = index - 1; i >= 0; i--) {
          if (!(this.state[containerSideOptions][i].isSelected || this.state[containerSideOptions][i].isMoved)) {
            itemToFocusId = this.state[containerSideOptions][i].id;
            break;
          }
        }
      }

      // If there are no next or previous items, focus on the container
      if (!itemToFocusId) {
        itemToFocusId = containerSide + '-options';
      }

      document.getElementById(itemToFocusId).focus();
    }
  };

  onKeyDown = (event, id) => {
    // Accessibility feature: Handles focus management.
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();

      // Manage focus after selecting move all right
      if (id === 'move-all-right') {
        const activeItem = document.getElementById('move-all-left');
        activeItem.focus();
        return null;
      }

      // Manage focus after selecting move all left
      if (id === 'move-all-left') {
        setTimeout(() => {
          const activeItem = document.getElementById('right-options');
          activeItem.focus();
        }, 0);
        return null;
      }

      // Manage focus after selecting move left
      if (id === 'move-left') {
        const activeItem = document.getElementById('move-all-left');
        activeItem.focus();
        return null;
      }

      // Manage focus after selecting move right
      if (id === 'move-right') {
        if (this.isOptionSelected(this.state.rightOptions)) {
          const activeItem = document.getElementById('move-left');
          activeItem.focus();
        }
        else {
          const activeItem = document.getElementById('move-all-left');
          activeItem.focus();
        }
        return null;
      }

      // Use SPACEBAR to to select items
      if (event.key === ' ') {
        event.preventDefault();
        const obj = {};

        if (this.state['rightOptions'].findIndex((item) => item.id === event.target.id) !== -1) {
          obj[event.target.dataset.value] = this.state['rightOptions'].find((item) => item.isSelected && (item.id === event.target.id));
          this.onRightSelect(obj);
        }
        else {
          obj[event.target.dataset.value] = this.state['leftOptions'].find((item) => item.isSelected && (item.id === event.target.id));
          this.onLeftSelect(obj);
        }
      }

      // Use ENTER to move items to other list.
      if (event.key === 'Enter') {
        event.preventDefault();

        // The enter key functionality should only work for the container side where focus is on
        if (this.state['rightOptions'].findIndex((item) => item.id === document.activeElement.id) !== -1) {
          this.handleFocus('right');
          this.moveLeft({ direction: 'left', isMoveAll: false });
        }
        else {
          this.handleFocus('left');
          this.moveRight({ direction: 'right', isMoveAll: false });
        }
      }
    }

    // Accessibility feature: Handles arrow key navigation
    if (event.keyCode === 40 || event.keyCode === 38) {
      event.preventDefault();

      let activeOptions;
      let nextItemIndex;

      const { leftOptions, rightOptions } = this.state;
      const checkListBeginning = () => (nextItemIndex === -1) ? nextItemIndex = activeOptions.length - 1 : '';
      const checkListEnd = () => (nextItemIndex > activeOptions.length - 1) ? nextItemIndex = 0 : '';
      const leftOptionsIndex = leftOptions.findIndex((item) => item.id === id);
      const rightOptionsIndex = rightOptions.findIndex((item) => item.id === id);

      // Handles down arrow navigation
      if (event.keyCode === 40) {
        if (rightOptionsIndex !== -1) {
          activeOptions = rightOptions;
          nextItemIndex = rightOptionsIndex + 1;
        }
        else {
          activeOptions = leftOptions;
          nextItemIndex = leftOptionsIndex + 1;
        }

        // Handles when navigation reaches list end and loops back to the beginning
        checkListEnd();

        // Skips over previously selected items
        if (activeOptions[nextItemIndex].isMoved) {
          do {
            nextItemIndex = nextItemIndex + 1;
            checkListEnd();
          }
          while (activeOptions[nextItemIndex].isMoved);
        }
      }

      // Handles up arrow navigation
      if (event.keyCode === 38) {
        if (rightOptionsIndex !== -1) {
          activeOptions = rightOptions;
          nextItemIndex = rightOptionsIndex - 1;
        }
        else {
          activeOptions = leftOptions;
          nextItemIndex = leftOptionsIndex - 1;
        }

        // Handles when navigation reaches list beginning and loops back to the end
        checkListBeginning();

        // Skips over previously selected items
        if (activeOptions[nextItemIndex].isMoved) {
          do {
            nextItemIndex = nextItemIndex - 1;
            checkListBeginning();
          }
          while (activeOptions[nextItemIndex].isMoved);
        }
      }

      // Moves focus to next item
      const activeItemId = activeOptions[nextItemIndex].id;
      const activeItem = document.getElementById(`${activeItemId}`);
      activeItem.focus();
    }
  };

  onContainerFocus = (event) => {
    if (event.currentTarget === event.target) {
      const targetRef = event.currentTarget.querySelector('[role="option"]');

      // Move focus to first option on container recieves focus
      if (!event.currentTarget.contains(event.relatedTarget)) {
        targetRef?.focus();
      }

      event.preventDefault();
    }
  };

  // Action controls setting an inline style of top taken from panorama.
  renderActions = (disabled) => (
    <div className="vdl-dual-select__button-overlay" style={this.props.filter && !this.props.disabled ? { top: '120.5px' } : { top: '90.5px' }}>
      <div>
        <Action className="move-all-right" id="move-all-right" direction="right" disabled={(this.state.leftOptions.length === this.state.rightOptions.length) || disabled} isMoveAll onClick={this.moveRightAll} handleKeyboardSelection={this.onKeyDown} />
      </div>
      <div>
        <Action className="move-right" id="move-right" direction="right" disabled={!this.isOptionSelected(this.state.leftOptions) || disabled} onClick={this.moveRight} handleKeyboardSelection={this.onKeyDown} />
      </div>
      <div>
        <Action className="move-left" id="move-left" direction="left" disabled={!this.isOptionSelected(this.state.rightOptions) || disabled} onClick={this.moveLeft} handleKeyboardSelection={this.onKeyDown} />
      </div>
      <div>
        <Action className="move-all-left" id="move-all-left" direction="left" disabled={(this.state.rightOptions.length === 0) || disabled} isMoveAll onClick={this.moveLeftAll} handleKeyboardSelection={this.onKeyDown} />
      </div>
    </div>
  );

  renderListItem = (listprops, isLeftOptions, item) => {
    return (
      <ListItem
        draggable={!listprops.disabled && true}
        id={item.id}
        isAvailable={!!isLeftOptions}
        key={item.value}
        listClass={item.listClass}
        disabled={listprops && listprops.disabled}
        value={item.value}
        label={item.label}
        onSelect={isLeftOptions ? this.onLeftSelect : this.onRightSelect}
        onMove={isLeftOptions ? this.moveRight : this.moveLeft}
        isSelected={item.isSelected}
        handleKeyboardSelection={this.onKeyDown}
      />
    );
  };

  renderOptions = (options, ctrlKey, searchTerm) => {
    const isLeftOptions = ctrlKey === 'available' && true;
    const filterMovedOptions = isLeftOptions && (options.filter((lo) => !lo.isMoved === true));
    const filterOptions = filterMovedOptions || options;
    const listprops = this.props;

    return (
      filterOptions
        .filter((lo) => lo.label.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
        .map((item) => this.renderListItem(listprops, isLeftOptions, item))
    );
  };

  renderListOptions = (options, searchTerm, onChange, ctrlKey, isValid, textboxValue, ariaDescribedby) => {
    const { id, 'aria-invalid': ariaInvalid, required } = this.props;
    const isLeftOptions = ctrlKey === 'available' && true;
    const errorState = !isLeftOptions && !isValid && this.props.required && 'vdl-dual-select_validation_error';
    const errorMessage = errorState && ((this.props.invalidLabelText || `${FormatHelper.formatMessage('@@mustSelectOne')}`));
    const inValidText = errorState && 'inValid__text';
    const title = isLeftOptions ? (this.props.leftLabel || `${FormatHelper.formatMessage('@@Available')}`) : (errorMessage || (this.props.rightLabel || `${FormatHelper.formatMessage('@@Selected')}`));
    const sequenceClassName = (this.props.showSelectedSequence && !isLeftOptions) ? 'show-sequence' : '';
    const listTitleId = isLeftOptions ? `dual-select-available-${id}` : `dual-select-selected-${id}`;
    const placeholderText = this.props.placeholder || FormatHelper.formatMessage('@@Search');
    const activeElementId = document.activeElement.id;

    return (
      <div className="vdl-col-md-6 vdl-col-sm-6" >
        <label id={listTitleId} className={classNames('vdl-dual-select__list-title', inValidText)}>{title}</label>
        {!this.props.disabled && this.props.filter && <Textbox immediate={true} value={textboxValue} className="search-input" onChange={onChange} autoComplete="off" placeholder={placeholderText} aria-label={title} />}
        <div className={classNames('vdl-dual-select__list-holder', ctrlKey, errorState)} tabIndex={0} aria-labelledby={ariaDescribedby} onFocus={this.onContainerFocus} id={isLeftOptions ? 'left-options' : 'right-options'}>
          <ul role="listbox" aria-multiselectable={true} aria-activedescendant={activeElementId} aria-labelledby={listTitleId} aria-invalid={ariaInvalid} className={classNames('vdl-dual-select__list-group', ctrlKey, sequenceClassName)} ref={this.collectContainers} aria-required={required}>{this.renderOptions(options, ctrlKey, searchTerm)}</ul>
        </div>
      </div>
    );
  };

  render() {
    const { id, leftOptions, rightOptions, leftSearchTerm, rightSearchTerm, leftTextboxValue, rightTextboxValue } = this.state;
    const { ariaDescribedby, disabled, isValid, onBlur, onFocus } = this.props;

    let nodeId = id;

    if (!nodeId) {
      nodeId = generateId('preSelectedOptions');
    }

    return (
      <div className="vdl-row vdl-dual-select" onBlur={onBlur} onFocus={onFocus}>
        <div className="ms-container" id={nodeId} >
          {this.renderListOptions(leftOptions, leftSearchTerm, this.leftChange, 'available', isValid, leftTextboxValue, ariaDescribedby)}
          {this.renderActions(disabled)}
          {this.renderListOptions(rightOptions, rightSearchTerm, this.rightChange, 'selected', isValid, rightTextboxValue, ariaDescribedby)}
          {/* Below div is for screen reader announcements when an option is selected with keyboard */}
          {<div className="mdf-sr-only" ref={this.screenReaderDivRef} aria-live="polite"></div>}
        </div>
      </div>
    );
  }
}
