import React from 'react';
import PropTypes from 'prop-types';
import { DeviceHelper, FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
import { omit } from 'lodash';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface INumberInputProps {
  id?: string;
  value?: number;
  placeholder?: string;
  format?: any;
  autoFormat?: any;
  autoComplete?: string;
  maxLength?: number;
  parse?: (str: string, culture?: string) => number;
  culture?: string;
  min?: number;
  onChange: (value?: number | string) => void;
  onClick?: (event) => void;
  onFocus?: (event) => void;
  onBlur?: (event) => void;
  onKeyDown?: (event) => void;
  onKeyPress?: (event) => void;
  onKeyUp?: (event) => void;
  disabled?: boolean;
  // To use mobile number picker if supported
  adaptive?: boolean;
  readOnly?: boolean;
  editing?: boolean;
  className?: string;
  autoFocus?: boolean;
  minimumFractionDigits?: number;
  formatValue?: any;
  decimal?: number;
  name?: string;
  role?: string;
  tabIndex?: number;
  immediate?: boolean;
  required?: boolean;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
  ariaLabel?: string;
  'aria-label'?: string;
}

export interface INumberInputState {
  stringValue?: string;
}

export class MDFNumberInput extends React.Component<INumberInputProps, INumberInputState> {
  private localeChar = '.';
  private thousandSeparator = ',';

  static propTypes = {
    value: PropTypes.number,
    placeholder: PropTypes.string,
    format: PropTypes.any,
    autoFormat: PropTypes.any,
    autoComplete: PropTypes.string,
    parse: PropTypes.func,
    culture: PropTypes.string,
    min: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    adaptive: PropTypes.bool,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    minimumFractionDigits: PropTypes.number,
    decimal: PropTypes.number,
    formatValue: PropTypes.any,
    editing: PropTypes.bool,
    className: PropTypes.string,
    autoFocus: PropTypes.bool,
    name: PropTypes.string,
    role: PropTypes.string,
    tabIndex: PropTypes.number,
    immediate: PropTypes.bool,
    required: PropTypes.bool,
    ariaDescribedby: PropTypes.string,
    ariaLabel: PropTypes.string
  };

  static contextTypes = {
    isCurrency: PropTypes.string
  };

  static defaultProps: INumberInputProps = {
    min: 0,
    editing: false,
    onChange: () => undefined
  };

  private inputRef: HTMLElement;

  private useDevicePicker = false;

  constructor(props: INumberInputProps) {
    super(props);
    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();
    this.state = this.getDefaultState();

    this.localeChar = MDFNumberInput.getlocaleChar(this.props);
    this.thousandSeparator = MDFNumberInput.getThousandSeparator(this.props);
  }

  // Get the decimal separator for userLocale or from the culture props.
  public static getlocaleChar(props) {
    return props.culture ? FormatHelper.getLocaleNumberFormat(props.culture).decimalSeparator : FormatHelper.getLocaleNumberFormat(LocaleHelper.getUserLocale()).decimalSeparator;
  }

  public static getThousandSeparator(props) {
    return props.culture ? FormatHelper.getLocaleNumberFormat(props.culture).thousandSeparator : FormatHelper.getLocaleNumberFormat(LocaleHelper.getUserLocale()).thousandSeparator;
  }

  componentWillReceiveProps(nextProps: INumberInputProps) {
    this.setState(
      this.getDefaultState(nextProps)
    );
  }

  // Default formatted Value
  private getDefaultFormatValue = (props, strNumValue) => {
    const minimumFractionDigits = (props.minimumFractionDigits || props.minimumFractionDigits === 0) ? props.minimumFractionDigits : props.decimal;

    // when autoFormat is set to true format the number.
    if (props.autoFormat) {
      return FormatHelper.formatNumber(strNumValue, { minimumFractionDigits: minimumFractionDigits, maximumFractionDigits: props.decimal ? props.decimal : 0 }, props.culture ? props.culture : null);
    }
    else {
      return strNumValue;
    }
  };

  private getDefaultState = (props = this.props) => {
    const value = props.value;
    const delimChar = this.localeChar;
    let stringValue = '';

    let strNumValue = value || value === 0 ? value.toString() : '';

    // To check if the value is not a number had to convert it to decimal
    const numberValue = strNumValue.replace(delimChar, '.');

    if (isNaN(parseFloat(numberValue))) {
      stringValue = '';
    }
    else if (props.editing) {
      stringValue = ('' + value).replace('.', delimChar);
    }
    else {
      // accept decimals for different locales.
      strNumValue = numberValue;

      // Need to format only in the onBlur
      stringValue = props.formatValue ? props.formatValue : this.getDefaultFormatValue(props, strNumValue);
    }

    return {
      stringValue
    };
  };

  getInput = () => this.inputRef;

  private change = (e) => {
    let val = this.adjustDecimalPrecision(e.target.value);
    const numValue = this.parse(val);

    // we dont need this intermediate check to remove the padded the zeros during onChange
    const isIntermediate = this.isIntermediateValue(numValue, val);

    // do not allow non-numbers except signs and decimals isIntermediate will return true if the entered number is "-" and decimals.
    // first character is a negative character or a decimal isIntermediate will return true and it is accepted character.
    if (!val || !val.trim() || (isNaN(numValue) && !isIntermediate)) {
      val = '';
    }

    this.current(val);

    // call onChange on every key stroke only when immediate props is true.
    if (!isIntermediate && this.props.immediate) {
      // to compare 3.20 to 3.2 need to compare only when it is a string
      // only when immediate is true we send the value as string back to the application.
      // onBlur updates the string VALUE to Number before it is sent to the application to avoid the current behaviour(application is expecting a number type for value).
      this.props.onChange(val);
    }
  };

  private finish = (event: any) => {
    const str = this.state.stringValue;
    let numberValue: number | undefined = this.parse(str);

    // if number is below the min
    // we need to flush low values and decimal stops, onBlur means i'm done inputting
    if (this.isIntermediateValue(numberValue, str)) {
      if (isNaN(numberValue)) {
        numberValue = undefined;
      }

      // handleOnblur is never called
      this.props.onChange(numberValue);
    }

    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  private adjustDecimalPrecision = (value: string): string => {
    if (value) {
      // Remove the thousand separator and text characters entered before trying to convert it to a number or adjust decimal precision.
      value = value.replace(new RegExp(this.thousandSeparator, 'g'), '').replace(/[a-zA-Z]/g, '');

      // constructing regex based on localeChar and required decimal
      if (this.props?.decimal >= 0) {
        const regex = new RegExp(`^-?[0-9]*(\\${this.localeChar}[0-9]{0,${this.props.decimal}})?`);
        const parsedValue = value.match(regex);

        if (parsedValue && parsedValue.length === 2) {
          return parsedValue[0];
        }
      }
      else {
        const regex = new RegExp('^-?[0-9]*');
        const parsedValue = value.match(regex);

        if (parsedValue && parsedValue.length === 1) {
          return parsedValue[0];
        }
      }
    }

    return value;
  };

  private parse = (strVal: string): number => {
    const culture = this.props.culture;
    const delimChar = this.localeChar;
    const userParse = this.props.parse;

    if (userParse) {
      return userParse(strVal, culture);
    }

    strVal = strVal.replace(delimChar, '.');
    return parseFloat(strVal);
  };

  isIntermediateValue = (num, str) => ((num < !this.props.min) || this.isSign(str) || this.isAtDelimiter(str) || this.isPaddedZeros(str) || this.isAtDecimalZero(str));

  isSign = (val) => (val || '').trim() === '-';

  isPaddedZeros = (str) => {
    const decimals = str.split(this.localeChar)[1];

    // during editing we don't need to remove the padded zeros
    return !this.props.editing && !!(decimals && decimals.match(/0+$/));
  };

  isAtDelimiter = (str) => {
    const lastIndex = str.length - 1;

    if (str.length < 1) {
      return false;
    }

    const char = str[lastIndex];

    return ((char === this.localeChar) && (str.indexOf(char) === lastIndex));
  };

  isAtDecimalZero = (str) => {
    const decimals = str.split(this.localeChar)[1];

    return this.props.immediate && decimals?.[decimals.length - 1] === '0';
  };

  isValid = (num) => {
    if (typeof num !== 'number' || isNaN(num)) {
      return false;
    }

    return num >= this.props.min;
  };

  // this intermediate state is for when one runs into the decimal or are typing the number
  current = (stringValue) => {
    this.setState({ stringValue });
  };

  render() {
    const value = this.state.stringValue;
    const customProps = omit(this.props, Object.keys(MDFNumberInput.propTypes));
    const ariaLabel = resolveAriaProperty('MDFNumberInput', 'aria-label', 'ariaLabel', this.props);
    const ariaDescribedby = resolveAriaProperty('MDFNumberInput', 'aria-describedby', 'ariaDescribedby', this.props);

    return (
      <input
        id={this.props.id}
        {...customProps}
        ref={(c) => this.inputRef = c}
        className={this.props.className}
        maxLength={this.props.maxLength}
        type={this.useDevicePicker && DeviceHelper.isIOS() ? 'number' : 'text'}
        step={this.useDevicePicker ? 'any' : ''}
        onChange={this.change}
        onBlur={this.finish}
        onFocus={this.props.onFocus}
        aria-disabled={this.props.disabled}
        aria-readonly={this.props.readOnly}
        disabled={this.props.disabled}
        readOnly={this.props.readOnly}
        placeholder={this.props.placeholder}
        value={value}
        onKeyPress={this.props.onKeyPress}
        onKeyUp={this.props.onKeyUp}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autoComplete}
        name={this.props.name}
        role={this.props.role}
        tabIndex={this.props.tabIndex}
        aria-required={this.props.required}
        aria-describedby={ariaDescribedby}
        aria-label={ariaLabel}
      />
    );
  }
}
