import React from 'react';
import PropTypes from 'prop-types';
import { FormatHelper, generateId } from '@adp-wfn/mdf-core';
import { MDFButton } from '../MDFButton';
import classNames from 'classnames';
import { Checkbox } from '@synerg/vdl-react-components';
import { MDFRadio } from '../MDFRadio';
import { ActionMenu } from '../ActionMenu';
import { SmallCardContext } from '../MDFContext';
import { MDFOverlayPopoverComponent } from '../MDFOverlayPopover';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export type IActionType = 'edit' | 'view' | 'delete' | 'switch' | 'download' | 'overflow' | 'other' | 'multi' | 'single' | 'default' | 'popover';

export interface ISmallCardActionProps {
  className?: string;
  // action type allowed for the top card
  actionType: IActionType;
  // render other Icons
  otherActionIcon?: string;
  // show action label along with action icon
  showLabel?: boolean
  // action label along with action icon
  actionLabel?: string
  // onClick for the action corner
  onClick?: () => void;
  // The children to render within this actionCorner.
  children?: any;
  // support 2nd action of info icon for selectable cards
  hasInfoIcon?: boolean;
  // support id, such as Pendo id
  id?: string;
  // arialLabel to read out the action
  ariaLabel?: string;
  'aria-label'?: string;
}

export class SmallCardAction extends React.PureComponent<ISmallCardActionProps, any> {
  static propTypes = {
    className: PropTypes.string,
    // Supports
    actionType: PropTypes.oneOf(['edit', 'view', 'delete', 'switch', 'download', 'overflow', 'other', 'multi', 'single', 'default', 'popover']),
    // Other icons
    otherActionIcon: PropTypes.string,
    showLabel: PropTypes.bool,
    actionLabel: PropTypes.string,
    onClick: PropTypes.func,
    selectable: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([false])]),
    hasInfoIcon: PropTypes.bool,
    id: PropTypes.string
  };

  declare context: React.ContextType<typeof SmallCardContext>;

  // passing this static ContextType to set the this.context
  static contextType = SmallCardContext;

  actionIcon = '';
  defaultActionLabel = '';
  actionItem = null;
  actionInfo = null;
  isSelected: any;

  renderAction = (actionType) => {
    switch (actionType) {
      case 'edit': {
        this.actionIcon = 'fa fa-pencil';
        this.defaultActionLabel = FormatHelper.formatMessage('@@Edit');
        break;
      }

      case 'view': {
        this.actionIcon = 'fa fa-eye';
        this.defaultActionLabel = FormatHelper.formatMessage('@@View');
        break;
      }

      case 'multi':
        this.actionIcon = '';
        this.renderChildren(actionType);
        break;

      case 'single':
        this.actionIcon = '';
        this.renderChildren(actionType);
        break;

      case 'delete': {
        this.actionIcon = 'fa fa-trash-o';
        this.defaultActionLabel = FormatHelper.formatMessage('@@Delete');
        break;
      }

      case 'download': {
        this.actionIcon = 'fa fa-download';
        this.defaultActionLabel = FormatHelper.formatMessage('@@Download');
        break;
      }

      case 'overflow':
        this.actionIcon = '';
        this.renderChildren(actionType);
        break;

      case 'other':
        this.actionIcon = this.props.otherActionIcon;
        break;

      case 'popover':
        this.renderChildren(actionType);
        break;

      // Fall through
      default: {
        this.actionIcon = '';
        this.defaultActionLabel = '';
        break;
      }
    }
  };

  // render only one action
  renderActionComponents = (child, action) => {
    if (child) {
      // render checkbox and radio with actionType multi and single only for selectable cards
      if (this.isSelected && child.type === MDFRadio && action === 'single') {
        return this.actionItem = <MDFRadio {...child.props} />;
      }
      else if (this.isSelected && child.type === Checkbox && action === 'multi') {
        return this.actionItem = <Checkbox {...child.props} />;
      }
      else if (!this.isSelected && child.type === ActionMenu && action === 'overflow') {
        // Allow nested children for ActionMenu component
        return this.actionItem = <ActionMenu {...child.props}>{...child.props.children}</ActionMenu>;
      }
      else if (!this.isSelected && child.type === MDFOverlayPopoverComponent && action === 'popover') {
        // Allow nested children for Popover component
        return this.actionItem = <MDFOverlayPopoverComponent {...child.props}>{...child.props.children}</MDFOverlayPopoverComponent>;
      }
      if (this.isSelected && this.props.hasInfoIcon && child.type === MDFOverlayPopoverComponent) {
        // Allow actionIcon only for Selectable cards
        return this.actionInfo = <MDFOverlayPopoverComponent {...child.props}>{...child.props.children}</MDFOverlayPopoverComponent>;
      }
      else {
        console.error('This action is not supported for this card');
        return null;
      }
    }
    else {
      return null;
    }
  };

  renderChildren = (action?: IActionType) => {
    const childrenCount = React.Children.count(this.props.children);

    // when only one child is passed
    if (childrenCount === 1) {
      this.renderActionComponents(this.props.children, action);
    }
    else {
      this.props.children.forEach((child) => {
        this.renderActionComponents(child, action);
      });
    }

    return null;
  };

  render() {
    const { className, onClick, actionType, hasInfoIcon, id } = this.props;

    this.isSelected = this.context && this.context.selectable;

    // call renderChildren to render infoIcon when there is no actionType for selectable cards
    // and hasInfoIcon set to true.
    this.isSelected && hasInfoIcon && !actionType && this.renderChildren();

    // call renderAction only when actionType property is available
    actionType && this.renderAction(actionType);

    const actionInfoClass = hasInfoIcon && 'actionIcon mdf-actionInfo';
    const actionId = id || generateId('cardAction');
    const ariaLabel = resolveAriaProperty('SmallCardAction', 'aria-label', 'ariaLabel', this.props);

    // Do not render anything when there is no actionType Property passed.
    // When an actionIcon is passed render only the action Action
    // for selectable only allow checkbox and radiobutton component does not support any other icons
    // For Accessibility - When there is no actionItem or actionInfo do not render the dom
    // check for actionItem to cover overflow and popover
    return (
      <React.Fragment>
        {
          (actionType && !this.isSelected && (this.actionItem || this.actionIcon)) &&
          <div className={classNames('small-card-action')}>
            {this.actionIcon && <MDFButton buttonStyle={'link'} id={actionId} aria-label={ariaLabel || this.defaultActionLabel} onClick={onClick} iconClass={(className || `${this.actionIcon} actionIcon` || '')} >
              {this.props.showLabel && (this.props.actionLabel || this.defaultActionLabel)}
            </MDFButton>}
            {!this.actionIcon && this.actionItem}
          </div>
        }
        {
          (this.isSelected && (this.actionItem || this.actionIcon)) &&
          <div id={'item-' + actionId} className={classNames('small-card-action', className)}>
            {this.actionItem}
          </div>
        }
        {
          (this.isSelected && hasInfoIcon) &&
          <div id={'info-' + actionId} className={classNames('small-card-action', actionInfoClass)}>
            {this.actionInfo}
          </div>
        }
      </React.Fragment>
    );
  }
}
