import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { MDFIcon } from '../MDFIcon';
import { SmallCardContext } from '../MDFContext';
import { ComponentManager, generateId } from '@adp-wfn/mdf-core';

export interface ICardTitleProps {
  // Assign className to the Title portion of Header
  className?: string;
  // The HTML tag to use for the header text. "h2" is the default, but it can be overridden in cases where it doesn't semantically make sense based on the page structure.
  headerTag?: string;
  // id for SmallCardTitle
  id?: string;
  // SubTitle String
  subTitle?: string;
  // Title that can be an html string to render title for the header
  title?: any;
  // Title icon to be rendered to the left of the title
  titleIconClass?: string;
  // To set the font size of the title for Title/subtitle"
  titleSize?: 'small' | 'medium' | 'large';
  // To set font weight for Title/subtitle
  weight?: 'semibold' | 'light';
  // option to wrap Title and SubTitle
  wrapTitle?: boolean;
}

export function SmallCardTitle(props: ICardTitleProps) {
  const { className, id, headerTag, subTitle, title, titleIconClass, titleSize, weight, wrapTitle } = props;
  const HeaderTag = ComponentManager.getComponent(props.headerTag);

  const context: any = useContext(SmallCardContext);
  const selectableCard = (context && context.selectable);

  // Titlecase style will not be supported when headerTag property is being passed.
  const titleClasses = classNames({
    'small-card-title--titleSubTitle': true,
    'small-card-title--selectable': selectableCard,
    'small-card-title--titleSubTitle--wrapTitle': wrapTitle,
    [`small-card-title--${weight}`]: !selectableCard && (weight && true),
    [`small-card-title--${titleSize}`]: (headerTag !== 'h2' ? false : titleSize && true)
  });

  let cardId = id;

  if (!cardId) {
    cardId = generateId('mdfSmallCardTitle');
  }

  const headerProps = {
    className: `${classNames(`${titleClasses} `, className)} no-margin`,
    title
  };

  return (
    <React.Fragment>
      <div id={cardId} className={`small-card-title ${subTitle ? 'small-card-title--alignItem' : ''}`}>
        {(!selectableCard) && titleIconClass && <MDFIcon className={`${titleIconClass} small-card-title--icon`}></MDFIcon>}
        <div className="header">
          <HeaderTag {...headerProps}>{title}
            {title && subTitle && <span className={'small-card-subTitle'}>{subTitle}</span>}
          </HeaderTag>
        </div>
      </div>
    </React.Fragment>
  );
}

SmallCardTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleIconClass: PropTypes.string,
  weight: PropTypes.oneOf([
    'semibold',
    'light'
  ]),
  titleSize: PropTypes.oneOf([
    'small',
    'medium',
    'large'
  ])
};

SmallCardTitle.defaultProps = {
  weight: 'semibold',
  titleSize: 'large',
  headerTag: 'h2'
};

// SmallCardSubTitle
export interface ICardSubTitleProps {
  className?: string;
  subTitle?: any;
}

export function SmallCardSubTitle(props: ICardSubTitleProps) {
  const { className, subTitle } = props;

  return (
    <React.Fragment>
      <div className={classNames('small-card-subTitle', className)} title={subTitle}>{subTitle}</div>
    </React.Fragment>
  );
}

SmallCardSubTitle.propTypes = {
  className: PropTypes.string,
  subTitle: PropTypes.string
};
