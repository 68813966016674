import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MDFCore } from '@adp-wfn/mdf-core';
import { SdfFocusPane } from '@waypoint/react-components';
import { ModalType } from '@waypoint/web-components/types/behaviors/modal';
import { FocusPaneSize, FocusPaneSpacing, ModalStatus } from '@waypoint/web-components/types/components/focus-pane/focus-pane';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

export interface IMDFFocusPaneProps {
  acceptLabel?: string;
  'accept-label'?: string;
  allowBackgroundInteraction?: boolean;
  'allow-background-interaction'?: boolean;
  backLabel?: string;
  'back-label'?: string;
  children?: React.ReactNode;
  closeable?: boolean;
  closeIconTitle?: string;
  'close-icon-title'?: string;
  disableAcceptButton?: boolean;
  'disable-accept-button'?: boolean;
  disableDismissButton?: boolean;
  'disable-dismiss-button'?: boolean;
  dismissLabel?: string;
  'dismiss-label'?: string;
  dismissOnClickOutside?: boolean;
  'dismiss-on-click-outside'?: boolean;
  heading?: string;
  hideAcceptButton?: boolean;
  'hide-accept-button'?: boolean;
  hideDismissButton?: boolean;
  'hide-dismiss-button'?: boolean;
  hideFooter?: boolean;
  'hide-footer'?: boolean;
  hideHeader?: boolean;
  'hide-header'?: boolean;
  icon?: IconName;
  isVisible?: boolean; // Use visible instead
  'is-visible'?: boolean; // Use visible instead
  onAfterClose?: (event) => void; // Use onSdfAfterClose instead
  onAfterOpen?: (event) => void; // Use onSdfAfterOpen instead
  onSdfAccept?: (event) => void;
  onSdfAfterClose?: (event) => void;
  onSdfAfterOpen?: (event) => void;
  onSdfDismiss?: (event) => void;
  paneType?: ModalType;
  'pane-type'?: ModalType;
  // Accessibility feature - Moves focus to a specific element after the modal dialog closes.
  // Enable feature by passing matching string values to the modal dialog's restoreFocusNodeId property and the next focused element's id property.
  restoreFocusNodeId?: string;
  spacing?: FocusPaneSpacing;
  status?: ModalStatus;
  size?: FocusPaneSize;
  title?: string; // Use heading instead
  titleTag?: string;
  'title-tag'?: string;
  urgencyType?: string; // Use status instead
  'urgency-type'?: string; // Use status instead
  visible?: boolean;
}

export const MDFFocusPane = (props: IMDFFocusPaneProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(props.visible);
  const [trigger, setTrigger] = useState(null);
  const focusPane = useRef<HTMLSdfFocusPaneElement>(null);

  if (MDFCore.shouldLog()) {
    console.warn('MDFFocusPane(): Entering. props = ', props);
  }

  const afterOpened = useCallback((event) => {
    if (props.onAfterOpen && !props.onSdfAfterOpen) {
      console.error('MDFFocusPane(): "onAfterOpen" is not a valid property. Use "onSdfAfterOpen" instead.');
      props.onAfterOpen(event);
    }
    else {
      props.onSdfAfterOpen?.(event);
    }
  }, []);

  const afterClosed = useCallback((event) => {
    // Stop rendering the component after the close animation is done.
    setIsShowing(false);

    if (props.onAfterClose && !props.onSdfAfterClose) {
      console.error('MDFFocusPane(): "onAfterClose" is not a valid property. Use "onSdfAfterClose" instead.');
      props.onAfterClose(event);
    }
    else {
      props.onSdfAfterClose?.(event);
    }

    // Accessibility: Use restoreFocusNodeId property to place focus on a custom element after the slide in closes.
    if (props.restoreFocusNodeId) {
      const id = props.restoreFocusNodeId;
      const customFocusElement = document.getElementById(id) as any;

      if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
        customFocusElement.setFocus?.();
      }
      else if (customFocusElement) {
        customFocusElement.focus();
      }
    }
  }, [isShowing]);

  useEffect(() => {
    // When the component is visible, we need to allow it to render so that
    // the opening animation will be visible.
    if (props.isVisible === true || props.isVisible === false) {
      console.error('MDFFocusPane(): "isVisible" is not a valid property. Use "visible" instead.');
    }

    if (props['is-visible'] === true || props['is-visible'] === false) {
      console.error('MDFFocusPane(): "is-visible" is not a valid property. Use "visible" instead.');
    }

    setIsShowing(!!(props.visible || props.isVisible || props['is-visible']));
  }, [props.isVisible, props['is-visible'], props.visible]);

  useLayoutEffect(() => {
    // The open/close methods need to be run before repainting the screen.
    if (isShowing) {
      // Focus management: Identify element that opens the modal.
      setTrigger(document.activeElement.id);
      void focusPane.current?.open();
      setIsOpen(true);
    }
    else {
      void focusPane.current?.close();
      setIsOpen(false);

      // Handles focus management upon slide in closing
      let focusNodeId;
      let customFocusElement;

      if (props.restoreFocusNodeId || trigger) {
        // Checks if a custom focus element (restoreFocusNodeId property) is specified first. If a
        // focus element is not specified, then focus will be moved back to the original trigger element.
        props.restoreFocusNodeId ? focusNodeId = props.restoreFocusNodeId : focusNodeId = trigger;
        customFocusElement = document.getElementById(focusNodeId) as any;

        if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
          customFocusElement.setFocus?.();
        }
        else if (customFocusElement) {
          customFocusElement.focus();
        }
      }
    }
  }, [isShowing]);

  // Convert properties to their snake-case names to work around a bug in Stencil's
  // React wrapper that prevents new values from getting to the web component.
  const focusPaneProps: any = {
    'accept-label': props.acceptLabel ?? props['accept-label'],
    'allow-background-interaction': props.allowBackgroundInteraction ?? props['allow-background-interaction'],
    'back-label': props.backLabel ?? props['back-label'],
    'closeable': props.closeable,
    'close-icon-title': props.closeIconTitle ?? props['close-icon-title'],
    'disable-accept-button': props.disableAcceptButton ?? props['disable-accept-button'],
    'disable-dismiss-button': props.disableDismissButton ?? props['disable-dismiss-button'],
    'dismiss-label': props.dismissLabel ?? props['dismiss-label'],
    'dismiss-on-click-outside': props.dismissOnClickOutside ?? props['dismiss-on-click-outside'],
    'heading': props.heading,
    'hide-accept-button': props.hideAcceptButton ?? props['hide-accept-button'],
    'hide-dismiss-button': props.hideDismissButton ?? props['hide-dismiss-button'],
    'hide-footer': props.hideFooter ?? props['hide-footer'],
    'hide-header': props.hideHeader ?? props['hide-header'],
    'icon': props.icon,
    'onSdfAccept': props.onSdfAccept,
    'onSdfDismiss': props.onSdfDismiss,
    'pane-type': props.paneType ?? props['pane-type'],
    'spacing': props.spacing,
    'status': props.status,
    'size': props.size,
    'title-tag': props.titleTag ?? props['title-tag']
  };

  if (props.title) {
    console.error('MDFFocusPane(): "title" is not a valid property. Use "heading" instead.');

    if (!focusPaneProps.heading) {
      focusPaneProps.heading = props.title;
    }
  }

  if (props.onAfterClose) {
    console.error('MDFFocusPane(): "onAfterClose" is not a valid property. Use "onSdfAfterClose" instead.');
  }

  if (props.onAfterOpen) {
    console.error('MDFFocusPane(): "onAfterOpen" is not a valid property. Use "onSdfAfterOpen" instead.');
  }

  if (props.urgencyType) {
    console.error('MDFFocusPane(): "urgencyType" is not a valid property. Use "status" instead.');

    if (!focusPaneProps.status) {
      focusPaneProps.status = props.urgencyType;
    }
  }

  if (props['urgency-type']) {
    console.error('MDFFocusPane(): "urgency-type" is not a valid property. Use "status" instead.');

    if (!focusPaneProps.status) {
      focusPaneProps.status = props['urgency-type'];
    }
  }

  switch (focusPaneProps.size) {
    case 'small':
      console.error('MDFFocusPane(): "small" is not a valid size. Use "sm" instead.');
      focusPaneProps.size = 'sm';
      break;

    case 'medium':
      console.error('MDFFocusPane(): "medium" is not a valid size. Use "md" instead.');
      focusPaneProps.size = 'md';
      break;

    case 'large':
      console.error('MDFFocusPane(): "large" is not a valid size. Use "lg" instead.');
      focusPaneProps.size = 'lg';
      break;

    case 'xlarge':
      console.error('MDFFocusPane(): "xlarge" is not a valid size. Use "xl" instead.');
      focusPaneProps.size = 'xl';
      break;
  }

  focusPaneProps.onSdfAfterClose = afterClosed;
  focusPaneProps.onSdfAfterOpen = afterOpened;
  delete focusPaneProps.children;

  // Eliminate all the falsey properties so we don't pass them to the sdf-focus-pane
  // and have React convert them to strings, which the sdf-focus-pane treats as real values
  Object.keys(focusPaneProps).forEach((key) => {
    if (focusPaneProps.hasOwnProperty(key) && !focusPaneProps[key]) {
      delete focusPaneProps[key];
    }
  });

  const renderChildren = () => {
    if (isOpen) {
      return <React.Fragment>{props.children}</React.Fragment>;
    }
    else {
      return null;
    }
  };

  if (MDFCore.shouldLog()) {
    console.warn('MDFFocusPane(): Rendering.');
    console.warn(`MDFFocusPane(): props.visible = ${props.visible}, props.isVisible = ${props.isVisible}, props.is-visible = ${props['is-visible']}, isShowing = ${isShowing}, isOpen = ${isOpen}`);
    console.warn('MDFFocusPane(): focusPaneProps = ', focusPaneProps);
  }

  if (props.visible || props.isVisible || props['is-visible'] || isShowing || isOpen) {
    return createPortal(<SdfFocusPane className="mdf" {...focusPaneProps} ref={focusPane}>{renderChildren()}</SdfFocusPane>, document.body);
  }
  else {
    return null;
  }
};

MDFFocusPane.defaultProps = {
  size: 'md',
  visible: false
};

MDFFocusPane.displayName = 'MDFFocusPane';
