import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import vdlPrefix from '../util/vdlPrefix';
import classNames from 'classnames';
import { DropdownList } from '@synerg/vdl-react-components';
import { debounce } from 'lodash';
import PaginationFirstIcon from 'adp-react-icons/lib/adp/pagination-first';
import PaginationLastIcon from 'adp-react-icons/lib/adp/pagination-last';
import PaginationPrevIcon from 'adp-react-icons/lib/adp/pagination-previous';
import PaginationNextIcon from 'adp-react-icons/lib/adp/pagination-next';
import { FormatHelper } from '@adp-wfn/mdf-core';

export interface IPageInfo {
  label: string,
  value: number
}

export interface IPaginatorProps {
  pageNumber: number;
  totalItems: number;
  pageSize?: number;
  pageSizes?: IPageInfo[];
  pageSizeDropdownId?: string;
  visibleTotalItems?: boolean;
  className?: string;
  onPageNumberChange?: (pageNumber: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  onPageStartIndexChange?: (pageStartIndex: number) => void;
}

const defaultPageSizes: IPageInfo[] = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '25', value: 25 }
];

export const PaginatorComponent = (props: IPaginatorProps) => {
  const [pageSize, setPageSize] = useState<number>(props.pageSize);
  const [pageNumber, setPageNumber] = useState<number>(props.pageNumber);
  const [numberInput, setNumberInput] = useState<number>(pageNumber);
  const firstText = FormatHelper.formatMessage('@@First');
  const lastText = FormatHelper.formatMessage('@@Last');
  const nextText = FormatHelper.formatMessage('@@Next');
  const previousText = FormatHelper.formatMessage('@@Previous');
  const itemsFoundText = FormatHelper.formatMessage('@@ItemsFound');
  const itemsperPageText = FormatHelper.formatMessage('@@ItemsperPage');
  const positionText = FormatHelper.formatMessage('@@of');
  const page = FormatHelper.formatMessage('@@page');

  const totalPagesCount = useMemo(() => {
    return Math.ceil(props.totalItems / pageSize);
  }, [pageSize, props.totalItems]);

  const triggerPageNumberChange = useCallback((number, pNumber, pSize) => {
    setPageNumber(number);

    if (number !== pNumber) {
      if (props.onPageNumberChange) {
        props.onPageNumberChange(number);
      }

      if (props.onPageStartIndexChange) {
        props.onPageStartIndexChange(Math.max(0, pSize * (number - 1)));
      }
    }
  }, []);

  const debouncedPageNumberChange = useRef(debounce(triggerPageNumberChange, 500)).current;

  const onPageNumberChange = useCallback((number, flushChange = false) => {
    setNumberInput(number);
    debouncedPageNumberChange(number, pageNumber, pageSize);

    if (flushChange) {
      debouncedPageNumberChange.flush();
    }
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (props.pageNumber !== pageNumber) {
      setPageNumber(props.pageNumber);
      setNumberInput(props.pageNumber);
    }
  }, [props.pageNumber]);

  useEffect(() => {
    if (props.pageNumber > totalPagesCount) {
      onPageNumberChange(totalPagesCount, true);
    }
  }, [totalPagesCount]);

  useEffect(() => {
    if (props.pageSize !== pageSize) {
      setPageSize(props.pageSize);
    }
  }, [props.pageSize]);

  const getLabel = useCallback((label) => {
    return `${label} ${FormatHelper.formatMessage('@@label_page')}`;
  }, []);

  const next = () => {
    if (numberInput < totalPagesCount) {
      onPageNumberChange(numberInput + 1);
    }
  };

  const prev = () => {
    if (numberInput > 1) {
      onPageNumberChange(numberInput - 1);
    }
  };

  const first = () => {
    onPageNumberChange(1, true);
  };

  const last = () => {
    onPageNumberChange(totalPagesCount, true);
  };

  // Handle the keyboard interaction
  const handleKeyDown = (event, key) => {
    const which = event.key;

    if (which === 'Enter') {
      switch (key) {
        case 'first':
          first();
          break;
        case 'last':
          last();
          break;
        case 'prev':
          prev();
          break;
        case 'next':
          next();
          break;
      }
    }
  };

  const onPageSizeChange = useCallback((selected) => {
    const selectedPageSize = selected.value;

    setPageSize(selectedPageSize);
    setPageNumber(1);
    setNumberInput(1);

    if (props.onPageSizeChange) {
      props.onPageSizeChange(selectedPageSize);
    }

    if (props.onPageNumberChange) {
      props.onPageNumberChange(1);
    }

    if (props.onPageStartIndexChange) {
      props.onPageStartIndexChange(0);
    }
  }, []);

  const onNumberInputChange = (event) => {
    const value = Number(event.target.value);

    if (value === numberInput || value < 1 || value > totalPagesCount) {
      debouncedPageNumberChange.cancel();
      setNumberInput(value);
      return;
    }

    onPageNumberChange(value);
  };

  const onNumberInputBlur = () => {
    if (numberInput !== pageNumber) {
      setNumberInput(pageNumber);
    }
  };

  const disableEnd = (numberInput === totalPagesCount || totalPagesCount === 0);
  const disableStart = (numberInput === 1 || totalPagesCount === 0);

  return (
    <div className={classNames(`${vdlPrefix('pagination')}`, props.className)}>
      {props.visibleTotalItems ? <label className="pagination__total-items-found-labels">{props.totalItems} {itemsFoundText}</label> : ''}
      <label className="pagination__rows-per-page-labels">{itemsperPageText}</label>
      {props.pageSizes.length > 1 ? <DropdownList
        aria-label={itemsperPageText}
        id={props.pageSizeDropdownId}
        data={props.pageSizes}
        disabled={totalPagesCount === 0}
        value={pageSize}
        onChange={onPageSizeChange}
        textField="label"
        valueField="value"
      /> : ''}
      <i className={classNames('pagination-first', { disabled: disableStart })} role="button" aria-label={getLabel(firstText)} onClick={first} title={firstText} tabIndex={disableStart ? -1 : 0} onKeyDown={(event) => handleKeyDown(event, 'first')}><PaginationFirstIcon /></i>
      <i className={classNames('pagination-previous', { disabled: disableStart })} role="button" aria-label={getLabel(previousText)} onClick={prev} title={previousText} tabIndex={disableStart ? -1 : 0} onKeyDown={(event) => handleKeyDown(event, 'prev')}><PaginationPrevIcon /></i>
      <input aria-label={page + ' ' + numberInput} type="number" value={numberInput || ''} aria-valuenow={numberInput} aria-valuemin={1} aria-valuemax={totalPagesCount}
        min={1} max={totalPagesCount} disabled={totalPagesCount === 0} aria-disabled={totalPagesCount === 0} className="pagination-input" onChange={onNumberInputChange} onBlur={onNumberInputBlur} />
      <span className="pagination__page-display">{`${positionText} ${totalPagesCount}`}</span>
      <i className={classNames('pagination-next', { disabled: disableEnd })} role="button" aria-label={getLabel(nextText)} onClick={next} title={nextText} tabIndex={disableEnd ? -1 : 0} onKeyDown={(event) => handleKeyDown(event, 'next')}><PaginationNextIcon /></i>
      <i className={classNames('pagination-last', { disabled: disableEnd })} role="button" aria-label={getLabel(lastText)} onClick={last} title={lastText} tabIndex={disableEnd ? -1 : 0} onKeyDown={(event) => handleKeyDown(event, 'last')}><PaginationLastIcon /></i>
    </div>
  );
};

PaginatorComponent.displayName = 'Paginator';

PaginatorComponent.propTypes = {
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizes: PropTypes.array,
  pageSizeDropdownId: PropTypes.string,
  totalItems: PropTypes.number,
  visibleTotalItems: PropTypes.bool
};

PaginatorComponent.defaultProps = {
  pageSize: 5,
  totalItems: 0,
  visibleTotalItems: false,
  pageSizes: defaultPageSizes
};
