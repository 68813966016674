import { CardAtom, ICardAtomProps } from './SmallCard/CardAtom';
import React, { useEffect, useState } from 'react';
import { FeatureFlags, FormatHelper } from '@adp-wfn/mdf-core';

export type CardGutterWidth = 0 | 5 | 10 | 15 | 20;

/** Notes
 * Section Component that act as a box for Header and Content. This uses CardAtom internally that shares CardAtom Properties
 * Section Header takes any Children, the application is expected to pass a title that is of H2 tag
 * Section Content takes any children
 * Sets the cardStyle to flat with margin 10px
 * */

export interface ISectionHeaderProps {
  // Placeholder for the component's children.
  children: React.ReactNode;
  // Style to overwrite the SectionHeader
  className?: string;
  // Internal use only: Internally pass gutterWidth property
  gutterWidth?: CardGutterWidth;
  // use the Waypoint version of the layout styles, will be ignored once the feature flag is removed
  isWaypoint?: boolean;
}

export interface ISectionContentProps {
  // Placeholder for the component's children.
  children: React.ReactNode;
  // Style to overwrite the SectionContent
  className?: string;
  // Internal use only: Internally pass gutterWidth property
  gutterWidth?: CardGutterWidth;
  // use the Waypoint version of the layout styles, will be ignored once the feature flag is removed
  isWaypoint?: boolean;
}

export interface ISectionProps extends ICardAtomProps {
  // Placeholder for the component's children.
  children?: React.ReactNode;
  // Whether or not the use can expand/collapse the Section
  collapsible?: boolean;
  // Whether or not the node should be expanded initially
  expanded?: boolean;
  // The internal padding for the card. Defaults to 10.
  gutterWidth?: CardGutterWidth;
  // Callback fired when the expand/collapse icon is clicked
  onExpandClick?: (expand) => void;
  // Accessibility message for toggle button.
  toggleButtonLabel?: any;
  // Whether or not to add a grey border below the SectionHeader
  useTitleSeparator?: boolean;
}

export const SectionContent = (props: ISectionContentProps) => {
  const { className, children } = props;

  const newGutterWidth = {
    0: 'p-0',
    5: 'p-2',
    10: 'p-2',
    15: 'px-4 pt-2 pb-4',
    20: 'px-6 pt-2 pb-6'
  };

  return (
    <div className={`mdf-section-content ${className ?? ''} ${props.isWaypoint ? newGutterWidth[props.gutterWidth] : `gutterWidth-${props.gutterWidth}`}`}>
      {children}
    </div>
  );
};

export const SectionHeader = (props: ISectionHeaderProps) => {
  const { className, children } = props;

  const newGutterWidth = {
    0: 'p-0',
    5: 'p-2',
    10: 'p-2',
    15: 'px-4 pt-4 pb-2',
    20: 'px-6 pt-6 pb-2'
  };

  return (
    <div className={`mdf-section-header ${className ?? ''} ${props.isWaypoint ? newGutterWidth[props.gutterWidth] : `gutterWidth-${props.gutterWidth}`}`} >
      {children}
    </div>
  );
};

export const Section = (props: ISectionProps) => {
  const { useTitleSeparator, children, collapsible, expanded, onExpandClick, gutterWidth, ...cardAtomProps } = props;
  const [isOpen, setOpen] = useState(expanded);
  const hasOldShell = (window as any).isLegacyAppShell || (window as any).isClassicShell;
  const hasWaypointCards = FeatureFlags.hasFeature('waypointCards') && !hasOldShell;
  const isWaypoint = props.isWaypoint || hasWaypointCards;

  useEffect(() => {
    expanded ? setOpen(true) : setOpen(false);
  }, [expanded]);

  let sectionTitle = null;
  let sectionContent = null;

  React.Children.forEach(children, (child: any): any => {
    if (child) {
      if (child.type === SectionHeader) {
        return sectionTitle = <SectionHeader gutterWidth={props.gutterWidth} isWaypoint={isWaypoint} {...child.props} />;
      }
      else if (child.type === SectionContent) {
        return sectionContent = <SectionContent gutterWidth={props.gutterWidth} isWaypoint={isWaypoint} {...child.props} />;
      }
      else {
        return child;
      }
    }
  });

  const onExpandButtonClick = (event: React.SyntheticEvent<any>) => {
    event.stopPropagation();
    setOpen(!isOpen);
    onExpandClick(!isOpen);
  };

  // Expand and collapse the section content when space or enter key is pressed for accessibility.
  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (event.key === 'Enter' || event.key === ' ') {
      onExpandButtonClick(event);
    }
  };

  const renderHeader = () => {
    const label = props.toggleButtonLabel || FormatHelper.formatMessage('@@SectionCard');

    return (
      <div className={`mdf-section-header-panel ${useTitleSeparator ? 'mdf-cardLineBorder' : ''}`}>
        {sectionTitle}
        {collapsible && <span role="button" aria-label={label} aria-expanded={isOpen} tabIndex={0} className={`section-collapsible-style ${isOpen ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`} onKeyPress={handleKeyPress} onClick={onExpandButtonClick}></span>}
      </div>
    );
  };

  // renderHeader only when SectionHeader is the direct child of SectionPanel
  return (
    <CardAtom {...cardAtomProps} gutterWidth={0} isWaypoint={isWaypoint} cardStyle="flat" className={`mdf-section-style ${props.className ?? ''}`}>
      {sectionTitle && renderHeader()}
      {(!props.collapsible || isOpen) && sectionContent}
    </CardAtom>);
};

Section.displayName = 'Section';
SectionContent.displayName = 'SectionContent';
SectionHeader.displayName = 'SectionHeader';

Section.defaultProps = {
  gutterWidth: 10
};
