import assoc from 'ramda/es/assoc';
import compose from 'ramda/es/compose';
import concat from 'ramda/es/concat';
import filter from 'ramda/es/filter';
import findIndex from 'ramda/es/findIndex';
import includes from 'ramda/es/includes';
import insert from 'ramda/es/insert';
import insertAll from 'ramda/es/insertAll';
import lensIndex from 'ramda/es/lensIndex';
import lensProp from 'ramda/es/lensProp';
import map from 'ramda/es/map';
import omit from 'ramda/es/omit';
import over from 'ramda/es/over';
import prop from 'ramda/es/prop';
import propEq from 'ramda/es/propEq';
import props from 'ramda/es/props';
import remove from 'ramda/es/remove';
import set from 'ramda/es/set';
import sortBy from 'ramda/es/sortBy';

const updateCollection = (value, collection, newValue) => {
  const index = findIndex(propEq(value, 'value'), collection);

  return over(
    lensIndex(index),
    assoc('isSelected', newValue),
    collection
  );
};

export const updateValueInCollection = (value: any, collection: any) => (
  updateCollection(value, collection, true)
);

export const removeValueInCollection = (value, collection) => (
  updateCollection(value, collection, undefined)
);

const isSelected = propEq(true, 'isSelected');

const filterAndTransformSelected = compose(
  map(omit(['isSelected'])), filter(isSelected)
);

export const filterAndRetrieveSelectedValues = compose(
  map(prop('value')),
  filter(isSelected)
);

// Someone added a bad type definition to the first parameter of sortBy, so
// we have to ignore this error (for now).
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const retrieveOptionsList = (options) => map(props(['label', 'value']), sortBy(prop('value'), options));

const addHideAndDropSelected = compose(
  set(lensProp<any>('isMoved'), true),
  omit(['isSelected'])
);

const addSelectedAndDropHide = compose(
  omit(['isMoved'])
);

const retrieveValues = (collection) => map(
  prop('value'),
  filter(isSelected, collection)
);

// We need to insert the element in the target and not required to swap
export const move = (currentIndex, targetIndex) => (collection) => {
  const selectedValue = omit(['isSelected'], collection[currentIndex]);

  // remove the currentItem selected
  collection = remove(currentIndex, 1, collection);

  // insert the selectedItem to the targetIndex
  collection = insert(targetIndex, selectedValue, collection);
  return collection;
};

export const moveWithin = (state, targetValue) => {
  const selectedValues = state && retrieveValues(state);

  // To support multiple drag and drop
  selectedValues.forEach(
    (value) => {
      const selectedIndex = findIndex(propEq(value, 'value',), state);
      const targetIndex = targetValue ? findIndex(propEq(targetValue, 'value'), state) : state.length;

      if (selectedIndex < targetIndex) {
        // reduce the targetIndex by 1 moving downward
        state = selectedIndex >= 0 && move(selectedIndex, targetIndex - 1)(state);
      }
      else {
        state = state.length && move(selectedIndex, targetIndex)(state);
      }
    }
  );

  return state;
};

export const moveLeftToRight = (state: any, node: HTMLElement) => {
  const newState: any = {};
  let targetValue: any;
  let targetIndex: any;

  newState.leftOptions = state.leftOptions.map((option: Record<string, any>) => {
    if (isSelected(option)) {
      return addHideAndDropSelected(option);
    }

    return option;
  });

  const selectedOptions = filterAndTransformSelected(state.leftOptions);

  if (node && node instanceof HTMLElement) {
    // Move selected options from left to right based on dropped location.
    targetValue = node.getAttribute('data-value');
    targetIndex = targetValue ? findIndex(propEq(targetValue, 'value'), state.rightOptions) : state.rightOptions.length;
    newState.rightOptions = insertAll(targetIndex, selectedOptions, state.rightOptions);
  }
  else {
    newState.rightOptions = concat(state.rightOptions, selectedOptions);
  }

  return newState;
};

export const removeIsSelectedInCollection = (options: any) =>
  options.map((option: any) => {
    option.isSelected = undefined;
    return option;
  });

export const moveRightToLeft = (state) => {
  const newState: any = {};

  newState.rightOptions = filter(propEq(undefined, 'isSelected'), state.rightOptions);
  const selectedRightValues = filterAndRetrieveSelectedValues(state.rightOptions);

  newState.leftOptions = state.leftOptions.map(
    (option) => {
      if (includes(option.value, selectedRightValues)) {
        return addSelectedAndDropHide(option);
      }

      return option;
    }
  );

  return newState;
};

export const moveRightLeftAll = (state) => {
  const newState: any = {};

  // set the rightoptions to be empty when moving all items from right to left
  newState.rightOptions = [];

  newState.leftOptions = concat(
    state.leftOptions.filter(function(lOption) {
      // concatenate only when the option is not already isMoved
      return lOption.isMoved !== true;
    }),
    state.rightOptions.map((rOption) => {
      rOption.isSelected = undefined;
      return rOption;
    })
  );

  return newState;
};

export const moveLeftRightAll = (state) => {
  const newState: any = {};

  // add isMoved true for consistency
  newState.leftOptions = state.leftOptions.map((option) => addHideAndDropSelected(option));

  newState.rightOptions = concat(
    state.rightOptions,
    state.leftOptions = state.leftOptions
      // remove object that has isMoved set to true.
      .filter((lOption) => lOption.isMoved !== true)
      .map((lOption) => {
        lOption.isSelected = undefined;
        return lOption;
      })
  );

  return newState;
};
